import MockAdapter from 'axios-mock-adapter';
import * as LocalAPI from 'view/offline/LocalAPI';
import * as LocalSvyAPI from 'view/offline/LocalSvyAPI';
import { P_EXMN_TRGT_CRTR_YMD, A_EXMN_TRGT_CRTR_YMD } from 'view/offline/LocalQsnInfo';
import { capiExit } from 'common/utils/CommonFunction';
const FakeApi = (axios) => {
  const mock = new MockAdapter(axios);
  /**
   * 공통
   * 로그아웃
   */
  mock.onGet('ba100/logout/').reply(async function (config) {
    console.log('ba100/logout/');
    return [200, { result: 'SUCCESS' }];
  });

  /**
   * 공통
   * 로그인(세션 생성)
   */
  mock.onGet('ba100/info-data').reply(async function (config) {
    console.log('ba100/info-data');
    let exmnTrgtCrtrYmd = '';
    if (process.env.REACT_APP_PRDCTN_ID === '220001') {
      exmnTrgtCrtrYmd = P_EXMN_TRGT_CRTR_YMD;
    } else {
      exmnTrgtCrtrYmd = A_EXMN_TRGT_CRTR_YMD;
    }

    let params = {};
    // if (sessionStorage.getItem('user') != null) {
    //   params = {
    //     ...JSON.parse(decodeURIComponent(sessionStorage.getItem('user'))),
    //     ...config.params,
    //   };
    // } else {
    //   params = config.params;
    // }
    params = config.params;

    let rspnInfo;
    // 인총일 경우 로그인시 응답자 정보 셋팅
    if (process.env.REACT_APP_PRDCTN_ID === '220001') {
      rspnInfo = await LocalAPI.infoData(config);

      if (params.emtdChartrCd === '4' || params.emtdChartrCd === '3') {
        alert('시설조사표는 오프라인을 지원하지 않습니다.');
        capiExit();
      }
    }

    let result = {
      ...params,
      ...rspnInfo,
      result: 'SUCCESS',
      sbmsnYn: '',
      exmnTrgtCrtrYmd: exmnTrgtCrtrYmd,
    };
    return [200, result];
  });

  /**
   * 가기초 로그인(세션 생성)
   */
  mock.onGet('ba100/hshldNmst-data').reply(async function (config) {
    console.log('ba100/hshldNmst-data');

    let params = {};
    if (sessionStorage.getItem('user') != null) {
      params = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));
      // params = sessionStorage.getItem('user');
      params.exmnSttsCd = '1';
    } else {
      params = config.params;
    }

    let result = {
      ...params,
      result: 'SUCCESS',
    };
    return [200, result];
  });

  /**
   * 인총 응답자 조사 시작
   */
  mock.onPost('/ba110/inptBgng-Insert').reply(async function (config) {
    console.log('inptBgng-Insert');
    return [200, { result: 'SUCCESS' }];
  });

  /**
   * 인총 응답자 조사 시작
   */
  mock.onPut('ba110/info-update').reply(async function (config) {
    console.log('ba110/info-update');
    let result = await LocalAPI.infoUpdate(config);
    return [200, result];
  });

  /**
   * 공통
   * 가구원 코드
   */
  mock.onGet('bd110/hhmcovercol-data').reply(async function (config) {
    console.log('bd110/hhmcovercol-data');
    let result = await LocalAPI.selectHhmCoverColData();
    return [200, result];
  });

  /**
   * 공통
   * 가구원 정보
   */
  mock.onGet('bd110/hhmcover-data').reply(async function (config) {
    console.log('bd110/hhmcover-data');
    let result = await LocalAPI.selectHhmCoverData();
    return [200, result];
  });

  /**
   * 공통
   * 가구원 저장
   */
  mock.onPost('bd110/hhmcover-save').reply(async function (config) {
    console.log('bd110/hhmcover-save');
    let result = await LocalAPI.saveHhmCoverData(config);
    return [200, result];
  });

  /**
   * 공통 코드
   */
  mock.onPost('coma400/cd-list').reply(async function (config) {
    console.log('coma400/cd-list');
    let result = await LocalAPI.cdList(config);
    return [200, result];
  });

  /**
   * 공통
   * 남남 가구원 문자전송 저장
   */
  mock.onPost('bd170/sendOtherHhmrSms-data').reply(async function (config) {
    console.log('bd170/sendOtherHhmrSms-data');
    let result = await LocalAPI.sendOtherHhmrSmsData(config);
    return [200, result];
  });

  /**
   * 공통
   * 조사상태 변경
   */
  mock.onPost(/ab120\/update-exmn-stts\.*/).reply(async function (config) {
    console.log('update-exmn-stts');
    let result = await LocalAPI.updateExmnStts(config);
    return [200, result];
  });

  /**
   * 농총
   * 표지 조회
   */
  mock.onGet('bd130/affscover-data/').reply(async function (config) {
    console.log('bd130/affscover-data/');
    let result = await LocalAPI.affscoverData();
    return [200, result];
  });

  /**
   * 농총
   * 표지 저장
   */
  mock.onPut('bd130/affscover-update').reply(async function (config) {
    console.log('bd130/affscover-update');
    let result = await LocalAPI.affscoverUpdate(config);
    return [200, result];
  });

  /**
   * 농총
   * 가구원 목록
   */
  mock.onGet('/ba110/acensHhm-list').reply(async function (config) {
    console.log('ba110/acensHhm-list' + config.url);
    let result = await LocalAPI.acensHhmList(config);
    return [200, result];
  });

  /**
   * 농총
   * 응답자 조회
   */
  mock.onGet('/ba110/acensHhm-Info').reply(async function (config) {
    console.log('ba110/acensHhm-Info' + config.url);
    let result = await LocalAPI.acensHhmInfo(config);
    return [200, result];
  });

  /**
   * 농총
   * 응답자 저장
   */
  mock.onPut('/ba110/acensCapiInfo-update').reply(async function (config) {
    console.log('acensCapiInfo-update');
    let result = await LocalAPI.acensCapiInfoUpdate(config);
    return [200, result];
  });

  /** *********************************조사표********************************* */
  mock.onGet(/aa100\/qsn-infodata\?.*/).reply(async function (config) {
    console.log('aa100/qsn-infodata' + config.url);
    let result = await LocalSvyAPI.qsnInfodata(config);
    return [200, result];
  });

  mock.onGet(/aa100\/adZoneTree-list\?.*/).reply(async function (config) {
    console.log('aa100/adZoneTree-list');
    let result = await LocalSvyAPI.adZoneTreelist();
    return [200, result];
  });

  mock.onPost(/ab100\/insert-svy-data\?.*/).reply(async function (config) {
    console.log('ab100/insert-svy-data');

    let result = await LocalSvyAPI.insertSvyData(config);
    return [200, result];
  });

  mock.onGet(/aa100\/userdfncd-list\?.*/).reply(async function (config) {
    console.log('aa100/userdfncd-list');
    let result = await LocalSvyAPI.userdfncdList(config);
    return [200, result];
  });

  mock.onPost('aa100/process-data-save').reply(async function (config) {
    console.log('aa100/process-data-save');
    let result = await LocalSvyAPI.processDataSave(config);
    return [200, result];
  });

  mock.onPost('/bz200/process-data-save').reply(async function (config) {
    console.log('/bz200/process-data-save');
    let result = await LocalSvyAPI.processDataSave(config);
    return [200, result];
  });

  return mock;
  /** *********************************조사표********************************* */
};

export default FakeApi;
