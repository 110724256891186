import api from '../../common/utils/api';

/**
 * 오프라인 전송 이력 조회(성별, 가구주와의 관계, 국적)
 * @returns
 */
const selectOflneData = (exmnInfo) => {
  let params = {
    exmnId: process.env.REACT_APP_EXMN_ID,
    prdctnId: process.env.REACT_APP_PRDCTN_ID,
    ciCrtrDt: exmnInfo != null ? exmnInfo.ciCrtrDt : null,
    cdCrtrDt: exmnInfo != null ? exmnInfo.cdCrtrDt : null,
    qsnDsgnstdrDt: exmnInfo != null ? exmnInfo.qsnDsgnstdrDt : null,
  };
  return api
    .get(`ab110/select-oflne-data`, { params })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(e.response.data.msg);
    });
};

/**
 * 오프라인 전송 이력 저장
 * @param {가구원 정보}} requestData
 * @returns
 */
const insertOflneHis = async (requestData) => {
  return await api
    .post('ab110/insert-oflne-his', requestData)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(e.response.data.msg);
    });
};

export default {
  selectOflneData,
  insertOflneHis,
};
