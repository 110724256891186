import { batch } from 'react-redux'
import _ from "lodash";
import { setItemProperties as paper_setItemProperties, setInputDataArray as paper_setInputDataArray, getCurrentGroup as paper_getCurrentGroup} from "./Paper";
import { setCallbackTrigger as behavior_setCallbackTrigger } from './Behavior';
import { addAnswer as answer_addAnswer, addAnswerArray as answer_addAnswerArray, addAffectedDataArray as answer_addAffectedDataArray} from './Answer';
// 액션 타입 정의
const SET_INIT_DATA = "subitems/SET_INIT_DATA";
const SET = "subitems/SET";
const ADD_SUBITEMS = "subitems/ADD_SUBITEMS"

const flattenNestedTree = (array, result) => {
  array.forEach(function (el) {
    result.push(el);
    if (el.children) {
      flattenNestedTree(el.children, result);
    } else {
    }
  });
};

const setAfterPaperLink = ({dispatch, getState,disabledItemMap,questionSaveKeys,itemMapData,selectedItem, prevEnableItems, useCallbackPool }) =>{
  //Paper모델과 연계 
   //useCallbackPool 파라미터추가. setAnswer 콜백으로 하위항목 활성화 호출시 동작이 분리되어야하기때문에
  try{
    const { Main,Paper, SubItems } = getState();
    let subItemsMech = itemMapData.common.subItemMech || false  //false 숨김 true 비활성
    const subItemPosition = itemMapData.common.subItemPosition    //false 항목 true 문항
    if(subItemPosition == true){
      subItemsMech = false
    }
    //1. 저장키와 관련된 하위항목 활성화
    const setInputDataArray = [];
    const addAffectedDataArray = [];
    if(questionSaveKeys?.length > 0){
      if(subItemsMech != false){
          questionSaveKeys.map(questionId=>{
            setInputDataArray.push({id : questionId, path : "common.disable", value : false})
            addAffectedDataArray.push({questionId : questionId, path:'common.disable', value : false})
          })
        }else{
          questionSaveKeys.map(questionId=>{
            setInputDataArray.push({id : questionId, path : "common.visible", value : true})
            addAffectedDataArray.push({questionId : questionId, path:'common.visible', value : true})
          })
      }

      // //입력받은 saveKey와 현재 state의 enableQuestion 비교후 같은 항목의 같은 하위항목을 가진 문항끼리 값이 변경된경우 하위항목 값 초기화
      let selectedSubItem = _.find(itemMapData.subItems, {questionList : [selectedItem]})
      selectedSubItem?.selectedTreeData.map(sub=>{
        if(prevEnableItems[sub]?.enableItems.length > 0){
          prevEnableItems[sub].enableItems.map(item=>{
            if(!_.isEqual(item , selectedItem) ){
              //표가 하위항목에 포함된경우 saveKeys가 없기때문에 예외처리.
              const subItem = Paper.itemMap[sub]
              subItem.saveKeys?.length > 0 && subItem.saveKeys.map(saveKey=>{
                if(useCallbackPool != false){
                  window.svy.fn_surveyCi_setAnswer(saveKey,"");//비활성화 되는 문항들 값 초기화
                }else{
                  const question = _.find(Paper.itemMap, { saveKeys: [saveKey] });
                  dispatch(answer_addAnswer({questionId:question.id, saveKey, value : '', commit:true,}))
                }
              })
            }
          })
        }
      })
          
  
      }
      //2. 하위항목 숨김 또는 비활성화.
      if(_.isArray(itemMapData.subItems)){
        const getSubItemProcessList = ({itemId, visibleItemId, saveKeyList, itemMapData, isSubItem}) => {
          let lv2SubItemsMech = itemMapData.common.subItemMech || false
          const lv2SubItemPosition = itemMapData.common.subItemPosition 
          if(lv2SubItemPosition == true){
            lv2SubItemsMech = false
          }
          const subSubItemsList = [] //하위항목에 하위항목을 담는 배열.
          itemMapData.subItems.forEach(item=>{
            item.selectedTreeData.forEach(key=>{
              if(disabledItemMap[key]&&disabledItemMap[key].enableItems.length==0 || isSubItem == true){ //하위항목을 활성화시켜주는 enableItem의 값이 없을때 key의 saveKeys를 돌면서 값초기화 후 비활성화.
                if(Paper.itemMap[key].saveKeys){
                  //하위항목 
                  if(lv2SubItemsMech != false ){
                    itemId.push(key);//itemId에 푸시후 아래서 common.disable 비활성화 해주기위함
                  }else{
                    visibleItemId.push(key)
                    }
                  Paper.itemMap[key].saveKeys.forEach(saveKey=>{ // key의 saveKey돌면서 한번에 비활성화하기위해 배열에 푸시
                    
                    saveKeyList.push({questionId : key, saveKey, value : ''})
                  });
                  Paper.itemMap[key].extraTextLink?.length > 0 && Paper.itemMap[key].extraTextLink.map(extraTextId=>{
                    saveKeyList.push({questionId : key, saveKey: extraTextId, value : ''})
                  })
                } 
                if(Paper.itemMap[key].type == 1 || Paper.itemMap[key].type == 3){

                  // const group = Paper.itemMap[key].type == 3 ? _.find(_.find(Paper.tree, {children : [{id : key}]}).children, {id : key}) : _.find(Paper.tree, {id : key})
                  // console.log("🚀 ~ file: SubItems.js:99 ~ getSubItemProcessList ~ group:", group)
                  let result = []
                  flattenNestedTree(Paper.tree, result)
                  const group = _.find(result, {id : key}) 
                  let children = group.children
                  if(Paper.itemMap[key].type == 3 && Paper.itemMap[key].common.tableType == 2){
                    let rowIndexs = []
                    children = _.filter(group.children, child=> child.rowIndex <= Paper.itemMap[key].common.info.rowIndexs[0])
                    let rowLength = Paper.itemMap[key].common.info.rowIndexs.length
                    for(let i=1; i < rowLength; i++){
                      rowIndexs.push(i)
                    }
                    // _.filter(group.children, child=>{
                    //   if( child.rowIndex == Paper.itemMap[key].common.info.rowIndexs[0] || child.rowIndex == 0){
                    //     return false
                    //   }else return true;
                    // }).map(child=>{
                    //   console.log("🚀 ~ file: SubItems.js:76 ~ _.filter ~ child:", child)
                    //   rowIndexs.push(parseInt(child.rowIndex))
                    // })
                    if(rowIndexs.length > 0){
                      window.svy.fn_surveyCi_tableRemoveRow(key, rowIndexs, false)
                    }
                  }
                  if(lv2SubItemsMech != false){
                    itemId.push(key)
                    children?.map(child=>{
                      if(_.isEmpty(disabledItemMap[child.id])){
                        itemId.push(child.id)
                        Paper.itemMap[child.id].saveKeys.map(saveKey=>{
                          saveKeyList.push({questionId : child.id, saveKey, value : ''})
                        })
                      }
                      // saveKeyList.push(...Paper.itemMap[child.id].saveKeys)
                    })
                  }else{
                    visibleItemId.push(key)
                    children?.map(child=>{
                      if(_.isEmpty(disabledItemMap[child.id])){
                        visibleItemId.push(child.id)
                        Paper.itemMap[child.id].saveKeys.map(saveKey=>{
                          saveKeyList.push({questionId : child.id, saveKey, value : ''})
                        })
                      }
                      // saveKeyList.push(...Paper.itemMap[child.id].saveKeys)
                    })
                  }
                }
                
                let subItemId = key;
                  Object.keys(SubItems.questionMap).forEach(itemKey=>{ //하위항목에 하위항목이 있다면 배열에 추가.
                    if(_.includes(Paper.itemMap[key].itemLink, itemKey)){
                      subSubItemsList.push(item)
                      if(Paper.itemMap[key].questionType == '1'){
                        subItemId = itemKey
                      }
                    }
                  })
                  if(subSubItemsList.length > 0){ //하위항목의 하위항목존재시 재귀함수실행.
                    getSubItemProcessList({itemId,visibleItemId, saveKeyList, itemMapData : Paper.itemMap[subItemId], isSubItem : true });
                  }
              } 
            });
          });
        }
        const itemId = [];
        const visibleItemId = [];
        const saveKeyList = []
        getSubItemProcessList({itemId, visibleItemId, saveKeyList, itemMapData})
        if(useCallbackPool != false){
          saveKeyList.forEach(data=>{
            window.svy.fn_surveyCi_setAnswer(data.saveKey,"");//비활성화 되는 문항들 값 초기화
          })
        }else{
          // const question = _.find(Paper.itemMap, { saveKeys: [saveKey] });
          // dispatch(answer_addAnswer({
          //   questionId:question.id,
          //   saveKey,
          //   value : '',
          //   commit:true,
          // }))
          dispatch(answer_addAnswerArray(saveKeyList))
        }
        if(itemId.length>0){
          itemId.map(id=>{
            setInputDataArray.push({id, path : "common.disable", value : true})
            addAffectedDataArray.push({questionId : id, path:'common.disable', value : true})
          })
        }
        if(visibleItemId.length>0){
          visibleItemId.map(id=>{
            setInputDataArray.push({id, path : "common.visible", value : false})
            addAffectedDataArray.push({questionId : id, path:'common.visible', value : false})
            if(Paper.itemMap[id].type == 1 && Paper.itemMap[id].common.isRejected == true){
              window.svy.clickRejectButton(Paper.itemMap[id])
            }
          })
        }


        if(setInputDataArray.length > 0){
          dispatch(paper_setInputDataArray(setInputDataArray));
          dispatch(answer_addAffectedDataArray(addAffectedDataArray))
        }
      }
  }catch(e){
    console.error(e);
  }
}

//disabledItemMap을 돌면서 하위항목들 비활성화 또는 활성화
const setSubItemsProperties = ({dispatch, getState, disabledItemMap}) => {
  const {SubItems, Answer, Paper} = getState()
  const disableSubItemMap = disabledItemMap || SubItems.disabledItemMap
  //subitems모델의 disableItemMap을 돌면서 비활성화시켜줘야하는 항목들 비활성화. ex)처음로딩시 하위항목 비활성화 / 반복표 행추가시 추가된행의 하위항목 비활성화
  if(disableSubItemMap){
    const disableItemId = [];
    const visibleItemId = [];
    Object.keys(SubItems.disabledItemMap).forEach(key=>{
      // console.log("🚀 ~ file: SubItems.js:231 ~ Object.keys ~ key:", key)
      const parent = _.find(Paper.itemMap, {subItems:[{selectedTreeData : [key]}]}) //부모항목의 하위항목 동작방식에따라 하위항목을 비활성화할건지 숨길건지 
      let subItemMech = parent.common.subItemMech
      if(parent.common.subItemPosition == true){
        subItemMech = false
      }
      const item = Paper.itemMap[key]
      let itemKey = item.id
      if(!_.isEmpty(item.parentId) && Paper.itemMap[item.parentId].questionType == '1'){
        itemKey = item.parentId
      }
      let tree = []
      flattenNestedTree(Paper.tree, tree);
      let group =  _.find(tree, {id : item.id})
      if(SubItems.disabledItemMap[key].enableItems.length == 0){
        let children = []
        if(group.children?.length > 0){
          flattenNestedTree(group.children, children)
        }
        if(subItemMech != false ){
          disableItemId.push(key);
          children?.map(child=>{
            disableItemId.push(child.id)
          })
        }else{
          visibleItemId.push(key);
          children?.map(child=>{
            visibleItemId.push(child.id)
          })
        }

      }
      if(Paper.itemMap[key]?.extraTextLink?.length > 0){
        Paper.itemMap[key].itemLink.map((item)=>{ 
        //기능코드X 잘못된 서버데이터 수정위함. 선택적주관식 처음페이지 로딩시 disable true속성이 없이 넘어오는경우 처음 subitems initData할때 선택적주관식이면서 기존에 저장된값이 없는경우 disable해줌.
        if(!_.isEmpty(Paper.itemMap[item].extraTextId) && (Answer.affectedData[item] == undefined || Answer.affectedData[item]?.common.disable == true)){ 
          disableItemId.push(item)
        }
      })
      }
    })
    if(disableItemId.length>0){
      dispatch(paper_setItemProperties({itemId : _.uniq(disableItemId), path:"common.disable",value:true,}));
    }
    if(visibleItemId.length >0){
      dispatch(paper_setItemProperties({itemId : _.uniq(visibleItemId), path:"common.visible",value:false,}));
    }
  }

  //기존에 저장된값이 있거나 값을 지정해서 하위항목추가할때 값이 있는 하위항목 활성화.
  // if(Answer.answerData){
  //   const disabledItemMap = _.cloneDeep(SubItems.disabledItemMap);
  //   Object.keys(Answer.answerData).forEach(saveKey=>{
  //     const itemMapData = _.find(Paper.itemMap,{saveKeys:[saveKey]});
  //     if(itemMapData){
  //       if(itemMapData.questionType==1){//주관식일땐
  //         let questionSaveKeys = []
  //         if(Paper.itemMap[itemMapData.id].saveKeys?.length >0){
  //           Paper.itemMap[itemMapData.id].saveKeys.forEach(saveKey=>{
  //             if(SubItems.questionMap[saveKey]?.length>0){
  //               questionSaveKeys.push(SubItems.questionMap[saveKey])
  //             }
  //           })
  //         }
  //         setAfterPaperLink({dispatch, getState,disabledItemMap,questionSaveKeys,itemMapData})
  //       }else if(itemMapData.questionType==2){//객관식일땐 
  //         const answerIndex = parseInt(Answer.answerData[saveKey])-1;
  //         const selectedItem = itemMapData.itemLink[answerIndex];
  //         if(selectedItem){
  //           const questionSaveKeys = SubItems.questionMap[selectedItem];
  //           setAfterPaperLink({dispatch, getState,disabledItemMap,questionSaveKeys,itemMapData,})
  //         }
  //       }
  //     }
  //   })
  // }
}

// 액션 생섬함수 정의
export const setInitData = () => (dispatch, getState) => {
  const { Paper, Answer } = getState();
  batch(()=>{

    dispatch({ type: SET_INIT_DATA, paperModel: Paper,answerModel:Answer,});
    setSubItemsProperties({dispatch, getState})
  })
};
export const set = ({itemMapData,saveKey, useCallbackPool}) => (dispatch, getState) => {
  //useCallbackPool 파라미터추가. setAnswer 콜백으로 하위항목 활성화 호출시 동작이 분리되어야하기때문에
  const { Paper,Answer, } = getState();
  let prevEnableItems = getState().SubItems.disabledItemMap
  batch(()=>{
    dispatch({ type: SET, paperModel: Paper,answerModel:Answer,itemMapData,}); //disabledItemMap을 구하는 역할 
    const { SubItems } = getState();
    let questionSaveKeys = []
    // console.log("🚀 ~ file: SubItems.js:309 ~ batch ~ saveKey:", saveKey)
    SubItems.questionMap[saveKey]?.map(key=> questionSaveKeys.push(key))
    questionSaveKeys?.map(question=>{
      if(Paper.itemMap[question].type == 1 || Paper.itemMap[question].type == 3){ //그룹, 표는 하위의 자식까지
        let result = []
        flattenNestedTree(Paper.tree, result)
        const group = _.find(result, {id : question}) 
        group.children?.map(child=>{
          if(_.isEmpty(_.cloneDeep(SubItems.disabledItemMap)[child.id])){
            questionSaveKeys.push(child.id)
          }
        })
      }
      // else if(Paper.itemMap[question].type == 3 && Paper.itemMap[question].common.tableType == '2'){
        //   const table = _.find(_.find(Paper.tree, {children : [{id : question}]}).children, {id : question})
        //   _.filter(table.children, child=> child.rowIndex != 0).map(child=>{
      //     questionSaveKeys.push(child.id)
      //   })
      // }
    })

    setAfterPaperLink({
      dispatch, getState,
      questionSaveKeys,
      disabledItemMap : _.cloneDeep(SubItems.disabledItemMap),
      itemMapData,
      selectedItem : saveKey,
      prevEnableItems,
      useCallbackPool
    });
  })
};

export const addSubItems = ({repeatTableId, needAddSubitemsList})=>(dispatch, getState)=>{
  const {Subitems, Paper, Answer} = getState()

  batch(()=>{
    dispatch({type: ADD_SUBITEMS, repeatTableId, needAddSubitemsList, paperModel : Paper, answerModel : Answer})
    setSubItemsProperties({dispatch, getState,disabledItemMap : needAddSubitemsList })

  })
}
// **** 초기상태 정의
const initialState = { 
  disabledItemMap:{},//비활성 항목들[초기 셋팅 이후 변하지 않음]//하지만 입력에서는 답변상태에 따라서 disable이 아닐 수 있음 
  questionMap:{},//문항 map
};


//하위항목 활성화될때 해당 항목 활성화시키는 부모항목의 키를 disabledItemMap의 enableItems값에 추가해줌.
const setData = ({state,disabledItemMap,questionMap,itemMapData,paperModel,answerModel}) =>{
  if(itemMapData){
    if(itemMapData.parentId){
      itemMapData = paperModel.itemMap[itemMapData.parentId];
    }
    if(itemMapData.questionType=="1"){
      let itemSaveKeys = _.filter(itemMapData.saveKeys, (v)=>{ //하위항목이 존재하는 saveKey만 배열로담기위해.
        if(paperModel.itemMap[v].subItems?.length > 0){
          return v
        }
      })
      itemSaveKeys.forEach(saveKey=> { //하위항목이 존재하는 saveKey배열을 돌면서 해당 인풋에 값이 있는지확인하고 있으면 enableItems로 push후 하위항목 disabled false.
        const saveKeyItem = paperModel.itemMap[saveKey];
        (questionMap[saveKey]||[]).forEach(key=>{
          const index = disabledItemMap[key].enableItems.indexOf(saveKey);
          if(saveKeyItem.common.displayType != 13 && saveKeyItem.common.displayType != 15){
            if(_.isEmpty(answerModel.answerData[saveKey])){
              if(index>-1){
                disabledItemMap[key].enableItems.splice(index,1);
              }
            }else{
              if(index==-1){
                disabledItemMap[key].enableItems.push(saveKey);
              }
            }
          }else{
            if(answerModel.answerData[saveKey] == 'N' || _.isEmpty(answerModel.answerData[saveKey] )){
              if(index>-1){
                disabledItemMap[key].enableItems.splice(index,1);
              }
            }else{
              if(index==-1){
                disabledItemMap[key].enableItems.push(saveKey);
              }
            }
          }
      })
    });
    }else if(itemMapData.questionType=="2"){
      let checked=[],unchecked=[]
      _.filter(itemMapData.itemLink,itemLink=>{return !_.isEmpty(questionMap[itemLink])}).forEach(itemLink=>{
        let isChecked = false;
        for (const key of itemMapData.saveKeys) {
          if (answerModel.answerData[key] == paperModel.itemMap[itemLink].value) {
            isChecked = true;
            break;
          }
        }
        if(isChecked){
          checked.push(itemLink);
        }else{
          unchecked.push(itemLink);
        }
      });
      checked.forEach(question=>{
        if(questionMap[question]){
          questionMap[question].forEach(key=>{
            const index = disabledItemMap[key].enableItems.indexOf(question);
            if(index==-1){
              disabledItemMap[key].enableItems.push(question);
            }
          });
        }
      });
      unchecked.forEach(question=>{
        if(questionMap[question]){
          questionMap[question].forEach(key=>{
            const index = disabledItemMap[key].enableItems.indexOf(question);
            if(index>-1){
              disabledItemMap[key].enableItems.splice(index,1);
            }
          });
        }
      });
    }
  }
  return disabledItemMap;
}
// **** 리듀서 작성
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_INIT_DATA: {
      const {paperModel,answerModel} = action;
      let questionMap = {};
      let disabledItemMap = {};
      _.filter(paperModel.itemMap,item=>{return !_.isEmpty(item.subItems)}).forEach(item=>{
        item.subItems.forEach(subItem=>{
          subItem.questionList.forEach(key=>{
            questionMap[key] = questionMap[key]||[];
            questionMap[key] = [...questionMap[key],...subItem.selectedTreeData];
          });
          subItem.selectedTreeData.forEach(key=>{
            disabledItemMap[key] = disabledItemMap[key]||{
              items:[],
              enableItems:[]
            };
            if(item.parentId){ //항목id를 삽입하기위해
              disabledItemMap[key].items.push(item.parentId);  
            }else{
              disabledItemMap[key].items.push(item.id);
            }
          })
        });
      });
      Object.keys(answerModel.answerData).forEach(saveKey=>{
        const itemMapData = _.find(paperModel.itemMap,{saveKeys:[saveKey]});
        if(itemMapData){
          setData({state,disabledItemMap,questionMap,itemMapData,paperModel,answerModel})
        }
      })
      return {
        ...state,
        disabledItemMap,
        questionMap
      };
    }
    case SET:{
      const {paperModel,answerModel} = action;
      if(action.itemMapData){
        const disabledItemMap = _.cloneDeep(state.disabledItemMap);
        return {
          ...state,
          disabledItemMap:setData({state,disabledItemMap,questionMap:state.questionMap,itemMapData:action.itemMapData,paperModel,answerModel})
        };
      }else{
        return state;
      }
    }
    case ADD_SUBITEMS : {
      const {repeatTableId, needAddSubitemsList, paperModel, answerModel} = action
      const currentIndex = paperModel.itemMap[repeatTableId].common.info.lastRowIndex
      let disabledItemMap = _.cloneDeep(state.disabledItemMap)
      let questionMap = _.cloneDeep(state.questionMap)
      
      needAddSubitemsList.forEach(item=>{
        item.subItems.forEach(subItem=>{
          subItem.selectedTreeData = subItem.selectedTreeData.map(item=> item+"_"+currentIndex)
          subItem.questionList.forEach(key=>{
            key = key+"_"+currentIndex
            questionMap[key] = questionMap[key]||[];
            questionMap[key] = [...questionMap[key],...subItem.selectedTreeData];
          });
          subItem.selectedTreeData.forEach(key=>{
            disabledItemMap[key] = disabledItemMap[key]||{
              items:[],
              enableItems:[]
            };
            if(item.parentId){ //항목id를 삽입하기위해
              disabledItemMap[key].items.push(item.parentId+"_"+currentIndex);  
            }else{
              disabledItemMap[key].items.push(item.id+"_"+currentIndex);
            }
          })
        });
      });
      Object.keys(answerModel.answerData).forEach(saveKey=>{
        const itemMapData = _.find(paperModel.itemMap,{saveKeys:[saveKey]});
        if(itemMapData){
          setData({state,disabledItemMap,questionMap,itemMapData,paperModel,answerModel})
        }
      })
      return {
        ...state,
        disabledItemMap,
        questionMap
      };
    }
    default: {
      return state;
    }
  }
}