import _ from "lodash";

// 액션 타입 정의
const SET_INIT_DATA = "popup/SET_INIT_DATA";
const OPEN = "popup/OPEN";
const CLOSE = "popup/CLOSE";


// 액션 생섬함수 정의
export const setInitData = ({data, }) => ({ type: SET_INIT_DATA, data });
export const open = ({popupIndex, popupData, focusKey}) => ({ type: OPEN, popupIndex, popupData, focusKey});
export const close = ({popupIndex, }) => async (dispatch, getState)=>{
  let focusKey = getState().Popup.focusKey
  await dispatch({ type: CLOSE, popupIndex,})
  if(!_.isEmpty(focusKey)){

    setTimeout(() => {
      if(getState().Main.inputLayoutCode == '01' ){
        
        window.svy.fn_surveyCi_setFocus(focusKey)
      }else{
        window.svy.fn_surveyCi_setFocus(focusKey.split("_")[1], focusKey.split("_")[0])
      }
    }, 0);
  }
};

// **** 초기상태 정의
/* 
  popupIndex 팝업
  1: 평 계산기
  2: 전공팝업
  3: 지역팝업 
  4: 농림업 생산물 체계표
  
  10: 식량작물
  11: 채소,특용,화초,기타작물
  12: 산나물,약용,관상,표고버섯
  13: 시설작물
  15: 기타과수
  16: 가축종류
  17: 어법종류(단건)
  18: 어법종류순위형(복수건)
  19: 어획품종순위형(복수건)
  20: 양식품종(단건)
  21: 양식방법(단건)
  22: 어법종류(단건)
  23: 어법종류순위형(복수건)
  24: 어획품종순위형(복수건)
  25: 양식품종(단건)
  26: 양식방법(단건)
  27: 행정구역검색
  
  
  
  55: 응답점검표
  66: 항목설명
*/
const initialState = { //항목 배치 순서 담당 
  popupIndex:undefined,  
  popupData:{},     //팝업에서 전달받아서 사용할 데이터 
  focustKey : '' //팝업을 닫으면서 다시 포커스 이동할 대상 항목키
};

// **** 리듀서 작성
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_INIT_DATA: {
      return action.data;
    }
    case OPEN: {
      if(action.popupIndex != '55'){
        document.querySelector("body").className = 'noScroll'
      }
      return {
        ...state,
        popupIndex:action.popupIndex, 
        popupData:action.popupData,
        focusKey :action.focusKey
       
      };
    }
    case CLOSE: {
      document.querySelector("body").className = ''
      return {
        ...state,
        popupIndex:undefined,
        popupData:{},
        focusKey : ''
      };
    }
    
    default: {
      return state;
    }
  }
}