import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  PASSWORD_SUCCESS,
  PASSWORD_FAIL,
  LOGOUT,
} from '../actions/types';

const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));
const initialState = user ? { user: user } : { isRegisted: false, user: null };

/**
 * 로그인 상태관리
 * @param {*} state
 * @param {*} action
 * @returns
 */
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isRegisted: true,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isRegisted: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isRegisted: true,
        user: payload.user,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        user: payload === null ? null : payload.user,
        isRegisted: false,
      };
    case PASSWORD_SUCCESS:
      return {
        ...state,
        isRegisted: true,
        user: payload.user == null ? '' : payload.user,
      };
    case PASSWORD_FAIL:
      return {
        ...state,
        isRegisted: false,
      };
    case LOGOUT:
      return {
        ...state,
        isRegisted: false,
        user: null,
      };
    default:
      return state;
  }
}
