import { LocalDB as db } from './LocalDB';
import { inputCiExecute, inputCiExecuteHhm } from './LocalInputCi';
import {
  P_HHM_IEM_LIST,
  A_HHM_IEM_LIST,
  P_QSN_LIST,
  A_QSN_LIST,
  F_QSN_LIST,
  A_AFF_COVER_IEM,
  A_AFF_HIDE_IEM,
  A_CTVT_FSRCS_IEM,
} from './LocalQsnInfo';
import _, { assign } from 'lodash';

import qsnInfodataFormEcphC01 from './json/ecph/qsn-infodata-c01.json'; // 조사표 : 가기초(건물)
import qsnInfodataFormEcphC02 from './json/ecph/qsn-infodata-c02.json'; // 조사표 : 가기초(가구)
import qsnInfodataFormEcpp from './json/ecpp/qsn-infodata.json'; // 조사표 : 인총
import qsnInfodataFormEcpa from './json/ecpa/qsn-infodata.json'; // 조사표 : 농총
import adZoneTreelistJson from './json/adZoneTree-list.json'; // 행정구역

// 농총 부가정보
/*
import OTDO_FOD_CROPS_SCLAS_CD from './json/userdfncd/OTDO_FOD_CROPS_SCLAS_CD.json';
import OTDO_VGETBL_CPCLPR_LCLAS_CD from './json/userdfncd/OTDO_VGETBL_CPCLPR_LCLAS_CD.json';
import OTDO_VGETBL_CPCLPR_SCLAS_CD from './json/userdfncd/OTDO_VGETBL_CPCLPR_SCLAS_CD.json';
import OTDO_WLDG_MDCRP_LCLAS_CD from './json/userdfncd/OTDO_WLDG_MDCRP_LCLAS_CD.json';
import OTDO_WLDG_MDCRP_SCLAS_CD from './json/userdfncd/OTDO_WLDG_MDCRP_SCLAS_CD.json';
import FCLTY_CROPS_CD from './json/userdfncd/FCLTY_CROPS_CD.json';
import ETC_FTR_CD from './json/userdfncd/ETC_FTR_CD.json';
import LVSTCK_KND_CD from './json/userdfncd/LVSTCK_KND_CD.json';
import SL_FSW_KND_CD from './json/userdfncd/SL_FSW_KND_CD.json';
import SL_FSW_KND_LCLAS_CD from './json/userdfncd/SL_FSW_KND_LCLAS_CD.json';
import SL_CHFFSH_SPCIES_LCLAS_CD from './json/userdfncd/SL_CHFFSH_SPCIES_LCLAS_CD.json';
import SL_CHFFSH_SPCIES_CD from './json/userdfncd/SL_CHFFSH_SPCIES_CD.json';
import SL_RAISNG_SPCIES_LCLAS_CD from './json/userdfncd/SL_RAISNG_SPCIES_LCLAS_CD.json';
import SL_RAISNG_SPCIES_CD from './json/userdfncd/SL_RAISNG_SPCIES_CD.json';
import SL_RAISNG_MTHD_CD from './json/userdfncd/SL_RAISNG_MTHD_CD.json';
import IWRS_FSW_KND_CD from './json/userdfncd/IWRS_FSW_KND_CD.json';
import IWRS_CHFFSH_SPCIES_LCLAS_CD from './json/userdfncd/IWRS_CHFFSH_SPCIES_LCLAS_CD.json';
import IWRS_CHFFSH_SPCIES_CD from './json/userdfncd/IWRS_CHFFSH_SPCIES_CD.json';
import IWRS_RAISNG_SPCIES_LCLAS_CD from './json/userdfncd/IWRS_RAISNG_SPCIES_LCLAS_CD.json';
import IWRS_RAISNG_SPCIES_CD from './json/userdfncd/IWRS_RAISNG_SPCIES_CD.json';
import IWRS_RAISNG_MTHD_CD from './json/userdfncd/IWRS_RAISNG_MTHD_CD.json';
*/
/*
import qsnInfodataFormInfoEcphC01 from './json/ecph/qsn-infodata-info-c01.json';
import qsnInfodataFormInfoEcphC02 from './json/ecph/qsn-infodata-info-c02.json';
import qsnInfodataFormInfoEcpp from './json/ecpp/qsn-infodata-info.json';
import qsnInfodataFormInfoA02Ecpp from './json/ecpp/qsn-infodata-info-a02.json';
import qsnInfodata1Form from './json/ecpa/qsn-infodata1.json';
import qsnInfodata2Form from './json/ecpa/qsn-infodata2.json';
import qsnInfodata3Form from './json/ecpa/qsn-infodata3.json';
import qsnInfodata4Form from './json/ecpa/qsn-infodata4.json';
*/

/**
 * 조사표 정보 조회
 * @param {*} config
 * @returns
 */
export const qsnInfodata = async (config) => {
  console.log('qsnInfodata');

  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));
  // let hshldNmst = await db.hshldNmstList.where({ hshldNmstId: user.hshldNmstId }); // 미전송 데이터만 조회
  let hshldNmst = await db.hshldNmstList.get(user.hshldNmstId);

  // 데이터 포멧

  let qsnInfodataForm = null;
  let affsCover = null;
  // 인총
  if (process.env.REACT_APP_PRDCTN_ID === '220001') {
    qsnInfodataForm = _.cloneDeep(qsnInfodataFormEcpp);
    // 농총
  } else if (process.env.REACT_APP_PRDCTN_ID === '220002') {
    qsnInfodataForm = _.cloneDeep(qsnInfodataFormEcpa);
    // 가기초
  } else {
    if (user.qsnSeCd == 'C01') {
      // 건물
      qsnInfodataForm = _.cloneDeep(qsnInfodataFormEcphC01);
    } else {
      // 가구
      qsnInfodataForm = _.cloneDeep(qsnInfodataFormEcphC02);
    }
  }

  // 조사표 포멧
  let svyQsnInfodataForm = null; // 설계정보 info form
  let qsnDsgnstdrInfo = null; // indexedDB local 설계정보
  let createAnswer = {}; // answer

  // 조사표ID
  let qsnId = new URL(config.baseURL + config.url).searchParams.get('qsnId');

  // 가구원 순번(인총)
  // let hhmSn = 1;
  // if (process.env.REACT_APP_PRDCTN_ID === '220001') {
  //   hhmSn = new URL(config.baseURL + config.url).searchParams.get('hhmSn');
  // }

  if (process.env.REACT_APP_PRDCTN_ID === '220001') {
    // 인총
    // qsnId = `${pQsnId}_${hhmSn}`;
    if (user.qsnSeCd === 'A02') {
      qsnId = `${P_QSN_LIST[0]}`;
    } else if (user.qsnSeCd === 'A03') {
      qsnId = `${P_QSN_LIST[1]}`;
    } else if (user.qsnSeCd === 'A04') {
      qsnId = `${P_QSN_LIST[2]}`;
    } else {
      qsnId = `${P_QSN_LIST[3]}`;
    }
    // data qsnId
    qsnInfodataForm.data.qsnId = qsnId;
    qsnInfodataForm.data.qsnSeCd = user.qsnSeCd;

    qsnDsgnstdrInfo = await db.qsnDsgnstdrList.get({ qsnSeCd: user.qsnSeCd });
    svyQsnInfodataForm = qsnDsgnstdrInfo.qsnInfo;

    if (!_.isEmpty(hshldNmst?.svyList[qsnId])) {
      console.log('저장된 설계정보 있음');
      qsnInfodataForm.data = _.cloneDeep(hshldNmst.svyList[qsnId].data);
    }

    // 명부 셋팅
    qsnInfodataForm.data.hshldNmstInfo = { dwllgKndCd: user.dwllgKndCd };

    if (user.qsnSeCd !== 'A02' && user.qsnSeCd !== 'A03') {
      // 온라인세션 가진 상태에서 오프라인으로 세션 재취득시 발생되는 문제(브라우저 닫고 다시 열면 정상)
      // || hshldNmst?.svyList[qsnId].isSave === 'N'
      if (_.isEmpty(hshldNmst?.svyList?.hhmCover)) {
        alert('가구원 저장 정보가 존재하지 않습니다.');
        // navigate(`/${appMode.slice(0, 4).toLowerCase()}ba110m01`);
        return false;
      }
      // 인총 first 조사표 첫번째 가구원으로 조사표 초기데이터 생성
      // let hhmCover = _.filter(hshldNmst.svyList.hhmCover.data.answer, ['hhmSn', Number(hhmSn)])[0];
      // 항목중심 인총 answer 생성

      _.each(hshldNmst.svyList.hhmCover.data.answer, (hhmCover, index) => {
        createAnswer = {};
        createAnswer['HHM_SN'] = String(hhmCover.hhmSn);
        createAnswer[P_HHM_IEM_LIST[0]] = hhmCover.hhmSn;
        createAnswer[P_HHM_IEM_LIST[1]] = hhmCover.hhmNm;
        createAnswer[P_HHM_IEM_LIST[2]] = hhmCover.hhmSexdstnCd;
        createAnswer[P_HHM_IEM_LIST[3]] = hhmCover.hhmBryy;
        createAnswer[P_HHM_IEM_LIST[4]] = hhmCover.hhmBrthMm;
        createAnswer[P_HHM_IEM_LIST[5]] = hhmCover.hhmBrthDay;
        createAnswer[P_HHM_IEM_LIST[6]] = hhmCover.hhmAge;
        createAnswer[P_HHM_IEM_LIST[7]] = hhmCover.hshldrRelCd;
        if (_.some(qsnInfodataForm.data.answer, { HHM_SN: String(hhmCover.hhmSn) })) {
          if (hhmCover.useYn === 'Y') {
            // 타인가구 제거
            _.remove(qsnInfodataForm.data.answer, { HHM_SN: String(hhmCover.hhmSn) });
            _.remove(qsnInfodataForm.data.attribute, { HHM_SN: String(hhmCover.hhmSn) });
          } else {
            // 가구원으로 교체
            _.assign(qsnInfodataForm.data.answer[index], createAnswer);
          }
        } else {
          // 없을 경우 추가
          qsnInfodataForm.data.answer[index] = createAnswer;
        }
      });
    }
  } else if (process.env.REACT_APP_PRDCTN_ID === '220002') {
    // 농총 - 농림어가 여부에 따른 처리 S
    affsCover = hshldNmst.svyList.affsCover;

    // 임가가 아닐경우 재배임가면적 체크
    let ctvtFsrcsRspnsYn = '';
    if (affsCover.frmhsYn === 'Y' && affsCover.frhsYn !== 'Y') {
      if (hshldNmst.svyList[A_QSN_LIST[0]] !== undefined) {
        ctvtFsrcsRspnsYn = getCtvtFsrcsRspnsYn(hshldNmst.svyList[A_QSN_LIST[0]]?.data?.answer[0]);
      }
    }
    qsnInfodataForm.data.arfrSeInfo = {
      EXMN_FRMHS_YN: affsCover.frmhsYn,
      EXMN_FRHS_YN: affsCover.frhsYn,
      EXMN_IWRS_FHRSHS_YN: affsCover.iwrsFhrshsYn,
      EXMN_SL_FHRSHS_YN: affsCover.slFhrshsYn,
      CTVT_FSRCS_RSPNS_YN: ctvtFsrcsRspnsYn,
    };
    let tabInfo = qsnInfodataForm.data.tabInfo;

    let frhsYnTabSet = false;

    // 임업 특화
    if (qsnId === A_QSN_LIST[1]) {
      frhsYnTabSet = true;
    }

    // step1 탭활성화
    if (
      affsCover.frmhsYn === 'Y' &&
      (qsnId === A_QSN_LIST[0] || _.isEmpty(qsnId)) &&
      !frhsYnTabSet
    ) {
      // 농업
      tabInfo[0] = { ...tabInfo[0], active: 'This' };
      qsnId = A_QSN_LIST[0];
    } else if (affsCover.frhsYn === 'Y' || frhsYnTabSet) {
      // 임업
      tabInfo[0] = { ...tabInfo[0], active: '' };
      tabInfo[1] = { ...tabInfo[1], active: 'This' };
      qsnId = A_QSN_LIST[1];
    } else if (affsCover.slFhrshsYn === 'Y' && (qsnId === A_QSN_LIST[2] || _.isEmpty(qsnId))) {
      // 해수면
      tabInfo[2] = { ...tabInfo[2], active: 'This' };
      qsnId = A_QSN_LIST[2];
    } else if (affsCover.iwrsFhrshsYn === 'Y' && (qsnId === A_QSN_LIST[3] || _.isEmpty(qsnId))) {
      // 내수면
      tabInfo[3] = { ...tabInfo[3], active: 'This' };
      qsnId = A_QSN_LIST[3];
    }

    // step2 조사표 포멧 설정
    qsnInfodataForm.data.qsnId = qsnId != null && !_.isEmpty(qsnId) ? qsnId : qsnId;

    qsnDsgnstdrInfo = null;
    if (A_QSN_LIST[0] === qsnId) {
      qsnDsgnstdrInfo = await db.qsnDsgnstdrList.get({ qsnSeCd: 'B01' });
      // svyQsnInfodataForm = qsnInfodata1Form;
    } else if (A_QSN_LIST[1] === qsnId) {
      qsnDsgnstdrInfo = await db.qsnDsgnstdrList.get({ qsnSeCd: 'B02' });
      // svyQsnInfodataForm = qsnInfodata2Form;
    } else if (A_QSN_LIST[2] === qsnId) {
      qsnDsgnstdrInfo = await db.qsnDsgnstdrList.get({ qsnSeCd: 'B03' });
      // svyQsnInfodataForm = qsnInfodata3Form;
    } else if (A_QSN_LIST[3] === qsnId) {
      qsnDsgnstdrInfo = await db.qsnDsgnstdrList.get({ qsnSeCd: 'B04' });
      // svyQsnInfodataForm = qsnInfodata4Form;
    }

    // 설계정보 적재
    svyQsnInfodataForm = qsnDsgnstdrInfo.qsnInfo;

    // step3 탭삭제
    if (affsCover.frmhsYn === 'N') {
      // 농업
      _.remove(tabInfo, { qsnId: A_QSN_LIST[0] });
    }
    if (affsCover.frmhsYn === 'N' && affsCover.frhsYn === 'N') {
      // 임업
      _.remove(tabInfo, { qsnId: A_QSN_LIST[1] });
    }
    if (affsCover.slFhrshsYn === 'N') {
      // 해수면
      _.remove(tabInfo, { qsnId: A_QSN_LIST[2] });
    }
    if (affsCover.iwrsFhrshsYn === 'N') {
      // 내수면
      _.remove(tabInfo, { qsnId: A_QSN_LIST[3] });
    }
  } else if (process.env.REACT_APP_PRDCTN_ID === '220003') {
    // 가기초
    // 건물, 거처 조사표
    if (user.qsnSeCd == 'C01') {
      // 건물
      qsnId = `${F_QSN_LIST[0]}`;
      // svyQsnInfodataForm = qsnInfodataFormInfoEcphC01;
    } else {
      // 가구
      qsnId = `${F_QSN_LIST[1]}`;
      // svyQsnInfodataForm = qsnInfodataFormInfoEcphC02;
    }
    qsnInfodataForm.data.qsnId = qsnId;
    qsnDsgnstdrInfo = await db.qsnDsgnstdrList.get({ qsnSeCd: user.qsnSeCd });
    svyQsnInfodataForm = qsnDsgnstdrInfo.qsnInfo;
  }

  // 농총, 가기초 answer 생성
  if (process.env.REACT_APP_PRDCTN_ID === '220002') {
    if (!_.isEmpty(hshldNmst?.svyList[qsnId])) {
      // qsnInfodataForm.data = _.cloneDeep(hshldNmst.svyList[qsnId].data);
      // 농업이 있을경우 무적권 복사네..
      var svyAnswer = _.cloneDeep(hshldNmst.svyList[qsnId].data.answer);
      _.each(svyAnswer, (answer, index) => {
        if (index === 0) {
          createAnswer = answer;
        } else {
          var keys = _.keys(answer);
          _.each(keys, async (key) => {
            answer[`${index}_${key}`] = answer[key];
            delete answer[key];
          });

          let hhmCover = _.cloneDeep(
            _.find(hshldNmst.svyList.hhmCover.data.answer, {
              hhmSn: Number(answer[`${index}_HHM_SN`]),
            }),
          );

          // 농총 가구원 데이터 마이그레이션S
          answer[`${index}_${A_HHM_IEM_LIST[0]}`] = hhmCover.hhmBryy;
          answer[`${index}_${A_HHM_IEM_LIST[1]}`] = hhmCover.hhmBrthMm;
          answer[`${index}_${A_HHM_IEM_LIST[2]}`] = hhmCover.hhmBrthDay;
          answer[`${index}_${A_HHM_IEM_LIST[3]}`] = hhmCover.hhmAge;
          // 농총 가구원 데이터 마이그레이션E

          Object.assign(createAnswer, answer);
        }
      });

      // 조사표 정보 - 속성정보
      qsnInfodataForm.data.attribute = hshldNmst.svyList[qsnId].data.attribute;
    } else if (_.isEmpty(hshldNmst?.svyList[qsnId])) {
      // 초기 셋팅
      // 가구원 셋팅
      let hhmAnswer = _.cloneDeep(hshldNmst.svyList.hhmCover.data.answer);

      _.each(hhmAnswer, (hhmCover, index) => {
        // 농총 가구원 데이터 마이그레이션S
        createAnswer[`${index + 1}_${A_HHM_IEM_LIST[0]}`] = hhmCover.hhmBryy;
        createAnswer[`${index + 1}_${A_HHM_IEM_LIST[1]}`] = hhmCover.hhmBrthMm;
        createAnswer[`${index + 1}_${A_HHM_IEM_LIST[2]}`] = hhmCover.hhmBrthDay;
        createAnswer[`${index + 1}_${A_HHM_IEM_LIST[3]}`] = hhmCover.hhmAge;
        // 농총 가구원 데이터 마이그레이션E
      });
    }

    // 입업 경영주에서 사용
    if (qsnId === A_QSN_LIST[1]) {
      var svyAnswer = _.cloneDeep(hshldNmst?.svyList[A_QSN_LIST[0]]?.data?.answer);
      _.each(svyAnswer, (answer, index) => {
        if (index === 0) {
          // createAnswer = answer;
        } else {
          var keys = _.keys(answer);
          _.each(keys, async (key) => {
            answer[`${index}_${key}`] = answer[key];
            delete answer[key];
          });

          Object.assign(createAnswer, answer);
        }
      });
    }

    // 표지정보 항목 설정

    var affCoverKeys = _.keys(A_AFF_COVER_IEM);
    _.each(affCoverKeys, async (coverKey) => {
      createAnswer[A_AFF_COVER_IEM[coverKey]] = affsCover[coverKey];
    });

    // 조사표 정보
    qsnInfodataForm.data.answer = createAnswer;
  } else if (process.env.REACT_APP_PRDCTN_ID === '220003') {
    if (!_.isEmpty(hshldNmst?.svyList[qsnId])) {
      var svyAnswer = _.cloneDeep(hshldNmst.svyList[qsnId].data.answer);
      _.each(svyAnswer, (answer, index) => {
        if (index === 0) {
          createAnswer = answer;
        } else {
          var keys = _.keys(answer);
          _.each(keys, async (key) => {
            answer[`${index}_${key}`] = answer[key];
            delete answer[key];
          });

          Object.assign(createAnswer, answer);
        }
      });

      // 조사표 정보 - 속성정보
      qsnInfodataForm.data.attribute = hshldNmst.svyList[qsnId].data.attribute;
    }
    // 조사표 정보
    qsnInfodataForm.data.answer = createAnswer;
  }

  if (process.env.REACT_APP_PRDCTN_ID !== '220003') {
    if (!_.isEmpty(hshldNmst?.svyList[qsnId])) {
      // 마지막 항목
      qsnInfodataForm.data.lastInptIem = hshldNmst.svyList[qsnId].data.lastInptIem;
    }
    if (user.qsnSeCd === 'A02' || user.qsnSeCd === 'A03') {
      qsnInfodataForm.data.hmember = [];
    } else {
      // 가구원 정보 따로조사 제외
      qsnInfodataForm.data.hmember = _.filter(
        hshldNmst.svyList.hhmCover.data.snakeCaseAnswer,
        (hhmInfo) => hhmInfo.USE_YN !== 'Y',
      );
    }
  }

  // 조사표
  qsnInfodataForm.info = svyQsnInfodataForm;
  qsnInfodataForm.data.statsExmnSeCd = svyQsnInfodataForm.qsnSeCd;
  console.log(qsnInfodataForm);

  return qsnInfodataForm;
};

/**
 * 재배임가 여부
 * @param {*} answer
 * @returns
 */
const getCtvtFsrcsRspnsYn = (answer) => {
  let ctvtFsrcsSum = 0;
  _.each(A_CTVT_FSRCS_IEM, (iemId) => {
    ctvtFsrcsSum += Number(answer[iemId]);
  });

  return 0 < ctvtFsrcsSum ? 'Y' : 'N';
};

/**
 * 행정구역
 * @returns
 */
export const adZoneTreelist = async () => {
  console.log('adZoneTreelist');
  return adZoneTreelistJson;
};

/**
 * 파라데이터 저장
 * @param {*} config
 * @returns
 */
export const processDataSave = async (config) => {
  console.log('processDataSave');

  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));
  let hshldNmst = await db.hshldNmstList.get(user.hshldNmstId);
  hshldNmst.svyList.processData = {
    ...JSON.parse(config.data),
    method: config.method,
    requestUrl: config.url,
    isSave: 'Y',
    user: user,
  };

  await db.hshldNmstList.put(hshldNmst);

  return {
    result: 'SUCCESS',
  };
};

/**
 * 조사표 저장
 * @param {*} config
 * @returns
 */

export const insertSvyData = async (config) => {
  console.log('insertSvyData');

  const innerNmstSave = async (putHshldNmstId, index) => {
    let hshldNmst = await db.hshldNmstList.get(putHshldNmstId);
    let isSave = 'Y';
    // 첫번째 대표 가구만 전송함.
    if (Number(index) > 0) {
      isSave = 'N';
    }

    if (_.isEmpty(hshldNmst)) {
      if (user.qsnSeCd === 'C01') {
        hshldNmst = {
          hshldNmstId: putHshldNmstId,
          dwllgNo: user.dwllgNo,
          bldgMngNo: user.bldgMngNo,
          svyList: [],
        };
      } else if (user.qsnSeCd === 'C02') {
        hshldNmst = {
          hshldNmstId: putHshldNmstId,
          svyList: [],
        };
      } else {
        hshldNmst = {
          hshldNmstId: putHshldNmstId,
          partcptnNo: user.partcptnNo,
          svyList: [],
        };
      }
    }

    hshldNmst = {
      ...hshldNmst,
      isSave: isSave,
    };

    let saveSvyData = _.cloneDeep(configData);
    // 농총 임업조사표일경우 숨김항목 제외하여 저장
    if (process.env.REACT_APP_PRDCTN_ID === '220002' && configData.data.statsExmnSeCd === 'B02') {
      _.each(A_AFF_HIDE_IEM, (hideIem) => {
        delete saveSvyData.data.answer[0][hideIem];
      });
    }

    // 가기초 일 경우 키와 조사표정보가 다르기 때문에 user를 조사표 별로 셋팅(인총, 농총은 고민 필요)
    hshldNmst.svyList[qsnId] = {
      ...saveSvyData,
      method: config.method,
      requestUrl: config.url,
      isSave: isSave,
      user: user,
    };

    // if (process.env.REACT_APP_PRDCTN_ID === '220003') {
    //   // 가기초만 user를 조사표안에 넣었는지 확인 필요.
    //   hshldNmst.svyList[qsnId] = user;
    // }

    await db.hshldNmstList.put(hshldNmst);
  };

  let configData = JSON.parse(config.data);
  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));

  let qsnId = '';
  if (!_.isUndefined(configData.data.qsnId)) {
    qsnId = configData.data.qsnId;
  } else {
    qsnId = new URL(config.baseURL + config.url).searchParams.get('qsnId');
  }

  // 가구원 순번(인총)
  // if (process.env.REACT_APP_PRDCTN_ID === '220001') {
  //   let hhmSn = 1;
  //   hhmSn = new URL(config.baseURL + config.url).searchParams.get('hhmSn');
  //   qsnId = `${qsnId}_${hhmSn}`;
  // }

  let bndeInptTrgtHshldNmstId = null;
  if (!_.isUndefined(user.bndeInptTrgtHshldNmstId)) {
    bndeInptTrgtHshldNmstId = user.bndeInptTrgtHshldNmstId.split(',');
  }

  if (
    process.env.REACT_APP_PRDCTN_ID === '220003' &&
    bndeInptTrgtHshldNmstId != null &&
    bndeInptTrgtHshldNmstId.length > 1
  ) {
    let index = 0;
    for (const bndeHshldNmstId of bndeInptTrgtHshldNmstId) {
      // 치명적인 곳..
      await innerNmstSave(bndeHshldNmstId, index);
      index++;
    }
  } else {
    await innerNmstSave(user.hshldNmstId);
  }

  let okList = configData.data.okList;

  let result = {};

  if (configData.data.tmprStrgYn !== 'Y' && user.emtdChartrCd !== '4') {
    result = await inputCiExecute(user, configData.data, okList);
  } else {
    result = {
      result: 'SUCCESS',
      svyResultList: [
        {
          result: 'SUCCESS',
          ciResult: 'SUCCESS',
          ciList: [],
        },
      ],
    };
  }
  return result;
};

/**
 * 조사표 공통 코드
 * @param {*} config
 * @returns
 */
export const userdfncdList = async (config) => {
  // const searchParams = new URLSearchParams(config.url);
  const cdGroupId = new URL(config.baseURL + config.url).searchParams.get('cdGroupId');
  let commonCd = await db.commonCdList.get(cdGroupId);
  return commonCd;
  // 내부 파일 불러올 경우
  // switch (cdGroupId) {
  //   case 'OTDO_FOD_CROPS_SCLAS_CD':
  //     return OTDO_FOD_CROPS_SCLAS_CD;
  //   case 'OTDO_VGETBL_CPCLPR_LCLAS_CD':
  //     return OTDO_VGETBL_CPCLPR_LCLAS_CD;
  //   case 'OTDO_VGETBL_CPCLPR_SCLAS_CD':
  //     return OTDO_VGETBL_CPCLPR_SCLAS_CD;
  //   case 'OTDO_WLDG_MDCRP_LCLAS_CD':
  //     return OTDO_WLDG_MDCRP_LCLAS_CD;
  //   case 'OTDO_WLDG_MDCRP_SCLAS_CD':
  //     return OTDO_WLDG_MDCRP_SCLAS_CD;
  //   case 'FCLTY_CROPS_CD':
  //     return FCLTY_CROPS_CD;
  //   case 'ETC_FTR_CD':
  //     return ETC_FTR_CD;
  //   case 'LVSTCK_KND_CD':
  //     return LVSTCK_KND_CD;
  //   case 'SL_FSW_KND_CD':
  //     return SL_FSW_KND_CD;
  //   case 'SL_FSW_KND_LCLAS_CD':
  //     return SL_FSW_KND_LCLAS_CD;
  //   case 'SL_CHFFSH_SPCIES_LCLAS_CD':
  //     return SL_CHFFSH_SPCIES_LCLAS_CD;
  //   case 'SL_CHFFSH_SPCIES_CD':
  //     return SL_CHFFSH_SPCIES_CD;
  //   case 'SL_RAISNG_SPCIES_LCLAS_CD':
  //     return SL_RAISNG_SPCIES_LCLAS_CD;
  //   case 'SL_RAISNG_SPCIES_CD':
  //     return SL_RAISNG_SPCIES_CD;
  //   case 'SL_RAISNG_MTHD_CD':
  //     return SL_RAISNG_MTHD_CD;
  //   case 'IWRS_FSW_KND_CD':
  //     return IWRS_FSW_KND_CD;
  //   case 'IWRS_CHFFSH_SPCIES_LCLAS_CD':
  //     return IWRS_CHFFSH_SPCIES_LCLAS_CD;
  //   case 'IWRS_CHFFSH_SPCIES_CD':
  //     return IWRS_CHFFSH_SPCIES_CD;
  //   case 'IWRS_RAISNG_SPCIES_LCLAS_CD':
  //     return IWRS_RAISNG_SPCIES_LCLAS_CD;
  //   case 'IWRS_RAISNG_SPCIES_CD':
  //     return IWRS_RAISNG_SPCIES_CD;
  //   case 'IWRS_RAISNG_MTHD_CD':
  //     return IWRS_RAISNG_MTHD_CD;

  //   default:
  //     return '';
  // }
};
