export const P_EXMN_TRGT_CRTR_YMD = '2024-05-01'; // 인총 조사 기준일
export const A_EXMN_TRGT_CRTR_YMD = '2024-04-01'; // 농총 조사 기준일

/**
 * 조사표ID
 */
export const A02 = '2200010117SD0000286'; // 인총 - CAPI 빈집 조사표
export const A03 = '2200010117SD0000288'; // 인총 - CAPI 시설 관리자 조사표
export const A04 = '2200010117SD0000295'; // 인총 - CAPI 사회시설 조사표
export const A06 = '2200010117SD0000275'; // 인총 - CAPI 가구원 중심 조사표
export const B01 = '2200020111SD0000253'; // 농총 - CAPI 농업 조사표
export const B02 = '2200020116SD0000259'; // 농총 - CAPI 임업 조사표
export const B03 = '2200020116SD0000262'; // 농총 - CAPI 해수면 조사표
export const B04 = '2200020116SD0000265'; // 농총 - CAPI 내수면 조사표
export const C01 = '2200031012SD0000236'; // 가기초 - CAPI 건물 조사표
export const C02 = '2200031012SD0000239'; // 가기초 - CAPI 가구 조사표

export const P_QSN_LIST = [A02, A03, A04, A06]; // 인총 조사표 목록
export const A_QSN_LIST = [B01, B02, B03, B04]; // 농총 조사표 목록
export const F_QSN_LIST = [C01, C02]; // 가기초 조사표 목록

const P_REQ_LIST = [
  'rspnInfo',
  'hhmCover',
  A06,
  A02,
  'exmnStts',
  'processData',
  'sendOtherHhmrSms',
]; //인총 전송 목록
const A_REQ_LIST = [
  'affsCover',
  'hhmCover',
  B01,
  B02,
  B03,
  B04,
  'exmnStts',
  'acensusCapiInfo',
  'processData',
]; // 농총 전송 목록
const F_REQ_LIST = [C01, 'exmnSttsC01', C02, 'exmnSttsC02', 'processData']; // 가기초 전송 목록

/**
 * 인총 가구원 기본정보 통계항목 ID
 * hhmSn;
 * hhmNm;
 * hhmSexdstnCd;
 * hhmBryy;
 * hhmBrthMm;
 * hhmBrthDay;
 * hhmAge;
 * hshldrRelCd;
 */
export const P_HHM_IEM_LIST = [
  '2200010412DV0004829',
  '2200010103DV0000359',
  '2200010103DV0000361',
  '2200010103DV0000365',
  '2200010103DV0000366',
  '2200010103DV0000367',
  '2200010412DV0004831',
  '2200010131DV0006451',
];

/**
 * 농총 가구원 기본정보 통계항목 ID
 * hhmBryy;
 * hhmBrthMm;
 * hhmBrthDay;
 * hhmAge;
 */
export const A_HHM_IEM_LIST = [
  '2200020210DV0001171',
  '2200020213DV0001525',
  '2200020213DV0001526',
  '2200020601DV0005791',
];

/**
 * 농총 : 조사표 설계정보에 표지항목을 설정
 */
export const A_AFF_COVER_IEM = {
  frmhsPfdfClvtYn: '2200020605DV0005813',
  frmhsArglvprdPrdctnYn: '2200020605DV0005815',
  frhsSlvcltrPrfmncYn: '2200020605DV0005821',
  frhsTrfSptdMngmtYn: '2200020605DV0005823',
  slRlvtMatterEptyYn: '2200020605DV0005843',
  frmhsLvstckBrdYn: '2200020605DV0005817',
  frmhsRlvtMatterEptyYn: '2200020605DV0005819',
  frhsPickFsrcsNtslYn: '2200020605DV0005825',
  frhsRlvtMatterEptyYn: '2200020605DV0005827',
  iwrsFshrAqidMngmtYn: '2200020605DV0005829',
  iwrsFshrMprcNtslYn: '2200020605DV0005831',
  iwrsRaisngMprcHoldYn: '2200020605DV0005833',
  iwrsRlvtMatterEptyYn: '2200020605DV0005835',
  slFshrAqidMngmtYn: '2200020605DV0005837',
  slFshrMprcNtslYn: '2200020605DV0005839',
  slRaisngMprcHoldYn: '2200020605DV0005841',
};

/**
 * 농총 숨김 항목
 *
 * 가축사육여부 2200020214DV0002463
 * 총판매금액 2200020215DV0002646
 * 농업관련사업_농축산물직판장운영 2200020215DV0002820
 * 농업관련사업_농축산물직거래 2200020215DV0002821
 * 농업관련사업_식당경영 2200020215DV0002826
 * 농업관련사업_카페경영 2200020219DV0006652
 * 농업관련사업_농축산물가공업 2200020215DV0002827
 * 농업관련사업_농기계작업대행 2200020215DV0002828
 * 농업관련사업_농촌관광사업 2200020215DV0002829
 * 농업관련사업_하지않음 2200020215DV0002830
 * 시설온실보유여부 2200020213DV0001858
 */
export const A_AFF_HIDE_IEM = [
  '2200020214DV0002463',
  '2200020215DV0002646',
  '2200020215DV0002820',
  '2200020215DV0002821',
  '2200020215DV0002826',
  '2200020219DV0006652',
  '2200020215DV0002827',
  '2200020215DV0002828',
  '2200020215DV0002829',
  '2200020215DV0002830',
  '2200020213DV0001858',
];

/**
 * 농총 재배임가 항목
 *
 * 2200020213DV0001835 취나물재배면적
 * 2200020213DV0001836 도라지재배면적
 * 2200020213DV0001837 더덕재배면적
 * 2200020213DV0001838 두릅재배면적
 * 2200020213DV0001839 고사리재배면적
 * 2200020213DV0001840 기타산나물재배면적
 * 2200020213DV0001841 오미자재배면적
 * 2200020213DV0001842 산수유재배면적
 * 2200020213DV0001843 산양삼재배면적
 * 2200020213DV0001844 헛개나무재배면적
 * 2200020213DV0001845 구기자재배면적
 * 2200020213DV0001846 오갈피재배면적
 * 2200020213DV0001847 당귀재배면적
 * 2200020213DV0001848 수액채취작물재배면적
 * 2200020213DV0001849 기타약용작물재배면적
 * 2200020213DV0001850 조경수재배면적
 * 2200020213DV0001851 분재재배면적
 * 2200020213DV0001852 야생화재배면적
 * 2200020213DV0001853 잔디재배면적
 * 2200020213DV0001854 기타관상작물재배면적
 * 2200020213DV0001855 표고버섯재배면적
 * 2200020214DV0001982 시설산나물재배면적
 * 2200020214DV0001983 시설약용작물재배면적
 * 2200020214DV0001984 시설관상작물재배면적
 * 2200020214DV0001986 시설톱밥표고버섯재배면적
 * 2200020214DV0001988 시설원목표고버섯재배면적
 * 2200020214DV0002407 밤재배면적
 * 2200020214DV0002408 호두재배면적
 * 2200020214DV0002409 대추재배면적
 * 2200020214DV0002410 떪은감재배면적
 * 2200020214DV0002411 잣재배면적
 * 2200020214DV0002412 은행재배면적
 * 2200020214DV0002413 복분자산딸기재배면적
 */
export const A_CTVT_FSRCS_IEM = [
  '2200020213DV0001835',
  '2200020213DV0001836',
  '2200020213DV0001837',
  '2200020213DV0001838',
  '2200020213DV0001839',
  '2200020213DV0001840',
  '2200020213DV0001841',
  '2200020213DV0001842',
  '2200020213DV0001843',
  '2200020213DV0001844',
  '2200020213DV0001845',
  '2200020213DV0001846',
  '2200020213DV0001847',
  '2200020213DV0001848',
  '2200020213DV0001849',
  '2200020213DV0001850',
  '2200020213DV0001851',
  '2200020213DV0001852',
  '2200020213DV0001853',
  '2200020213DV0001854',
  '2200020213DV0001855',
  '2200020214DV0001982',
  '2200020214DV0001983',
  '2200020214DV0001984',
  '2200020214DV0001986',
  '2200020214DV0001988',
  '2200020214DV0002407',
  '2200020214DV0002408',
  '2200020214DV0002409',
  '2200020214DV0002410',
  '2200020214DV0002411',
  '2200020214DV0002412',
  '2200020214DV0002413',
];

/**
 * 온라인 전송 목록
 * @returns
 */
export const getReqList = () => {
  if (process.env.REACT_APP_PRDCTN_ID === '220001') {
    return P_REQ_LIST;
  } else if (process.env.REACT_APP_PRDCTN_ID === '220002') {
    return A_REQ_LIST;
  } else {
    return F_REQ_LIST;
  }
};
