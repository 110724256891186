import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import ko from './ko-KR.json';
import en from './en-US.json';
import zh from './zh-CN.json';
import ja from './ja-JP.json';
import th from './th-TH.json';
import ru from './ru-RU.json';
import vi from './vi-VI.json';
import mn from './mn-MN.json';
import uz from './uz-UZ.json';
import kh from './km-KH.json';
import si from './si-LK.json';
import my from './my-MM.json';
import ne from './ne-NP.json';
import id from './id-ID.json';
import km from './km-KH.json';
import kk from './kk-KZ.json';


const resources = {
  ko : { translation : ko},
  en : { translation : en},
  zh : { translation : zh},
  th : { translation : th},
  vi : { translation : vi},
  uz : { translation : uz},
  si : { translation : si},
  my : { translation : my},
  ne : { translation : ne},
  id : { translation : id},
  ru : { translation : ru},
  km : { translation : km},
  kk : { translation : kk},
};


i18n.use(initReactI18next).init({
  resources,
  lng: sessionStorage.getItem('language') ? sessionStorage.getItem('language') : 'ko',
  fallbackLng: 'en',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
