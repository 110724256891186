import { LocalDB as db } from './LocalDB';
import { P_HHM_IEM_LIST, B01, B02, B03, B04 } from './LocalQsnInfo';
import _ from 'lodash';

/**
 * 로컬 입력내검 실행
 * @param {*} answerList
 * @returns
 */
export const inputCiExecute = async (user, data, okList) => {
  console.log('* LOCAL 내검 START *');
  let answerList = data.answer;
  let showIemSaveKeyList;
  // 가기초 특화
  if (process.env.REACT_APP_PRDCTN_ID === '220003') {
    // 화면 출력 통계 항목
    showIemSaveKeyList = data.showIemSaveKeyResult;
  }
  let ciContents = inputUtilityStr();

  let ciList = [];

  let ciRuleWhere = { exmnId: process.env.REACT_APP_EXMN_ID };

  // 농총 합계 변수들
  let hhmFarmngCnt = 0;
  for (const answer of answerList) {
    let iemKeys = _.keys(answer);

    for (const iemKey of iemKeys) {
      // console.log(iemKey + ':' + answer[`${iemKey}`]);
      if (iemKey != 'HHM_SN') {
        let value = answer[`${iemKey}`] != null ? `'${answer[`${iemKey}`]}'` : answer[`${iemKey}`];
        ciContents = ciContents + `var I${iemKey} = ${value};`;
      }
    }

    if (process.env.REACT_APP_PRDCTN_ID === '220001') {
      // 가구주와의 관계
      if (answer[P_HHM_IEM_LIST[7]] !== '1') {
        ciRuleWhere.ciDesignGroupCd = '30';
      }
      ciContents = ciContents + `var HHM_AGE = '${answer[P_HHM_IEM_LIST[6]]}';`; //나이

      await ciExecute(ciRuleWhere, ciContents, ciList, okList);
    } else if (process.env.REACT_APP_PRDCTN_ID === '220002') {
      // F460601 -농업종사형태, 농업종사기간
      // if (
      //   _.includes(['1', '3'], answer['2200020210DV0001254']) &&
      //   _.includes(['2', '3', '4', '5'], answer['2200020210DV0001206'])
      // ) {
      //   hhmFarmngCnt++;
      // }
    }
  }

  // 가기초 명부 상태 적재
  if (process.env.REACT_APP_PRDCTN_ID === '220003') {
    let nmstKeyList = [
      'dwllgKndCd',
      'exmnSeValue',
      'exmnTrgtYn',
      'dwllgKndEtc',
      'aptcpxBldgNm',
      'aptcpxBdhNm',
      'aditDelSttsCd',
      'bildngYr',
      'bildngMm',
      'uhSttsCd',
      'uhResnCd',
      'uhResnEtc',
      'copertnKtchCnt',
      'copertnToiletCnt',
      'afsseHoldSttsCd',
      'grupFcltyClCd',
      'copertnRstrntHoldSttsCd',
      'bildngEraSttsCd',
    ];

    _.each(nmstKeyList, (nmstKey) => {
      // console.log(`${nmstKey} : ${decodeURIComponent(user[nmstKey])}`);
      ciContents = ciContents + `var ${nmstKey} = '${decodeURIComponent(user[nmstKey])}';`;
    });
  } else if (process.env.REACT_APP_PRDCTN_ID === '220002') {
    // 농총 조사표별 내검 규칙 취득
    if (data.qsnId === B01 || data.qsnId === B02) {
      ciRuleWhere.dataTyCd = '70';
    } else if (data.qsnId === B03) {
      ciRuleWhere.dataTyCd = '80';
    } else if (data.qsnId === B04) {
      ciRuleWhere.dataTyCd = '90';
    }

    // if (hhmFarmngCnt > 0) {
    //   ciContents = ciContents + `var HHM_FARMNG_CNT=${hhmFarmngCnt};`;
    // }
  }

  if (process.env.REACT_APP_PRDCTN_ID !== '220001') {
    // let aptcpxBldgNm = user.aptcpxBldgNm;
    await ciExecute(ciRuleWhere, ciContents, ciList, okList, showIemSaveKeyList);
  }

  console.log('* LOCAL 내검 END *');
  return {
    result: 'SUCCESS',
    svyResultList: [
      {
        result: 'SUCCESS',
        ciResult: ciList.length > 0 ? 'ERROR' : 'SUCCESS',
        ciList: ciList,
      },
    ],
  };
};

async function ciExecute(ciRuleWhere, ciContents, ciList, okList, showIemSaveKeyList) {
  let ciRuleList = await db.ciRuleList.where(ciRuleWhere).toArray();

  for (const ciRule of ciRuleList) {
    // 테스트
    // if (ciRule.ciErrCdValue === 'F460601') {
    //   console.log('해당 내검');
    // }
    let isOkPs = false;

    for (const okCi of okList) {
      if (ciRule.ciErrCdValue === okCi.ciErrCdValue) {
        isOkPs = true;
        console.log('OK 체크 내검 : ' + okCi.ciErrCdValue);
      }
    }

    if (isOkPs) {
      continue;
    }

    let isExe = false;
    if (process.env.REACT_APP_PRDCTN_ID === '220003') {
      // 가기초 일경우

      for (const showIem of showIemSaveKeyList) {
        if (ciRule.statsIemId.indexOf(showIem.statsIemId) > -1) {
          isExe = true;
        }
      }
    } else {
      if (ciContents.indexOf(ciRule.statsIemId) > -1) {
        isExe = true;
      }
    }

    if (isExe) {
      try {
        console.log('## ciExecute ##');
        let executeCi = _.cloneDeep(ciContents);
        let ciScriptCn = ciRule.ciScriptCn.replace(/\n/g, '').replace(/\t/g, '');
        executeCi = executeCi.concat(`${ciScriptCn}`);
        // const executeFun = eval(executeCi);
        // let rst = executeFun();
        const rst = await new Function(executeCi)();

        if (rst) {
          let ciError = {
            ciRuleId: ciRule.ciRuleId,
            statsIemId: ciRule.statsIemId,
            ciErrMssageCn: ciRule.ciErrMssageCn,
            inptOkPsYn: ciRule.inptOkPsYn,
            ciErrCdValue: ciRule.ciErrCdValue,
          };
          console.log(`${rst} : ${ciRule.ciRuleNm}`);
          ciList.push(ciError);
        } else {
          // console.log(`${rst} : ${ciRule.ciRuleNm}`);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
}

/**
 * 로컬 입력내검 실행 (가구원 별)
 * @param {*} answerList
 * @returns
 */
export const inputCiExecuteHhm = async (user, data, okList) => {
  console.log('* LOCAL 내검 START *');
  let answerList = data.answer;
  let ciContents = inputUtilityStr();

  let ciList = [];

  // 취약점
  for (const answer of answerList) {
    let iemKeys = _.keys(answer);

    _.each(iemKeys, (iemKey) => {
      // console.log(iemKey + ':' + answer[`${iemKey}`]);
      if (iemKey != 'HHM_SN') {
        let value = answer[`${iemKey}`] != null ? `'${answer[`${iemKey}`]}'` : answer[`${iemKey}`];
        ciContents = ciContents + `var I${iemKey} = ${value};`;
      }
    });

    let ciRuleWhere = { exmnId: process.env.REACT_APP_EXMN_ID };
    if (answer[P_HHM_IEM_LIST[7]] !== '1') {
      ciRuleWhere.ciDesignGroupCd = '30';
    }

    let ciRuleList = await db.ciRuleList.where(ciRuleWhere).toArray();

    for (const ciRule of ciRuleList) {
      let isOkPs = false;
      _.each(okList, async (okCi) => {
        if (ciRule.ciErrCdValue === okCi.ciErrCdValue) {
          isOkPs = true;
          console.log('OK 체크 내검 : ' + okCi.ciErrCdValue);
        }
      });

      if (isOkPs) {
        return false;
      }

      if (ciContents.indexOf(ciRule.statsIemId) > -1) {
        await ciExecute(ciContents, ciRule, ciList);
      }
    }
  }

  // let aptcpxBldgNm = user.aptcpxBldgNm;

  console.log('* LOCAL 내검 END *');
  return {
    result: 'SUCCESS',
    svyResultList: [
      {
        result: 'SUCCESS',
        ciResult: ciList.length > 0 ? 'ERROR' : 'SUCCESS',
        ciList: ciList,
      },
    ],
  };
};

async function ciExecuteOld(ciContents, ciRule, ciList) {
  try {
    console.log('## ciExecute ##');
    let executeCi = _.cloneDeep(ciContents);
    let ciScriptCn = ciRule.ciScriptCn.replace(/\n/g, '').replace(/\t/g, '');
    executeCi = executeCi.concat(`${ciScriptCn}`);
    // const executeFun = eval(executeCi);
    // let rst = executeFun();
    const rst = new Function(executeCi)();

    if (rst) {
      let ciError = {
        ciRuleId: ciRule.ciRuleId,
        statsIemId: ciRule.statsIemId,
        ciErrMssageCn: ciRule.ciErrMssageCn,
        inptOkPsYn: ciRule.inptOkPsYn,
        ciErrCdValue: ciRule.ciErrCdValue,
      };
      console.log(`${rst} : ${ciRule.ciRuleNm}`);
      ciList.push(ciError);
    } else {
      // console.log(`${rst} : ${ciRule.ciRuleNm}`);
    }
  } catch (e) {
    console.log(e);
  }
}

function inputUtilityStr() {
  return `var IsVariable = function (target) { try { new Function(target)();
    return true;
    } catch (e) { return false;
    }};
    var IsEmpty = function (targetStr) { if (typeof targetStr === 'undefined') return true;
    if (targetStr === null) return true;
    if (targetStr.length < 1) return true;
    return false;
    };
    var IsNotEmpty = function (targetStr) { return !IsEmpty(targetStr);
    };
    var TrimToEmpty = function (targetStr) { if (IsEmpty(targetStr)) { return '';
    } return targetStr;
    };
    var TrimToZero = function (targetStr) { if (IsEmpty(targetStr)) return 0;
    if (!IsNaturalNumber(targetStr) && targetStr > 0) { return 0;
    } var value = Number(targetStr);
    if (isNaN(value)) { return 0;
    } return value;
    };
    var Nvl = function (targetStr, value) { return IsEmpty(targetStr) ? value : targetStr;
    };
    var Equals = function (targetStr, value) { if (TrimToEmpty(targetStr) == TrimToEmpty(value)) return true;
    else return false;
    };
    var EqualsWithOrs = function (targetStr, orValues) { for (var idx = 0;
    idx < orValues.length;
    idx++) { var value = orValues[idx];
    if (Equals(targetStr, value)) return true;
    } return false;
    };
    var NotEquals = function (targetStr, value) { return !Equals(targetStr, value);
    };
    var NotEqualsWithOrs = function (targetStr, orValues) { var isNotEquals = true;
    for (var idx = 0;
    idx < orValues.length;
    idx++) { var value = orValues[idx];
    if (Equals(targetStr, value)) isNotEquals = false;
    } return isNotEquals;
    };
    var StartWithOrs = function (targetStr, orValues) { if (IsEmpty(targetStr)) return false;
    for (var idx = 0;
    idx < orValues.length;
    idx++) { var value = orValues[idx];
    if (targetStr.startsWith(value)) return true;
    } return false;
    };
    var IsNaturalNumber = function (obj) { if (IsEmpty(obj)) return false;
    if (!/^[0-9]*$/.test(obj)) return false;
    return obj % 1 === 0;
    };
    var Lpad = function (targetStr, size) { var zero = '';
    targetStr = targetStr.toString();
    if (targetStr.length < size) { for (var idx = 0;
    idx < size - targetStr.length;
    idx++) { zero += '0';
    } } return zero + targetStr;
    };
    var GetDynamicObjectsByGroupCode = function (itemCodes) { var lastSeriesNumber = '';
    for (var idx = 1;
    idx < 3000;
    idx++) { var seriesNumber = idx;
    var has = IsVariable(itemCodes[0] + '_' + seriesNumber);
    if (!has) { break;
    } else { lastSeriesNumber = seriesNumber;
    seriesNumber++;
    } } if (IsEmpty(lastSeriesNumber)) { return null;
    } var seriesObjects = [];
    var lastSeriesLength = Number(lastSeriesNumber);
    for (var seriesIndex = 1;
    seriesIndex <= lastSeriesLength;
    seriesIndex++) { var dynamicObjects = [];
    for (var itemCodeIdx = 0;
    itemCodeIdx < itemCodes.length;
    itemCodeIdx++) { var itemCode = itemCodes[itemCodeIdx];
    var variableName = itemCode + '_' + seriesIndex;
    var obj = { code: itemCode, value: new Function(variableName)(), };
    dynamicObjects.push(obj);
    } seriesObjects.push(dynamicObjects);
    } return seriesObjects;
    };
    var EqualsZero = function (targetValue) { return TrimToZero(targetValue) == 0;
    };
    var NotEqualsZero = function (targetValue) { return !(TrimToZero(targetValue) == 0);
    };    
  `.replace(/\n/g, '');
}
