import { batch } from 'react-redux'
import _, { result } from "lodash";
import { updateProgress as main_updateProgress, } from "./Main";
import { addAffectedData as answer_addAffectedData,addAnswer as answer_addAnswer, } from "./Answer";
import { updateTree as paperTree_updateTree, } from "./PaperTree";
import { addSubItems as subItems_addSubItems } from './SubItems';

// 액션 타입 정의
const GET_ALL_SAVE_KEY = "paper/GET_ALL_SAVE_KEY";
const SET_INIT_DATA = "paper/SET_INIT_DATA";
const INIT = "paper/INIT";
const SET_VISIBLE = "paper/SET_VISIBLE";
const SET_TREE = "paper/SET_TREE";
const SET_INPUT_DATA = "paper/SET_INPUT_DATA";
const SET_INPUT_DATA_ARRAY = "paper/SET_INPUT_DATA_ARRAY";
const PUSH_INPUT_DATA = "paper/PUSH_INPUT_DATA";
const MERGE_INPUT_DATA = "paper/MERGE_INPUT_DATA";
const SET_ITEM_PROPERTIES = "paper/SET_ITEM_PROPERTIES"
const SET_TABLE_ADD_ROW = "paper/SET_TABLE_ADD_ROW"
const SET_TABLE_REMOVE_ROW = "paper/SET_TABLE_REMOVE_ROW"
const SET_TABLE_FOOTER = "paper/SET_TABLE_FOOTER"
const CHECK_ANSWER_COMPLETE = "paper/CHECK_ANSWER_COMPLETE"
const SET_TABLE_PAGE = "paper/SET_TABLE_PAGE"
const SET_REPEAT_TABLE = "paper/SET_REPEAT_TABLE"
const SET_INIT_SUBITEMS = "paper/SET_INIT_SUBITEMS"
const SET_ITEM_DISABLED = "paper/SET_ITEM_DISABLED"
const SET_TABLE_COLUMN_TEXT = 'paper/SET_TABLE_COLUMN_TEXT'
const SET_MATRIX_ITEMS_DATA = 'paper/SET_MATRIX_ITEMS_DATA'

// 액션 생섬함수 정의
export const _getTreeData=({tree,id})=>{
  let result;
  const _recursion = (tree) =>{
    tree.some(tree=>{
      if(tree.id==id){
        result = tree;
        return true;
      }else{
        if(_.isArray(tree.children)){
          return _recursion(tree.children);
        }
      }
    })
  }
  _recursion(tree);
  return result;
}
export const getTreeData = ({id,}) => (dispatch, getState) => {
  const { Paper } = getState();
  return _getTreeData({tree:Paper.tree,id})
};
export const getAllSaveKey = () => (dispatch, getState) => {
  const saveKeys=[]
  //1. 트리 펼침 ( 선택적 주관식은 트리에 없음)
  let flattenTree = []
  flattenNestedTree(getState().Paper.tree, flattenTree)
  
  const flattenTreeIdList = flattenTree.map(v=>v.id)
  let optionalShortAnswerList = _.filter(getState().Paper.itemMap, (v)=>{
    if( (v.common||{}).isExtraText == true && flattenTreeIdList.indexOf(v.parentId) != -1){
      return true
    }else{
      return false
    }
  })
  const questionIdList = [
    ...flattenTree.map(v=>v.id), //트리에서 뽑은 아이디 목록
    ...optionalShortAnswerList.map(v=>v.id),  //선택적 주관식중 트리에 부모가 있는 항목들의 id
  ]
  
  
  // console.log("questionIdList",questionIdList )
  //2. itemmap정보를 통해서 항목의 saveKey목록 추출
  for( const questionId of questionIdList){
    const item = getState().Paper.itemMap[questionId]
    if( item && item.type=="2" && item.saveKeys !=undefined){
      
      saveKeys.push(...item.saveKeys)
      
    }else{
      // console.log(item)
    }
  }
  return saveKeys
};
export const setInitData = ({data, }) => (dispatch, getState) => {
  const { Answer, Main } = getState();
  dispatch({ type: SET_INIT_DATA, answerModel: Answer, data, mainModel : Main});
};
export const init = () => ({ type: INIT });//초기화
export const setVisible = ({ id, visible, }) => ({ type: SET_VISIBLE, id, visible, });
export const setTree = ({ tree }) => ({ type: SET_TREE, tree, });
export const setInputData= ({id, path,value}) => (dispatch, getState) => {
  // console.log("[reducer] paper setInputData",id,path,value)
  const { Behavior } = getState();
  batch(()=>{
    dispatch({ type: SET_INPUT_DATA, id, path, value,});

    if( path == "common.disable"){
      dispatch(main_updateProgress())  
    }
    if( path == "common.visible"){
      dispatch(paperTree_updateTree())  
    }
  })
};
export const setInputDataArray= (inputDataArray) => (dispatch, getState) => {
  console.log("[reducer] paper setInputDataArray",inputDataArray)
  const { Behavior } = getState();
  dispatch({ type: SET_INPUT_DATA_ARRAY, inputDataArray,});


};


export const pushInputData = ({id, path,value}) => ({ type: PUSH_INPUT_DATA, id, path, value,}); // 미사용확인 20220530
export const mergeInputData = ({value}) => ({ type: MERGE_INPUT_DATA, value,});
export const setItemProperties = ({questionId, itemId, path,value}) => (dispatch, getState) => {
  // console.log("[reducer] setItemProperties ",questionId, itemId, path,value)
  const { Behavior } = getState();
  dispatch({ type: SET_ITEM_PROPERTIES, behaviorModel: Behavior, questionId, itemId, path, value, });
};




export const setTableType2AddRow = ({id,data}) => (dispatch, getState) => {
  const { Behavior, Paper } = getState();
  dispatch({ type: SET_TABLE_ADD_ROW, behaviorModel: Behavior, id});
  let lastRowIndexChildren = []
  const item = Paper.itemMap[id]
  let key = item.id
  if(!_.isEmpty(item.parentId) && Paper.itemMap[item.parentId].questionType == '1'){
    key = item.parentId
  }
  let tree = []
  flattenNestedTree(Paper.tree, tree);
  let group = Paper.itemMap[_.find(tree, {children : [{id : key}]}).id ]
  let itemAutoIndex;
  const getPathToNode = function(node, fn) {
    var path = [];
    return node.some(function iter(p) {
      return function (a, i) {
          if (fn(a)) {
              path = p.concat(i+1);
              return true;
          }
          return a&& a.children.some(iter(p.concat(i+1)));
      };
  }([])) && path;
  }
  const selectedNodePath = getPathToNode(_.filter(Paper.tree, treeData => treeData.isHiddenItem != true), function (node) {
    return node?.id === group.id;
  });
  _.isArray(selectedNodePath) && selectedNodePath?.map((node, index)=>{
    if(index <= 0){
      itemAutoIndex = node
    }else{
      itemAutoIndex = itemAutoIndex + `-${node}`
    }
  })
  if(_.isString(itemAutoIndex) && itemAutoIndex.split('-')?.length > 1){
    group = Paper.itemMap[Paper.tree[parseInt(itemAutoIndex.split('-')[0]) - 1].id]
  }
  _.filter(group.children, child=> {  //반복표의 마지막 행의 자식항목들 찾아서 배열에 넣고 하위항목설정하기위함.
    if(Paper.itemMap[child.id].subItems?.length > 0 && child.rowIndex == 0){
      lastRowIndexChildren.push(Paper.itemMap[child.id])
    }
  })
  if(lastRowIndexChildren.length > 0){
    dispatch(subItems_addSubItems({repeatTableId : id, needAddSubitemsList : lastRowIndexChildren}))
  }
  //TODO validation을 현 안고 있음 사방에 퍼져있는 validation 한곳에 모아야 함.
  if(!_.isEmpty(data)){
    Object.keys(data).forEach(key=>{
      const itemData = getState().Paper.itemMap[id];
      const parentData = _.find(getState().Paper.itemMap, {saveKeys : [key+"_"+itemData.common.info.lastRowIndex]})
      const saveKey = key+"_"+itemData.common.info.lastRowIndex;
      dispatch(answer_addAnswer({questionId:parentData.id, saveKey, value:data[key], commit:true,  }));
    });
  }
};
export const setTableType2RemoveRow = ({id, rowIndexs}) => (dispatch, getState) => {
  const { Behavior } = getState();
  dispatch({ type: SET_TABLE_REMOVE_ROW, behaviorModel: Behavior, id, rowIndexs});
};
export const setTableType2RemoveRowArray = (removeRowArray) => (dispatch, getState) => {
  const { Behavior } = getState();
  removeRowArray.map(row=>{
    dispatch({ type: SET_TABLE_REMOVE_ROW, behaviorModel: Behavior, id : row.id, rowIndexs : row.rowIndexs});
  })
};
export const setTableFooter= ({tableId,columnIndex,value}) => (dispatch, getState) => {
  const { Behavior } = getState();
  dispatch({ type: SET_TABLE_FOOTER, behaviorModel: Behavior, tableId,columnIndex,value});
};

export const checkAnswerComplete= ({questionId, saveKey, answerComplete}) => (dispatch, getState) => {
  const { Answer, Paper,  } = getState();

  batch(()=>{
    if(_.isArray(saveKey)){
      saveKey.map((key, index)=>{
        dispatch({ type: CHECK_ANSWER_COMPLETE, answerModel: Answer, paperModel: Paper, questionId: questionId[index], saveKey: key, answerComplete});
        //답변완료 여부가 조사표를 새로열때도 이어져서 보여야하므로 
        dispatch(answer_addAffectedData({questionId:questionId[index], path:"answerComplete", value:getState().Paper.itemMap[questionId[index]].answerComplete}) ) 
      })
    }else{
      dispatch({ type: CHECK_ANSWER_COMPLETE, answerModel: Answer, paperModel: Paper, questionId, saveKey, answerComplete});
      //   //답변완료 여부가 조사표를 새로열때도 이어져서 보여야하므로 
        dispatch(answer_addAffectedData({questionId:questionId, path:"answerComplete", value:getState().Paper.itemMap[questionId].answerComplete}) ) 
    }
  
    // 진도율 갱신 
    dispatch( main_updateProgress())
  })
};
export const setAnswerCompleteArray = (setAnswerArray) => (dispatch, getState) => {
  const { Answer, Paper,  } = getState();

  batch(()=>{
    setAnswerArray.map((answer, index)=>{
        dispatch({ type: CHECK_ANSWER_COMPLETE, answerModel: Answer, paperModel: Paper, questionId: answer.questionId, saveKey: answer.saveKey, answerComplete : answer.answerComplete});
        //답변완료 여부가 조사표를 새로열때도 이어져서 보여야하므로 
        dispatch(answer_addAffectedData({questionId:answer.questionId, path:"answerComplete", value:getState().Paper.itemMap[answer.questionId].answerComplete}) ) 
      })
  
    // 진도율 갱신 
    dispatch( main_updateProgress())
  })
};

export const setTablePage = ({id,page}) => (dispatch, getState) => {
  dispatch({ type: SET_TABLE_PAGE, id, page});
};
export const setRepeatTable = ({answerData}) => (dispatch, getState) => {
  dispatch({ type: SET_REPEAT_TABLE, answerData});
};
export const setInitSubitems = () => (dispatch, getState) => {
  dispatch({ type: SET_INIT_SUBITEMS, subItemsModel:getState().SubItems});
};
export const setItemDisabled = ({ids,isDisabled}) => (dispatch, getState) => {
  dispatch({ type: SET_ITEM_DISABLED, ids,isDisabled});
};
export const setTableColumnText = (setTableColumnTextArray) => (dispatch, getState) => {
  setTableColumnTextArray.map(async (textData, i)=>{
    await dispatch({type:SET_TABLE_COLUMN_TEXT, id : textData.id, rowIndex:textData.rowIndex, columnIndex : textData.columnIndex, value : textData.value })
    dispatch(answer_addAffectedData({questionId:textData.id, path:"common.items", value:getState().Paper.itemMap[textData.id].common.items}) ) 
  })
}
export const setMatrixItemsData = ({id, itemId, path, value}) => async (dispatch, getState) =>{
  await dispatch({type: SET_MATRIX_ITEMS_DATA, id, itemId, path, value})
  dispatch(answer_addAffectedData({questionId:id, path:"items", value:getState().Paper.itemMap[id].items}) ) 
}


export const getCurrentGroup = ({questionId})=> (dispatch, getState) => {
  // 넘겨받은 항목아이디를 몇레벨이 되건 자식으로 가지는 1레벨 그룹을 찾아 리턴.
  try {
    const {Paper, } = getState();
    let key = questionId
    let tree = []
    
    flattenNestedTree(Paper.tree, tree);
    let group = Paper.itemMap[_.find(tree, {children : [{id : key}]}).id ]
    let itemAutoIndex;
    const getPathToNode = function(node, fn) {
      var path = [];
      return node.some(function iter(p) {
        return function (a, i) {
            if (fn(a)) {
                path = p.concat(i+1);
                return true;
            }
            return a&& a.children.some(iter(p.concat(i+1)));
        };
    }([])) && path;
    }
    const selectedNodePath = getPathToNode(_.filter(Paper.tree, treeData => treeData.isHiddenItem != true), function (node) {
      return node?.id === group.id;
    });
    _.isArray(selectedNodePath) && selectedNodePath?.map((node, index)=>{
      if(index <= 0){
        itemAutoIndex = node
      }else{
        itemAutoIndex = itemAutoIndex + `-${node}`
      }
    })
    if(_.isString(itemAutoIndex) && itemAutoIndex.split('-')?.length > 1){ 
      group = Paper.itemMap[Paper.tree[parseInt(itemAutoIndex.split('-')[0]) - 1].id]
    }
    return group
  } catch (error) {
    return ''
  }
}

export const defaultTableSize = {
  width:150,
  minWidth:50,
  height:30,
  minHeight:30
};
export const columnHeader = [
  {
    key:"isColumnHeaderRownum",
    name:"표측(rownum)",
    isChangeSize:false,
    defaultWidth:40,
    tableType:["2"]
  },
  {
    key:"isColumnHeaderCheckbox",
    name:"표측(checkbox)",
    isChangeSize:false,
    defaultWidth:30,
    tableType:["2"]
  },
  {
    key:"isColumnHeader",
    name:"표측",
    isChangeSize:true,
    defaultWidth:defaultTableSize.width,
    tableType:["1"]
  }
];
export const tableCopyKeys = ["extraTextLink","buttonLink","itemLink","saveKeys", "subItems"];
const template={
  "question":{
    id:"",
    type :2, //type 1:group, 2: question,  3: table, 4:video
    questionType:1,  //1:주관식, 2: 객관식  3: 슬라이더, 4:랭킹, 5:matrix,
    answerComplete:{}, 
    constraint:{
      // maxInputCount:2,
      // requireInputCount:2
    },
    common:{
      title:"",
      index:"",
      isRequired:false,
      
      comments:{
        bottom:"",
        
      },

    },
    style:{
      showTitle:"1",
      showBorder:"1",
      borderColor:"#999",
      answerAlignment:"vertical",
      showRankLabel:"0", //TODO: questionType2일때만 사용됨 (템플릿 분리 필요 )
      horizontalLevel:1, //TODO: questionType2일때만 사용됨 (템플릿 분리 필요 )
      answerAlignment2:"horizontal",
      titlePosition:"top",
    },
    itemLink:[],
    imageLink:[],
    buttonLink:[],
    callback:{

    },
  },
  "group": {
    id:"g0",
    type:1,       //type 1:group, 2: question,  3: table
    common:{
      title:"그룹명",
     
      comments:{
        bottom:"",
        
      },
    },
    style:{        
      showTitle:"1",
      showBorder:"1",
      borderColor:"#999",
    },
  },
  "table": {
    date:null,
    id:"t0",
    type:3,
    common:{
      title:'표',
      info : {
        row:0,
        column:0,
        isRowHeader:false,//표두
        isColumnHeader:false,//표측
        table:{
          isPercent:false,//테이블이 퍼센테이지로 넓이 지정하는지 여부
          width:null,
        }
      },
      items:{
        width:{
          header:[],//표측
          body:[]
        },
        height:{
          header:[],//표두
          body:[]
        },
        header:{
          row:[],//표두
          column:[]//표측
        },
        //각 셀 정보
        /**
          {
            tag:{
              element:"th|td",--element tag 명
              scope:"row|column"--row 또는 column
              colspan:1,
              rowspan:1,
              readonly:!boolean!,--읽기 전용 여부
              visible:!boolean!,--표시 여부
            },
            value:!string!,--값
            type:"1|2"--1:통계항목,2:텍스트(input tag 아님)
            isEdit:!boolean!, -- 편집중인지 여부
          }
        */
        body:[]
      },
      //셀 선택 리스트
      selection:{//NOTE : 추후 멀티 셀렉션 고려해서 array로 구성함
        header:{
          row: [{
            start:{row:null,column:null},
            end:{row:null,column:null},
          }],
          column: [{
            start:{row:null,column:null},
            end:{row:null,column:null},
          }]
        },
        body:[{
          start:{row:null,column:null},
          end:{row:null,column:null},
        }]
      },
      //셀 병합 리스트
      /*
      [{
        start:{
          row:0,
          column:0
        },
        end:{
          row:3,
          column:3
        }
      }]
      */
      merge:{
        header:{
          row:[],//표두
          column:[]//표측
        },
        body:[]
      },
    },
    style:{
      showTitle:"1",
      showBorder:"1",
      borderColor:"#999",
      titlePosition:"top",
    },
    callback:{

    },
  },
  "video": {
    id:"v0",
    type:4,
    common:{
      title:'동영상',
      src:'',
      linkType:'',
    },
    style:{
      showTitle:"1",
      showBorder:"1",
      borderColor:"#999",
      titlePosition:"top",
    },
    callback:{

    },
  },
}
const flattenNestedTree = (array, result) => {
  array.forEach(function(el){
    result.push(el);
    if(el.children) {
      
      flattenNestedTree(el.children, result);
    } else {
      
    }
  });
}
export const getDefaultTableColumnInfo = () =>{
  return {
    tag:{
      element:"td",
      colspan:1,
      rowspan:1,
      readonly:true,
      visible:true,
    },
    isEdit:false,
    value:"",
    type:"2"
  };
}
const getPathToNode = function(node, fn) {
  var path = [];
  return node.some(function iter(p) {
      return function (a, i) {
          if (fn(a)) {
              path = p.concat(i);
              return true;
          }
          return a&& a.children.some(iter(p.concat(i)));
      };
  }([])) && path;
}
const duplicateTableRow =({itemMap,id,tree,currentIndex}) => {
  const item = itemMap[id];
  const _setObject = ({copyItemMap,key}) =>{
    if(_.isArray(copyItemMap[key])){
      if(key=="saveKeys"){
        copyItemMap.saveKeys = copyItemMap.saveKeys.map(item=>item+"_"+currentIndex);
      }else if(key == "subItems"){
        if(copyItemMap[key]?.length > 0){
          let tempList = []
          copyItemMap[key].forEach((data,index)=>{
            let copyData = data
            copyData.moveNextForSubItem = data.moveNextForSubItem != (null) ? data.moveNextForSubItem + "_"+currentIndex : (null)
            copyData.questionList = data.questionList.map(id=>id+"_"+currentIndex)
            copyData.selectedTreeData = data.selectedTreeData.map(tree=>tree+"_"+currentIndex)
            tempList.push(copyData)
          })
          copyItemMap[key] = tempList
        }
      }else{
        copyItemMap[key].forEach((data,index)=>{
          let copy = _.cloneDeep(itemMap[data]);
          const k = data+"_"+currentIndex;
          copy.id = k;
          copy.parentId = copy.parentId+"_"+currentIndex;
          if(copy.extraTextId!==undefined){
            copy.extraTextId = copy.extraTextId+"_"+currentIndex;
          }
          tableCopyKeys.forEach(key=>{
            _setObject({copyItemMap:copy,key});
          });
          itemMap[k] = copy;
          copyItemMap[key][index] = k;
        })
      }
    }
  }
  [...new Array(item.common.info.column)].forEach((c,columnIndex)=>{
    let childrenArray = _.cloneDeep(_.filter(tree.children,{rowIndex:0, columnIndex}))
    
    for( const children of childrenArray){
      if(children  && children.target != 'footer'){
        let copyItemMap = _.cloneDeep(itemMap[children.id]);
        children.rowIndex = currentIndex;
        children.id = children.id+"_"+currentIndex;
        copyItemMap.id = children.id;
        itemMap[children.id] = copyItemMap;
        tableCopyKeys.forEach(key=>{
          _setObject({copyItemMap,key});
        });
        tree.children.push(children);
      }
    }
    
  });
};
const _setTablePage = ({data}) => {
  _.filter(data.itemMap,{type:3,common:{tableType:"2"}}).forEach(itemMap=>{
    itemMap.common.info.page.total = itemMap.common.info.rowIndexs.length;
    itemMap.common.info.page.last = Math.ceil(itemMap.common.info.page.total/itemMap.common.info.page.size);
  });
}
// **** 초기상태 정의
const initialState = { //항목 배치 순서 담당 
  init:false,
  nextId:1,

  //설문지의 항목 배치순서정보 
  tree:[],        //배열 위치가 id를 나타내지 않도록 그냥 push함 
  //설문지에 모든 항목을 1레벨로 나열해 놓은데이터
  itemMap:{},   //키가 문자열이기때문에 오브젝트로 선언함 

  
};

// **** 리듀서 작성
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_INIT_DATA: {
      const {answerModel, mainModel} = action;
      let data = action.data;
      //반복표 행추가시 복사할 원본데이터 생성.
      _.filter(data.itemMap, item=> item.type == 3 && item.common?.tableType == '2').map(repeatTable=>{
        let originItems = _.cloneDeep(repeatTable.common.items);
        repeatTable.common.info = {
          ...repeatTable.common.info,
          ['items'] : originItems
        }
      })

      //트리데이터에 전역그룹속성추가.
      data.tree.map(treeData=>{
        if(treeData?.isHiddenItem != true){
          const mainGroup = _.find(mainModel.groupTree, {children : [{key :treeData.id}]}) || {}
          treeData.mainGroupId = mainGroup?.key || ''
        }
      })
      if(mainModel.inputLayoutCode == '02'){

        const attrList = ['disable', 'visible', 'isAutoComplete', 'codeName', 'useQuestionReject'] // 가구원별로 복제되어야할 개별속성.
        Object.keys(data.itemMap).forEach(key=>{
          const itemData = data.itemMap[key]
          attrList.forEach(attr=>{
            let tempAttr = {}
            mainModel.memberList.map(member=>{
              tempAttr[member.HHM_SN] = itemData.common[attr]
            })
            itemData.common[attr] = tempAttr
          })
          
        })

      } 
        
      _setTablePage({data});
      return data;
    }
    case INIT: {
      return {
        ...state,
        init:true, 
        tree:[], 
      };
    }
    case SET_TREE:{
      const tree = []

      return {
        ...state,
        // tree:tree, 
      };
    }
    case SET_INPUT_DATA:{ //TODO :탭별로 코드 분리해야함 

      const itemMap = { ...state.itemMap }
      const _process = (id) =>{
        const pathSplit = action.path.split(".")
        if(pathSplit.length == 2){
          if(itemMap[id]){//없는 항목에 대해서 요청할때에 대한 오류 처리 
            itemMap[id]={
              ...itemMap[id], 
              [pathSplit[0]]:{
                ...itemMap[id][pathSplit[0]],
                [pathSplit[1]]:action.value,
              }
              
            }
          }
        }else if(pathSplit.length == 3){
          if(itemMap[id]){//없는 항목에 대해서 요청할때에 대한 오류 처리 
            itemMap[id]={
              ...itemMap[id], 
              [pathSplit[0]]:{
                ...itemMap[id][pathSplit[0]],
                [pathSplit[1]]:{
                  ...itemMap[id][pathSplit[0]][pathSplit[1]],
                  [pathSplit[2]]:action.value,
                }
              }
              
            }
          }
        }
      }
      if(_.isString(action.id)){
        _process(action.id);
      }else if(_.isArray(action.id)){
        action.id.forEach(id=>{
          _process(id);
        })
      }

      return {
        ...state,
        itemMap,
      }
    }
    case SET_INPUT_DATA_ARRAY:{ //TODO :탭별로 코드 분리해야함 

      const itemMap = { ...state.itemMap }
      const _process = (id, path, value,) =>{
        const pathSplit = path.split(".")
        if(pathSplit.length == 2){
          if(itemMap[id]){//없는 항목에 대해서 요청할때에 대한 오류 처리 
            itemMap[id]={
              ...itemMap[id], 
              [pathSplit[0]]:{
                ...itemMap[id][pathSplit[0]],
                [pathSplit[1]]:value,
              }
              
            }
          }
        }else if(pathSplit.length == 3){
          if(itemMap[id]){//없는 항목에 대해서 요청할때에 대한 오류 처리 
            itemMap[id]={
              ...itemMap[id], 
              [pathSplit[0]]:{
                ...itemMap[id][pathSplit[0]],
                [pathSplit[1]]:{
                  ...itemMap[id][pathSplit[0]][pathSplit[1]],
                  [pathSplit[2]]:value,
                }
              }
              
            }
          }
        }
      }
      if(_.isArray(action.inputDataArray)){
        action.inputDataArray.forEach(inputData=>{
          _process(inputData.id, inputData.path, inputData.value,);
        })
      }
      return {
        ...state,
        itemMap,
      }
    }
    case PUSH_INPUT_DATA:{ 

      const itemMap = { ...state.itemMap }

      const pathSplit = action.path.split(".")
      if(pathSplit.length == 2){
        itemMap[action.id]={
          ...itemMap[action.id], 
          [pathSplit[0]]:{
            ...itemMap[action.id][pathSplit[0]],
            [pathSplit[1]]:[
              ...itemMap[action.id][pathSplit[0]][pathSplit[1]],
              action.value
            ],
          }
          
        }
      }else if(pathSplit.length == 3){
        itemMap[action.id]={
          ...itemMap[action.id], 
          [pathSplit[0]]:{
            ...itemMap[action.id][pathSplit[0]],
            [pathSplit[1]]:{
              ...itemMap[action.id][pathSplit[0]][pathSplit[1]],
              [pathSplit[2]]:[
                ...itemMap[action.id][pathSplit[0]][pathSplit[1]][pathSplit[2]],
                action.value
              ],
            }
          }
          
        }
      }
     

      return {
        ...state,
        itemMap,
      }
    }
    case MERGE_INPUT_DATA:{ 
      console.log("[reducer] paper MERGE_INPUT_DATA", action.value)
      const itemMap = _.cloneDeep(state.itemMap) //
      return {
        ...state,
        itemMap : _.merge(itemMap, action.value),
      }
    }
    case SET_ITEM_PROPERTIES:{  
      const itemMap = { ...state.itemMap }

      const pathSplit = action.path.split(".")
      const itemId = _.isArray(action.itemId)?action.itemId:[action.itemId];
      itemId.forEach(id=>{
        if(itemMap[id]){
          let path;
          pathSplit.forEach((pathName, index, array)=>{
            if(index==0){
              path = itemMap[id][pathName];
            }else if(index !== array.length - 1){
              path = path[pathName];
            }
            if (index === array.length - 1){ 
              path[pathName] = action.value;
            }
          });
        }
      });
      return {
        ...state,
        itemMap,
      }
    }
    case SET_VISIBLE:{
      
      const tree = [...state.tree]
      const item = _.find(tree, {id:action.id})
      if(item){
        item.visible = action.visible
      }
      return {
        ...state,
        tree:tree,
      }
    }
    

    
    case SET_TABLE_ADD_ROW:{
      const {id} = action;
      let itemMap = _.cloneDeep(state.itemMap);
      let item = itemMap[id];
      if(item){
        const currentIndex = item.common.info.lastRowIndex+1;
        item.common.info.lastRowIndex = item.common.info.lastRowIndex+1;
        item.common.info.rowIndexs.push(currentIndex);
        let flatTree = []
        let copyTree = _.cloneDeep(state.tree);
        flattenNestedTree(copyTree, flatTree);
        let tree = _getTreeData({tree:flatTree,id});
        if(tree.children){
          duplicateTableRow({itemMap,id,tree,currentIndex});
        }
        item.common.info.page.total = item.common.info.rowIndexs.length;
        item.common.info.page.last = Math.ceil(item.common.info.page.total/item.common.info.page.size);
        const copiedBody = _.cloneDeep(item.common.info.items.body[0]);
        const copiedHeader= _.cloneDeep(item.common.info.items.header.column[0])
        item.common.items.body.push(copiedBody);
        item.common.items.header.column.push(copiedHeader)

        let result = {
          ...state,
          itemMap,
          tree:copyTree,
        };
        _setTablePage({data:result});
        return result;
      }else{
        return state;
      }
    }
    case SET_TABLE_REMOVE_ROW:{
      const {id,rowIndexs} = action;
      let itemMap = _.cloneDeep(state.itemMap);
      let item = itemMap[id];
      if(item){
        let flatTree = []
        let copyTree = _.cloneDeep(state.tree);
        flattenNestedTree(copyTree, flatTree);
        let tree = _.find(flatTree,{id});
        if(tree.children){
          [...new Array(item.common.info.column)].forEach((c,columnIndex)=>{
            let children = _.cloneDeep(_.filter(tree.children,{rowIndex:0, columnIndex}));
            if(children){
              children.map(children=>{
                rowIndexs.forEach(row=>{
                  const childrenId = children.id+"_"+row;
                  if(row!==""){
                    tableCopyKeys.forEach(key=>{
                      if(_.isArray(itemMap[childrenId][key])){
                        itemMap[childrenId][key].forEach((data,index)=>{
                          delete itemMap[data];
                        })
                      }
                    });
                    delete itemMap[childrenId];
                    tree.children.splice(_.findIndex(tree.children,{id:childrenId}),1);
                  }
                });
              })
            }
          });
        }
        rowIndexs.forEach(rowIndex=>{
          let index = _.indexOf(item.common.info.rowIndexs, rowIndex);
          item.common.items.body = _.filter(item.common.items.body, body=> _.indexOf(item.common.items.body, body) != index);
          item.common.items.header.column = _.filter(item.common.items.header.column, column=> _.indexOf(item.common.items.header.column, column) != index);
          // delete item.common.items.body[index]
          // delete item.common.items.header.column[index]
        })

        item.common.info.rowIndexs = _.difference(item.common.info.rowIndexs,rowIndexs);
        if(item.common.info.rowIndexs.length<1 && item.common.firstRowVisible != false){
          item.common.info.lastRowIndex++;
          duplicateTableRow({itemMap,id,tree,currentIndex:item.common.info.lastRowIndex});
          item.common.info.rowIndexs = [item.common.info.lastRowIndex]
          item.common.items.body.push(_.cloneDeep(item.common.info.items.body[0]))
          item.common.items.header.column.push(_.cloneDeep(item.common.info.items.header.column[0]))
        }
        item.common.info.page.total = item.common.info.rowIndexs.length;
        item.common.info.page.last = Math.ceil(item.common.info.page.total/item.common.info.page.size);
        if(item.common.info.page.last<item.common.info.page.current){
          item.common.info.page.current = item.common.info.page.last;
        }
        if(item.common.info.rowIndexs.length<1 && item.common.firstRowVisible == false){
          item.common.info.lastRowIndex = 0
          item.common.info.page.current = 1
        }
        return {
          ...state,
          itemMap,
          tree:copyTree,
        }
      }else{
        return state;
      }
    }
    case SET_TABLE_FOOTER:{
      const {tableId,columnIndex,value} = action;
      let itemMap = _.cloneDeep(state.itemMap);
      let item = itemMap[tableId];
      if(item&&item.common.info.isFooter===true){
        item.common.items.footer[0][columnIndex].value = value;
      }
      return {
        ...state,
        itemMap
      }
    }
    case CHECK_ANSWER_COMPLETE:{ //각 문항별로(항목별로 아님) 답변 완료했는지 검사 후 표시
      const itemMap = {...state.itemMap}
      let isAnswerComplete = true
      
      //1. 타입별로 답변이 완료되었는지 여부를 검사
      if(action.answerModel.answerData[action.saveKey]==undefined || _.isEmpty(action.answerModel.answerData[action.saveKey])){ //답변안했거나 답변 지우면 
        isAnswerComplete = false
      }
      
      //최소응답갯수 채워야지 답변완료처리 
      let answerdCount = 0
      const {isRequired, minimumAnswer,} = action.paperModel.itemMap[action.questionId].common
      const {saveKeys, } = action.paperModel.itemMap[action.questionId]
      if( isRequired==true &&  minimumAnswer>0){
        saveKeys.map(v=>{
          if(!_.isEmpty(action.answerModel.answerData[v])){
            answerdCount++
          }
          
        })
        if(minimumAnswer > answerdCount){
          isAnswerComplete = false
        }
      }
      let keyIndex; //선택적주관식이면서 userSelectedItemId가 undefined일때 questionId의 saveKeys에서 올바른 index를 구하기위해.
      //선택적주관식의 필수여부에따라 진도율 계산이 달라짐
      if(action.paperModel.itemMap[action.questionId].questionType == '2'){ //문제가 객관식일때 객관식문항중에 common.hasExtraText가 true이면서 common.isRequired가 true인 문항찾음
        let userSelectedItemId = action.paperModel.itemMap[action.questionId].itemLink[action.answerModel.answerData[action.saveKey]-1]
        if(action.paperModel.itemMap[action.saveKey] && action.paperModel.itemMap[action.saveKey].extraTextId || action.paperModel.itemMap[userSelectedItemId]?.common?.hasExtraText == true){   //선택적 주관식일경우
          if(userSelectedItemId == undefined){ //extraId가 undefined일때 saveKey를 가지고 다시구하기. 부모항목의 saveKeys의 개수에따라 
            let saveKeyList = action.paperModel.itemMap[action.questionId].saveKeys
            if(saveKeyList.length <= '1'){
              keyIndex = 0;
              userSelectedItemId = action.paperModel.itemMap[action.questionId].itemLink[action.answerModel.answerData[action.paperModel.itemMap[action.questionId].saveKeys[keyIndex]]-1]
            }else{
              for (let i = 0; i < saveKeyList.length; i++) {
                const key = saveKeyList[i]
                if (action.answerModel.answerData[key] == action.paperModel.itemMap[action.saveKey].value) {
                  keyIndex = i
                  break
                }else if (_.isEmpty(action.answerModel.answerData[key])) {
                  keyIndex = i
                  break
                }
              }
              userSelectedItemId = action.paperModel.itemMap[action.questionId].itemLink[action.answerModel.answerData[action.paperModel.itemMap[action.questionId].saveKeys[keyIndex]]-1]
            }
          }
          if(action.paperModel.itemMap[userSelectedItemId]?.common?.isRequired == true){
            if( _.isEmpty(action.answerModel.answerData[action.paperModel.itemMap[userSelectedItemId].extraTextId])){
              itemMap[action.questionId].answerComplete ={    // 선택적주관식이 필수 일때만, 항목도 answerComplete가 false 된것으로 처리 
                ...itemMap[action.questionId].answerComplete,
                [userSelectedItemId]:false,
              }
              isAnswerComplete = false
            }else{
              itemMap[action.questionId].answerComplete ={    // 선택적주관식이 필수 일때만, 항목도 answerComplete가 true로 된것으로 처리 
                ...itemMap[action.questionId].answerComplete,
                [userSelectedItemId]:true,
              }
              isAnswerComplete = true
            }
            // if(action.paperModel.itemMap[action.questionId].answerComplete[userSelectedItemId] != true){ 
            // }else{
            // }
          }else{    // 선택적주관식이 선택일경우 , 항목도 answerComplete가 true로 된것으로 처리
            isAnswerComplete = true
          }
        }
      }
      

      //2. 플래그 업데이트 처리 
      let answerCompleteTargetId = action.saveKey
      // 선택적 주관식일 경우, targetID가 선택적 주관식의 아이디로 바뀌어야함
      if(action.paperModel.itemMap[action.saveKey] && action.paperModel.itemMap[action.saveKey].extraTextId){
        answerCompleteTargetId = action.paperModel.itemMap[action.questionId].saveKeys[keyIndex]
      }

      if(action.answerComplete != undefined){
        isAnswerComplete = action.answerComplete 
      }
      itemMap[action.questionId].answerComplete ={
        ...itemMap[action.questionId].answerComplete,
        [answerCompleteTargetId]: isAnswerComplete,
      }

      return {
        ...state,
        itemMap
      }
    }
    case SET_TABLE_PAGE:{
      const {id} = action;
      const page = _.parseInt(action.page);
      if(_.isNumber(page)){
        let itemMap = _.cloneDeep(state.itemMap);
        let item = itemMap[id];
        if(item.common.info.page.last>=page&&page>0){
          item.common.info.page.current = page;
          return {
            ...state,
            itemMap
          }
        }
      }
      return state;
    }
    case SET_REPEAT_TABLE:{
      console.log("[reducer] paper SET_REPEAT_TABLE",)
      const itemMap = { ...state.itemMap };
      const {answerData} = action;
      _.filter(itemMap,{type:3,common:{tableType:"2"}}).forEach(item=>{//반복표 찾기 

        //표 안에 저장키 찾기 
        let keys = [];
        let flatTree = [];
        flattenNestedTree(state.tree, flatTree)
        const findTree = _.find(flatTree,{id:item.id});
        findTree.children.forEach(item=>{
          keys = _.concat(keys,itemMap[item.id].saveKeys);
        });
        
        //반복표 마지막 로우 인덱스 찾기 
        let rowIndexs = [];
        let lastRowIndex = 0;
        keys.forEach(id=>{
          const data = _.pickBy(answerData, (value, key)=>_.startsWith(key, `${id}_`));
          Object.keys(data).forEach(data=>{
            const rowIndex = parseInt(data.replace(`${id}_`,""));
            if(rowIndexs.indexOf(rowIndex)==-1){
              rowIndexs.push(rowIndex);
            }
            lastRowIndex = Math.max(lastRowIndex,rowIndex);
          });
        });
        if(rowIndexs.length==0 && item.common.firstRowVisible != false){
          rowIndexs = [1];
          lastRowIndex = 1;
        }

        
        //로우 증가처리 
        rowIndexs.length > 0 && rowIndexs?.forEach((currentIndex, index)=>{
          duplicateTableRow({itemMap,id:item.id,tree:findTree,currentIndex});
          if(index > 0){
            const copiedBody = _.cloneDeep(item.common.info.items.body[0]);
            const copiedHeader= _.cloneDeep(item.common.info.items.header.column[0])
            item.common.items.body.push(copiedBody);
            item.common.items.header.column.push(copiedHeader)
          }
        });
        item.common.info.lastRowIndex = lastRowIndex;
        item.common.info.rowIndexs = rowIndexs;
        item.common.info.page.total = item.common.info.rowIndexs.length;
        item.common.info.page.last = Math.ceil(item.common.info.page.total/item.common.info.page.size);
        
      });
      return {
        ...state,
        itemMap,
      }
    }
    case SET_INIT_SUBITEMS:{
      const {subItemsModel} = action;
      const itemMap = { ...state.itemMap };
      Object.keys(subItemsModel.disabledItemMap).forEach(id=>{
        itemMap[id].common.disable = true;
      });
      return {
        ...state,
        itemMap
      };
    }
    case SET_ITEM_DISABLED:{
      const itemMap = { ...state.itemMap };
      const {ids,isDisabled} = action;
      ids.forEach(id=>{
        itemMap[id].common.disable = isDisabled;
      });
      return {
        ...state,
        itemMap
      };
    }
    case SET_TABLE_COLUMN_TEXT : {
      let setTableColumnTextArray = action.setTableColumnTextArray
      // const itemMap = _.cloneDeep(state.itemMap);
      const itemMap = { ...state.itemMap };
        const {id, rowIndex, columnIndex, value,} = action;
        const item = itemMap[id];
        // let valueTarget=item.common.items.body;
        if(!_.isEmpty(item.common.items.body?.[rowIndex]?.[columnIndex])){
          item.common.items.body[rowIndex][columnIndex].value = value + '';
        }
      return {
        ...state,
        itemMap,
      }
    }
    case SET_MATRIX_ITEMS_DATA : {
      const itemMap = _.cloneDeep(state.itemMap)
      const {id, itemId, path, value} = action;
      const pathSplit = path.split(".")
      if (pathSplit.length === 1) {
        if (itemMap[id]) {
          const itemIndex = itemMap[id].items.findIndex(item => item.id === itemId);
          if (itemIndex !== -1) {
            itemMap[id].items[itemIndex] = {
              ...itemMap[id].items[itemIndex],
              [pathSplit[0]]: value
            };
          }
        }
      } else if (pathSplit.length === 2) {
        if (itemMap[id]) {
          const itemIndex = itemMap[id].items.findIndex(item => item.id === itemId);
          if (itemIndex !== -1) {
            itemMap[id].items[itemIndex] = {
              ...itemMap[id].items[itemIndex],
              [pathSplit[0]]: {
                ...itemMap[id].items[itemIndex][pathSplit[0]],
                [pathSplit[1]]: value
              }
            };
          }
        }
      }
      if(path == 'visible'){
        let visibleFalseLength = _.filter(itemMap[id].items, item=>item.visible == false).length
        if(visibleFalseLength == itemMap[id].items.length){
          itemMap[id].common.visible = false
        }else{
          itemMap[id].common.visible = true
        }
      }
      if(path == 'disable'){
        let disableTrueLength = _.filter(itemMap[id].items, item=>item.visible == true).length
        if(disableTrueLength == itemMap[id].items.length){
          itemMap[id].common.disable = true
        }else{
          itemMap[id].common.disable = false
        }
      }
      return { 
        ...state,
        itemMap
      }
    }
    default: {
      return state;
    }
  }
}