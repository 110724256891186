import { INPUT_ACENS_NMST_SE, ADD_ACENS_NMST_SE, GET_ACENS_NMST_SE } from '../actions/types';

const initialState = {
  values: { acensNmstSe: {} },
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case INPUT_ACENS_NMST_SE:
      return {
        ...state,
        values: { ...state.values, acensNmstSe: payload.acensNmstSe },
      };
    case ADD_ACENS_NMST_SE:
      return {
        ...state,
        values: { ...state.values, acensNmstSe: payload.acensNmstSe },
      };
    case GET_ACENS_NMST_SE:
      return {
        ...state,
        values: { acensNmstSe: payload.acensNmstSe },
      };
    default:
      return state;
  }
}
