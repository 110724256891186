import { SET_LANGUAGE } from '../actions/types';

const language = sessionStorage.getItem('language');
const initialState = language ? { language : language} : { language: 'ko' };

/**
 * 언어 설정 상태관리
 * @param {*} state
 * @param {*} action
 * @returns
 */
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: payload.language,
      };
    default:
      return state;
  }
}
