import axios from 'axios';
import authHeader from './AuthHeader';
import fakeApi from './FakeApi';
import { sessionOut } from '../../common/utils/CommonFunction';

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  cancelToken: source.token,
});
let fake;

if (
  (sessionStorage.getItem('isOnline') != null && sessionStorage.getItem('isOnline') === 'N') ||
  !window.navigator.onLine
) {
  console.log('fake1 off');
  fake = new fakeApi(instance);
} else {
  if (fake) {
    console.log('fake1 on');
    fake.restore();
  }
}

/**
 1. 요청 인터셉터
 2개의 콜백 함수를 받습니다.
 */
instance.interceptors.request.use(
  (config) => {
    // HTTP Authorization 요청 헤더에 jwt-token을 넣음
    // 서버측 미들웨어에서 이를 확인하고 검증한 후 해당 API에 요청함.
    if (
      (sessionStorage.getItem('isOnline') != null && sessionStorage.getItem('isOnline') === 'N') ||
      !window.navigator.onLine
    ) {
      fake = new fakeApi(instance);
    } else {
      if (fake) {
        fake.restore();
      }
    }
    config.headers = authHeader();
    return config;
  },
  (error) => {
    // 요청 에러 직전 호출됩니다.
    return Promise.reject(error);
  },
);

/**
 2. 응답 인터셉터
 2개의 콜백 함수를 받습니다.
 */
instance.interceptors.response.use(
  (response) => {
    /*
      http status가 200인 경우
      응답 성공 직전 호출됩니다.
      .then() 으로 이어집니다.
    */
    return response;
  },

  (error) => {
    /*
     http status가 200이 아닌 경우
     응답 에러 직전 호출됩니다.
     .catch() 으로 이어집니다.
    */
    if (error.response === undefined) {
    } else if (error.response.status == 401) {
      // if (error.response.status >= 400 || error.response.status <= 500) {
      // alert('로그인 하여 주시기 바랍니다.');
      sessionOut();
    } else if (error.response.status == 504) {
      // TODO 서버에 응답이 없거나 오프라인 일경우 오프라인 모드
      // 401에러 로그인 페이지로 이동
      alert('서버에 응답이 없습니다.');
    }
    return Promise.reject(error);
  },
);

export default instance;
