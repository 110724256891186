import _ from "lodash";

// 액션 타입 정의
const SET_INIT_DATA = "paperTree/SET_INIT_DATA";
const INIT = "paperTree/INIT";
const UPDATE_TREE = "paperTree/UPDATE_TREE";

// 액션 생섬함수 정의
export const setInitData = ({data, }) => ({ type: SET_INIT_DATA, data });
export const init = () => (dispatch, getState) => {
  const { Paper, Main, } = getState();
  dispatch({ type: INIT, paperModel: Paper, mainModel:Main, });
};

export const updateTree = () => (dispatch, getState) => {
  const { Paper, Main, } = getState();
  dispatch({ type: UPDATE_TREE, paperModel: Paper, mainModel:Main, });
};

// **** 초기상태 정의
const initialState = { //항목 배치 순서 담당 
  init:false,
  tree:[],
  dataSourceTree:[],

  
};

// **** 리듀서 작성
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_INIT_DATA: {
      return action.data;
    }
    case INIT: {
      return {
        ...state,
        init:true,
        tree:[{
          key: '0',
          title: action.mainModel.title,
          children: []
        }],
      };
    }
    case UPDATE_TREE:{
      const tree = [{
        key: '0',
        title: action.mainModel.title,
        children: [
         
        ]
        
      }]
    
      // const treeRoot = _.first(tree).children
      action.paperModel.tree.map((v,i)=>{ //TODO : 재귀함수로 수정필요 
        if(v){ //빈 배열 번호 무시 

          if(action.paperModel.itemMap[v.id].common.visible==false){ //TODO : paperModel.tree에 있는 visible 은 false 고정이고 사용안되고 있는듯? 
            return ;
          }
          const _set=(obj)=>{

          }
          _.first(tree).children.push({
            key : v.id,
            title: `${action.paperModel.itemMap[v.id].common.title}`, //${v.id}. ${v.visible?"(view)":""}
            children:v.children.map(vv=>{
              if(action.paperModel.itemMap[vv.id].common.visible==false){
                return ;
              }
              return {
                key:vv.id,
                title : `${action.paperModel.itemMap[vv.id].common.title}`,
                children : vv.children.map(vvv=>{
                  if(action.paperModel.itemMap[vvv.id].common.visible==false){
                    return ;
                  }
                  return {
                    key:vvv.id,
                    title : `${action.paperModel.itemMap[vvv.id].common.title}`,
                    children : vvv.children.map(vvvv=>{
                      if(action.paperModel.itemMap[vvvv.id].common.visible==false){
                        return ;
                      }
                      return {
                        key:vvvv.id,
                        title : `${action.paperModel.itemMap[vvvv.id].common.title}`,
                        children : vvvv.children.map(vvvvv=>{
                          if(action.paperModel.itemMap[vvvvv.id].common.visible==false){
                            return ;
                          }
                          return {
                            key:vvvvv.id,
                            title : `${action.paperModel.itemMap[vvvvv.id].common.title}`,
                            children : vvvvv.children.map(vvvvvv=>{
                              if(action.paperModel.itemMap[vvvvvv.id].common.visible==false){
                                return ;
                              }
                              return {
                                key:vvvvvv.id,
                                title : `${action.paperModel.itemMap[vvvvvv.id].common.title}`,
                                children : [],
                              }
                            }).filter(item=>item!=undefined),
                          }
                        }).filter(item=>item!=undefined),
                      }
                    }).filter(item=>item!=undefined),
                  }
                }).filter(item=>item!=undefined),
              }
            }).filter(item=>item!=undefined),
          })
        }
      })
      // console.log("**tree", tree, action.paperModel.itemMap)
      return {
        ...state,
        tree:tree,
      };
    }
  
    default: {
      return state;
    }
  }
}