import _ from "lodash";

// 액션 타입 정의
const SET_INIT_DATA = "styles/SET_INIT_DATA";
export const getDefaultTitleHtmlText=({id})=>(dispatch,getState)=> {
  const { Paper } = getState();
  const itemData = Paper.itemMap[id];
  let html = '<p style="width: 100%; font-size: 16px; font-weight: bold; color: rgb(51, 51, 51);">\n    ';
  if(itemData.common.isRequired){
    html+='<span style="margin-left: -15px; color: red; position: absolute; font-size: 30px;">*</span>';
  }
  if(itemData.common.index){
    html+=itemData.common.index+". ";
  }
  html+=itemData.common.title+"\n</p>";
  return html;
};
export const getInfo=({id,target})=>(dispatch,getState)=> {
  const { Styles } = getState();
  id = id?.split("_")[0] //반복표의 로우들이 부모의 속성을 읽도록 처리 
  if (Styles.itemMap&&Styles.itemMap[id]) {
    const find = _.find(Styles.itemMap[id], { target });
    return find;
  }
};
export const get=({id,target})=>(dispatch,getState)=> {
  const find = getInfo({id,target})(dispatch,getState);
  if (find) {
    return {
      ...find.style
    };
  } else {
    return {};
  }
}
// 액션 생섬함수 정의
export const setInitData = ({data, }) => ({ type: SET_INIT_DATA, data });


// **** 초기상태 정의
const initialState = { 
  itemMap: {}
};

// **** 리듀서 작성
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_INIT_DATA: {
      return {
        ...action.data
      };
    }
    default: {
      return state;
    }
  }
}