// db.js
import Dexie from 'dexie';

/**
 * database create
 * @returns
 */
const createDatabase = () => {
  if (process.env.REACT_APP_PRDCTN_ID === '220001') {
    return 'P_CENSUS_DB';
  } else if (process.env.REACT_APP_PRDCTN_ID === '220002') {
    return 'A_CENSUS_DB';
  } else {
    return 'F_CENSUS_DB';
  }
};

/**
 * table crate
 * @returns
 */
const createTable = () => {
  if (process.env.REACT_APP_PRDCTN_ID === '220001') {
    return {
      exmnInfo: '++exmnId',
      ciRuleList: '++ciRuleId,exmnId,ciDesignGroupCd,dataTyCd,statsIemId,[exmnId+ciDesignGroupCd]',
      qsnDsgnstdrList: '++qsnSeCd,exmnId',
      hhmSexdstnCdList: '++cdId,cdNm, qesitmNo',
      hshldrRelCdList: '++cdId,cdNm, qesitmNo',
      nltyFrntnNtnCdList: '++cdId,cdNm, qesitmNo',
      cdList: '++cdId,cdNm, cdSortOrdr',
      hshldNmstList:
        '++hshldNmstId,partcptnNo,isSave,hhmSn,[hshldNmstId+isSave],[partcptnNo+isSave]',
    };
  } else if (process.env.REACT_APP_PRDCTN_ID === '220002') {
    return {
      exmnInfo: '++exmnId',
      ciRuleList:
        '++ciRuleId,exmnId,ciDesignGroupCd,dataTyCd,statsIemId,[exmnId+ciDesignGroupCd],[exmnId+dataTyCd]',
      qsnDsgnstdrList: '++qsnSeCd,exmnId',
      hhmSexdstnCdList: '++cdId,cdNm, qesitmNo',
      hshldrRelCdList: '++cdId,cdNm, qesitmNo',
      nltyFrntnNtnCdList: '++cdId,cdNm, qesitmNo',
      hshldNmstList: '++hshldNmstId,partcptnNo,isSave,hhmSn,[hshldNmstId+isSave]',
      commonCdList: '++cdGroupId',
      cropList: '++cropKey,ctpvCd,sggCd,sggCropsCd,[ctpvCd+sggCd]',
    };
  } else {
    return {
      exmnInfo: '++exmnId',
      ciRuleList: '++ciRuleId,exmnId,dataTyCd,statsIemId,[exmnId+ciDesignGroupCd]',
      qsnDsgnstdrList: '++qsnSeCd,exmnId',
      hshldNmstList:
        '++hshldNmstId,dwllgNo,bldgMngNo,isSave,[hshldNmstId+isSave],[partcptnNo+isSave]',
    };
  }
};

//set the database
export const LocalDB = new Dexie(createDatabase());
LocalDB.version(9).stores(createTable());
