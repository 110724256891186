import { ADD_AFFS, GET_AFFS } from '../actions/types';

const initialState = {
  values: { affs: [] },
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_AFFS:
      return {
        ...state,
      };
    case GET_AFFS:
      return {
        ...state,
        values: { answer: payload.affs },
      };
    default:
      return state;
  }
}
