export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const PASSWORD_SUCCESS = 'PASSWORD_SUCCESS';
export const PASSWORD_FAIL = 'PASSWORD_FAIL';
export const LOGOUT = 'LOGOUT';

export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const SET_LOADING = 'SET_LOADING';

export const SET_LANGUAGE = 'SET_LANGUAGE';

export const ADD_HHM_COVER = 'ADD_HHM_COVER';
export const INPUT_HHM_COVER = 'INPUT_HHM_COVER';
export const DELETE_HHM_COVER = 'DELETE_HHM_COVER';
export const ADD_FCLTY_COVER = 'ADD_FCLTY_COVER';

export const ADD_AFFS = 'ADD_AFFS';
export const GET_AFFS = 'GET_AFFS';

export const INPUT_ACENS_NMST_SE = 'INPUT_ACENS_NMST_SE';
export const ADD_ACENS_NMST_SE = 'ADD_ACENS_NMST_SE';
export const GET_ACENS_NMST_SE = 'GET_ACENS_NMST_SE';
