import { batch } from 'react-redux'
import _ from "lodash";
import dayjs from "dayjs";
import { callExternalStatistics as main_callExternalStatistics, updateProgress as main_updateProgress, updateTree as main_updateTree} from "./Main";
import { setTableFooter as paper_setTableFooter, setInputDataArray as paper_setInputDataArray, setAnswerCompleteArray as paper_setAnswerCompleteArray, setTableColumnText as paper_setTableColumnText, setTableType2RemoveRowArray as paper_setTableType2RemoveRowArray} from "./Paper";
import { addAffectedDataArray as answer_addAffectedDataArray, addAnswerArray as answer_addAnswerArray, removeAnswerArray as answer_removeAnswerArray } from "./Answer"
import { updateTree as paperTree_updateTree, } from "./PaperTree"
import { set as subItems_set } from './SubItems';
// 액션 타입 정의
const SET_INIT_DATA = "behavior/SET_INIT_DATA";
const SET_ACTIVE_QUESTION = "behavior/SET_ACTIVE_QUESTION";
const SET_MOUSEOVER_QUESTION = "behavior/SET_MOUSEOVER_QUESTION";
const SET_LEFT_TAB_INDEX = "behavior/SET_LEFT_TAB_INDEX";
const OPEN_CODE_PORTAL = "behavior/OPEN_CODE_PORTAL";
const CLOSE_CODE_PORTAL = "behavior/CLOSE_CODE_PORTAL";
const SET_CALLBACK_TRIGGER = "behavior/SET_CALLBACK_TRIGGER";
const TOGGLE_LEFT_SIDEBAR = "behavior/TOGGLE_LEFT_SIDEBAR";
const SHOW_LOADING = "behavior/SHOW_LOADING";
const HIDE_LOADING = "behavior/HIDE_LOADING";
const TOGGLE_REVIEW_RESULT = "behavior/TOGGLE_REVIEW_RESULT";
const TOGGLE_REVIEW_RESULT_ROW = "behavior/TOGGLE_REVIEW_RESULT_ROW";
const SET_LEFTSIDE_BAR_TYPE_2 = "behavior/SET_LEFTSIDE_BAR_TYPE_2";
const SET_STATUS = "behavior/SET_STATUS";
const UPDATE_SESSION_TIME_STRING = "behavior/UPDATE_SESSION_TIME_STRING";
const REFERESH_SESSION_TIME = "behavior/REFERESH_SESSION_TIME"
const SET_DEFAULT_SESSION_TIME = "behavior/SET_DEFAULT_SESSION_TIME";
const OPEN_SESSION_POPUP = "behavior/OPEN_SESSION_POPUP"
const OPEN_SAVE_POPUP = "behavior/OPEN_SAVE_POPUP"
const SET_SAVE_STAGE = "behavior/SET_SAVE_STAGE"
const EXECUTE_SAVE_PROCESS = "behavior/EXECUTE_SAVE_PROCESS"
const SET_NEXT_FOCUS_ID = "behavior/SET_NEXT_FOCUS_ID"
const CHANGE_SVY_MEMBER = "behavior/CHANGE_SVY_MEMBER"
const SET_ZOOM = "behavior/SET_ZOOM"
const TOGGLE_CAUTION = 'behavior/TOGGLE_CAUTION'
const SET_IS_INVALID_ANSWER = "behavior/SET_IS_INVALID_ANSWER"
const SHOW_SAVE_BUTTON = 'behavior/SHOW_SAVE_BUTTON'

// 액션 생섬함수 정의
export const setInitData = ({data, }) => ({ type: SET_INIT_DATA, data });
export const setActiveQuestion= ({activeQuestionId, }) => (dispatch, getState) => {
  dispatch({ type: SET_ACTIVE_QUESTION, activeQuestionId, });
  //외부 통계서비스 호출 
  dispatch(main_callExternalStatistics({gbn:"I",iemId:activeQuestionId}))

};
export const setMouseoverQuestion = ({mouseoverQuestionId,}) => ({ type: SET_MOUSEOVER_QUESTION, mouseoverQuestionId });
export const setLeftTabIndex = ({index, }) => ({ type: SET_LEFT_TAB_INDEX, index });
export const openCodePortal = () => ({ type: OPEN_CODE_PORTAL, });
export const closeCodePortal = () => ({ type: CLOSE_CODE_PORTAL, });
export const setCallbackTrigger= ({data, callback, }) => async (dispatch, getState) => {
  
  const { Paper:paperModel, Main:mainModel, Answer:answerModel,  } = getState();

  const {eventName, questionId, scriptList, saveKeys,  currentPage, nextPage, currentGroup, nextGroup, prevGroup, hhmsn } = data
  console.log("[Callback] call ",eventName,questionId)

  //사용자 콜백 풀 실행 
  const _executeCallbackPool = ()=>{
    batch(async ()=>{

      if(window.svy.callback_pool?.paper_setInputDataArray?.length > 0 ){
        dispatch(paper_setInputDataArray(window.svy.callback_pool.paper_setInputDataArray))
      }
  
      if(window.svy.callback_pool?.answer_addAffectedDataArray?.length > 0 ){
        dispatch(answer_addAffectedDataArray(window.svy.callback_pool.answer_addAffectedDataArray))
      }
  
      if(window.svy.callback_pool?.answer_addAnswerArray?.length > 0){
       dispatch(answer_addAnswerArray(window.svy.callback_pool.answer_addAnswerArray))
      }
  
      if(window.svy.callback_pool?.fn_surveyCi_setFocus != undefined){
        window.svy.fn_surveyCi_setFocus(window.svy.callback_pool?.fn_surveyCi_setFocus)
      }

      if(window.svy.callback_pool?.paper_setAnswerCompleteArray?.length > 0){
        dispatch(paper_setAnswerCompleteArray(window.svy.callback_pool?.paper_setAnswerCompleteArray))
      }

      if(window.svy.callback_pool?.paper_setTableColumnTextArray?.length > 0){
        dispatch(paper_setTableColumnText(window.svy.callback_pool?.paper_setTableColumnTextArray))
      }
      
      if(window.svy.callback_pool?.answer_removeAnswerArray?.length > 0){
        dispatch(answer_removeAnswerArray(window.svy.callback_pool?.answer_removeAnswerArray))
      }

      if(window.svy.callback_pool?.paper_removeRepeatTableRowArray?.length > 0){
        dispatch(paper_setTableType2RemoveRowArray(window.svy.callback_pool?.paper_removeRepeatTableRowArray))
      }
      if(window.svy.callback_pool?.subItems_setSubItemArray?.length > 0){
        window.svy.callback_pool?.subItems_setSubItemArray.map(subItem=>{
          dispatch(subItems_set({itemMapData : subItem.itemMapData, saveKey : subItem.saveKey, useCallbackPool : subItem.useCallbackPool}))

        })
      }
  
      
      if(window.svy.callback_pool?.main_updateProgress){
        dispatch(main_updateProgress())
      }
      if(window.svy.callback_pool?.paperTree_updateTree){
        dispatch(paperTree_updateTree())
      }
      if(window.svy.callback_pool?.main_updateTree){
        dispatch(main_updateTree())
      }
    })

    //clear
    window.svy.callback_pool={
      paper_setInputDataArray:[],
      answer_addAffectedDataArray:[],
      answer_addAnswerArray:[],
      paper_setAnswerCompleteArray : [],
      paper_setTableColumnTextArray : [],
      paper_removeRepeatTableRowArray : [],
      answer_removeAnswerArray : [],
      subItems_setSubItemArray : [],
      fn_surveyCi_setFocus:undefined,
      main_updateProgress:false,
      paperTree_updateTree:false,
      main_updateTree:false,
    }
  }


  if(eventName == "onBlur"){
    // console.log("callbackTrigger onBlur", nextProps.behaviorModel.callbackTrigger)
    let result;

    if(paperModel.itemMap[questionId] && paperModel.itemMap[questionId].callback[eventName]){ //사용자가 응답한 항목에 해당 콜백이 있으면, 
      
      const userCallbackData = paperModel.itemMap[questionId].callback[eventName]
      try{
        const userFunction = new Function(userCallbackData.param, userCallbackData.code)
        
        // const result = userFunction.apply(window.svy,[questionId,data.id])
        
        result = userFunction.apply(window.svy,[questionId,data.id, hhmsn])
        // console.log("result ", result)
      }catch(error){
        console.log("error from user api ", error)
      }          
    }
    _executeCallbackPool()
    callback(result)
    
  }if(eventName == "onFocus"){
    // console.log("callbackTrigger onFocus", nextProps.behaviorModel.callbackTrigger)
    
    if(paperModel.itemMap[questionId] && paperModel.itemMap[questionId].callback[eventName]){ //사용자가 응답한 항목에 해당 콜백이 있으면, 
      
      const userCallbackData = paperModel.itemMap[questionId].callback[eventName]
      try{
        const userFunction = new Function(userCallbackData.param, userCallbackData.code)
        
        const result = userFunction.apply(window.svy,[questionId,data.id, hhmsn])
        
        _executeCallbackPool()
        callback(result)
        // console.log("result ", result)
      }catch(error){
        console.log("error from user api ", error)
      }          
      
      
    }
    
  }else if(eventName=="changeAnswer"){
    //전역콜백
    if(mainModel.callback.changeAnswer){
      try{
        const userCallbackData = mainModel.callback.changeAnswer;
        const userFunction = new Function(userCallbackData.param, userCallbackData.code)
        let _this = {
          setFooter:({tableId,columnIndex,value})=>{ //TODO : 추후 셀 계산식 기능으로 대체 필요 
            dispatch(paper_setTableFooter({tableId,columnIndex,value}))
          }
        }
        
        
        let result = undefined
        setTimeout(() => { 
          _executeCallbackPool()
          result = userFunction.apply(window.svy,[answerModel.answerData]);
        }, 0);
        //NOTE : 전역콜백은 콜백호출 할 필요 없음.
      }catch(error){
        console.error("error from user api ", error)
      }
    }
    //항목콜백
    if(paperModel.itemMap[questionId] && paperModel.itemMap[questionId].callback[eventName]){ //사용자가 응답한 항목에 해당 콜백이 있으면, 
      
      const userCallbackData = paperModel.itemMap[questionId].callback[eventName]
      try{
        const userFunction = new Function(userCallbackData.param, userCallbackData.code)
        
        const answerData = _.pickBy(answerModel.answerData,(i,saveKey)=>{return saveKeys.indexOf(saveKey)!=-1})
        
        userFunction.apply(window.svy,[answerData, hhmsn])

      }catch(error){
        console.error("error from user api ", error)
      }          
        
    }
    await _executeCallbackPool()
    await callback()
  }else if(eventName =="inputReviewScript"){
    

    const reviewResult = {}
    scriptList.map(script=>{
      try{
        const userFunction = new Function("", script.ciScript)
        
        const result = userFunction.apply(window.svy,[])
        
        reviewResult[script.scriptApplcStatsIemId] = result
        // console.log("result ", result)

        _executeCallbackPool()
      }catch(error){
        console.error("error from user api ", error)
      }          
    })
    _executeCallbackPool()
    callback(reviewResult)
  }else if(eventName =="beforeChangePage"){
    
    const userCallbackData = mainModel.callback.beforeChangePage;
    let result = true
    if(userCallbackData && !_.isEmpty(userCallbackData.code) && (window.phcPass != "Y" && window.affPass != "Y")){ //작성된 콜백이 있으면 콜백의 리턴값을 받아옴 
      try{
        const userFunction = new Function(userCallbackData.param, userCallbackData.code)
        
        result = userFunction.apply(window.svy,[currentPage, nextPage, data.currentGroupId, data.nextGroupId])
        
      }catch(error){
        console.error("error from user api ", error)
      }     
    }
    _executeCallbackPool()
    callback(result)
    
   
  }else if(eventName =="afterChangePage"){
    
    const userCallbackData = mainModel.callback.afterChangePage;
    let result = true
    if(userCallbackData && !_.isEmpty(userCallbackData.code) ){ //작성된 콜백이 있으면 콜백의 리턴값을 받아옴 
      try{
        const userFunction = new Function(userCallbackData.param, userCallbackData.code)
        
        result = userFunction.apply(window.svy,[currentPage, data.currentGroupId])
        
      }catch(error){
        console.error("error from user api ", error)
      }  
         
    }
    _executeCallbackPool()
    callback(result)
    
   
  }else if(eventName =="clickButton"){
    // console.log(nextProps.behaviorModel.callbackTrigger, this.props.paperModel.itemMap[questionId])
    const userCallbackData = paperModel.itemMap[questionId].callback[eventName]
    // console.log("data",data)
    if(userCallbackData){ //콜백 작성한 경우에만 동작
      let result = undefined
      try{
        const userFunction = new Function(userCallbackData.param, userCallbackData.code)
        
        let repeatTableRowIndex = undefined
        if( data.id.split("_").length == 2){ //반복표 로우번호 보내주기 
          repeatTableRowIndex = data.id.split("_")[1]
        }
        result = userFunction.apply(window.svy,[data.id, repeatTableRowIndex, hhmsn])
        
      }catch(error){
        console.error("error from user api ", error)
      }          
      _executeCallbackPool()
      callback(result)
    }
    
  }else if(eventName =="receivePopupData"){
    
    _executeCallbackPool()
    //_executeCallbackPool 실행하기 위한 더미 
    
  }else if(eventName =="onload"){
    
    await _executeCallbackPool()
    await callback()
    //_executeCallbackPool 실행하기 위한 더미 
  }else if(eventName =="executeCallbackPool"){
    _executeCallbackPool()
    //_executeCallbackPool 실행하기 위한 더미 
  }else if(eventName == "clickMainButton"){
    const userCallbackData = mainModel.callback.clickMainButton;
    if(userCallbackData){
      try{
        const userFunction = new Function(userCallbackData.param, userCallbackData.code)
        
        let result = undefined
        setTimeout(() => { 
          _executeCallbackPool()
          result = userFunction.apply(window.svy, [data.buttonId])
        }, 0);
        //NOTE : 전역콜백은 콜백호출 할 필요 없음.
      }catch(error){
        console.error("error from user api ", error)
      }
    }

  }else if(eventName =="afterClickAddButton"){
    //반복표 행추가 버튼 클릭시 실행되는 사용자함수
    const userCallbackData = paperModel.itemMap[questionId].callback[eventName]
    if(userCallbackData){ //콜백 작성한 경우에만 동작
      let result = undefined
      try{
        const userFunction = new Function(userCallbackData.param, userCallbackData.code)
        let repeatTableId = questionId
        result = userFunction.apply(window.svy,[repeatTableId])
        
      }catch(error){
        console.error("error from user api ", error)
      }          
      _executeCallbackPool()
      callback(result)
    }

  }else if(eventName =="afterClickRemoveButton"){
    //반복표 행삭제 버튼 클릭시 실행되는 사용자함수
    const userCallbackData = paperModel.itemMap[questionId].callback[eventName]
    if(userCallbackData){ //콜백 작성한 경우에만 동작
      let result = undefined
      try{
        const userFunction = new Function(userCallbackData.param, userCallbackData.code)
        let repeatTableId = questionId
        result = userFunction.apply(window.svy,[repeatTableId])
        
      }catch(error){
        console.error("error from user api ", error)
      }          
      _executeCallbackPool()
      callback(result)
    }

  }else if(eventName =="beforeChangeGroup"){
    
    const userCallbackData = mainModel.callback.beforeChangeGroup;
    let result = true
    if(userCallbackData && !_.isEmpty(userCallbackData.code) && (window.phcPass != "Y" && window.affPass != "Y")){ //작성된 콜백이 있으면 콜백의 리턴값을 받아옴 
      try{
        const userFunction = new Function(userCallbackData.param, userCallbackData.code)
        
        result = userFunction.apply(window.svy,[currentGroup, nextGroup])
        
      }catch(error){
        console.error("error from user api ", error)
      }     
    }
    _executeCallbackPool()
    callback(result)
    
   
  }else if(eventName =="afterChangeGroup"){
    
    const data = mainModel.callback.afterChangeGroup;
    let result = true
    if(data && !_.isEmpty(data.code) ){ //작성된 콜백이 있으면 콜백의 리턴값을 받아옴 
      try{
        const userFunction = new Function(data.param, data.code)
        
        result = userFunction.apply(window.svy,[prevGroup, currentGroup])
        
      }catch(error){
        console.error("error from user api ", error)
      }  
         
    }
    _executeCallbackPool()
    callback(result)
    
   
  }else if(eventName =="afterSave"){
    
    const data = mainModel.callback.afterSave;
    let result = true
    if(data && !_.isEmpty(data.code) ){ //작성된 콜백이 있으면 콜백의 리턴값을 받아옴 
      try{
        const userFunction = new Function(data.param, data.code)
        
        result = userFunction.apply(window.svy,[])
        
      }catch(error){
        console.error("error from user api ", error)
      }  
         
    }
    await _executeCallbackPool()
    await callback(result)
    
   
  }else if(eventName == 'clickQuestionInfoButton'){
    const itemMapData = paperModel.itemMap[questionId]
    const data = itemMapData.callback.clickQuestionInfoButton;
    let result = true
    if(data && !_.isEmpty(data.code) ){ //작성된 콜백이 있으면 콜백의 리턴값을 받아옴 
      try{
        const userFunction = new Function(data.param, data.code)
        result = userFunction.apply(window.svy,[questionId, hhmsn])
        
      }catch(error){
        console.error("error from user api ", error)
      }  
         
    }
    await _executeCallbackPool()
  }else if(eventName == 'onPyeongCalcConfirm'){
    
    const callbackData = mainModel.callback.onPyeongCalcConfirm;
    let result = true
    if(callbackData && !_.isEmpty(callbackData.code) ){ //작성된 콜백이 있으면 콜백의 리턴값을 받아옴 
      try{
        const userFunction = new Function(callbackData.param, callbackData.code)
        result = userFunction.apply(window.svy,[data.saveKey, data.value])
        
      }catch(error){
        console.error("error from user api ", error)
      }  
         
    }
    await _executeCallbackPool()
    callback(result)
  }else if(eventName == 'clickQuestionRejectButton'){
    const itemMapData = paperModel.itemMap[questionId]
    const callbackData = itemMapData.callback.clickQuestionRejectButton;
    let result = true
    if(callbackData && !_.isEmpty(callbackData.code) ){ //작성된 콜백이 있으면 콜백의 리턴값을 받아옴 
      try{
        const userFunction = new Function(callbackData.param, callbackData.code)
        result = userFunction.apply(window.svy,[questionId, data.isRejected, data.hhmsn])
        
      }catch(error){
        console.error("error from user api ", error)
      }  
    }
    await _executeCallbackPool()
    callback(result)
  }


  
  
  // dispatch({ type: SET_CALLBACK_TRIGGER, data, callback, });
};
export const toggleLeftSidebar = () => ({ type: TOGGLE_LEFT_SIDEBAR, });
export const showLoading = ({text,   }) => ({ type: SHOW_LOADING, text, });
export const hideLoading = () => ({ type: HIDE_LOADING, });
export const toggleReviewResult = (visible) => ({ type: TOGGLE_REVIEW_RESULT, visible, });
export const toggleReviewResultRow = ({reviewResult}) => ({ type: TOGGLE_REVIEW_RESULT_ROW, reviewResult,});
export const setLeftSidebarType2 = ({id, }) => ({ type: SET_LEFTSIDE_BAR_TYPE_2, id });
export const setStatus= ({questionId, svyTableUpdtAt}) => (dispatch, getState) => {
  dispatch({ type: SET_STATUS, paperModel: getState().Paper, questionId, svyTableUpdtAt});
};
export const setDefaultSessionTime = ({time, }) => ({ type: SET_DEFAULT_SESSION_TIME, time, });
export const updateSessionTimeString = () => ({ type: UPDATE_SESSION_TIME_STRING, });
export const refreshSessionTime = () => ({ type: REFERESH_SESSION_TIME, });
export const openSessionPopup = ({open, }) => ({ type: OPEN_SESSION_POPUP, open, });
export const openSavePopup = ({open, isSaveComplete}) => ({ type: OPEN_SAVE_POPUP, open, isSaveComplete});
export const setSaveStage = ({stage}) => ({ type: SET_SAVE_STAGE, stage});
export const executeSaveProcess = ({inSaveProcess}) =>(dispatch, getState)=> {
  const debounce = (func, delay)=>{
    let timerId;
    let tempFunc = (...args) => {
      if(timerId){
        clearTimeout(timerId)
      }
      timerId=setTimeout(()=>{
        func(...args);
        timerId = null;
      }, delay)
    }
    return tempFunc
  }
  const debouncedDispatch = debounce((action)=>{
    getState();
    dispatch(action)
  }, 500)
  debouncedDispatch({type:EXECUTE_SAVE_PROCESS, inSaveProcess});
  // dispatch({type:EXECUTE_SAVE_PROCESS, inSaveProcess})

}
export const setNextFocusId = ({nextFocusId}) =>({type:SET_NEXT_FOCUS_ID, nextFocusId})
export const changeSvyMember =({hhmSn}) => (dispatch, getState) => {

  // dispatch(main_setInitFalse({}))
  dispatch({type:CHANGE_SVY_MEMBER, hhmSn})
}
export const setZoom = ({zoom}) => ({type:SET_ZOOM , zoom})
export const toggleCaution = ({}) => ({type:TOGGLE_CAUTION, })
export const setIsInvalidAnswer = ({isInvalidAnswer, itemId}) => ({type : SET_IS_INVALID_ANSWER , isInvalidAnswer, itemId})
export const showSaveButton = ({show})=>({type:SHOW_SAVE_BUTTON, show})


// **** 초기상태 정의
const initialState = { //항목 배치 순서 담당 
  activeQuestionId:"0",
  leftTabIndex:1,
  openCodePortal:false,
  mouseOverQuestionId:undefined,
  callbackTrigger:{},
  callbackTriggerCompleteCallback:undefined,
  toggleLeftSidebar:false,
  openReviewResult:false,
  loading:{
    show:false,
    text:""
  }, 
  selectedReivewResult:undefined, //입력자료 검토내용 선택 정보
  leftSidebarType2:{
    selectedId:undefined,
  },
  nmstUpdtAt:"N",//명부 수정 여부
  svyTableUpdtAt:"N",//조사표 수정 여부

  sessionExpireTime:"",
  defaultSessionTime:0, 
  sessionTime:"--:--", 
  sessionPopup:false,
  savePopup:false,
  saveStage:0, 
  isSaveComplete : false,
  inSaveProcess : false, 
  nextFocusId : '',
  changeSvyMember : {
    needChange : false,
    hhmSn : 0
  },
  zoom : 1,
  toggleCaution : false, 
  isInvalidAnswer : false,
  invalidItemId : '',
  showSaveButton :false
};

// **** 리듀서 작성
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_INIT_DATA: {
      return action.data;
    }
    case SET_ACTIVE_QUESTION: {
      return {
        ...state,
        activeQuestionId:action.activeQuestionId, 
       
      };
    }
    case SET_LEFT_TAB_INDEX: {
      return {
        ...state,
        leftTabIndex:action.index, 
       
      };
    }
    case SET_MOUSEOVER_QUESTION: {
      return {
        ...state,
        mouseOverQuestionId:action.mouseoverQuestionId, 
       
      };
    }
    case OPEN_CODE_PORTAL: {
      return {
        ...state,
        openCodePortal:true, 
       
      };
    }
    case CLOSE_CODE_PORTAL: {
      return {
        ...state,
        openCodePortal:false, 
       
      };
    }
    case SET_CALLBACK_TRIGGER: {
      return {
        ...state,
        callbackTrigger:action.data, 
        callbackTriggerCompleteCallback : action.callback,
      };
    }
    case TOGGLE_LEFT_SIDEBAR: {
      return {
        ...state,
        toggleLeftSidebar:!state.toggleLeftSidebar, 
       
      };
    }
    case SHOW_LOADING: {
      console.log("🚀 ~ reducer ~ show loading")
      return {
        ...state,
        loading:{
          show:true,
          text:action.text,
        }, 
      };
    }
    case HIDE_LOADING: {
      console.log("🚀 ~ reducer ~ hide loading")
      window.svy.contHeight = 0
      return {
        ...state,
        loading:{
          show:false,
          text:"",
        }, 
      };
    }
    case TOGGLE_REVIEW_RESULT: {
      if(_.isBoolean(action.visible)){
        return {
          ...state,
          openReviewResult:action.visible, 
         
        }
      }else{
        return {
          ...state,
          openReviewResult:!state.openReviewResult, 
         
        }
      }
      
    }
    case TOGGLE_REVIEW_RESULT_ROW: {
      console.log("TOGGLE_REVIEW_RESULT_ROW",state.selectedReivewResult==undefined?action.reviewResult:undefined)
      return {
        ...state,
        selectedReivewResult:state.selectedReivewResult==action.reviewResult?undefined:action.reviewResult, 
       
      };
    }
    case SET_LEFTSIDE_BAR_TYPE_2: {
      return {
        ...state,
        leftSidebarType2:{
          selectedId:action.id,
        }, 
       
      };
    }
    case SET_STATUS: {
      const {paperModel,questionId, svyTableUpdtAt} = action;
      const copyState = _.cloneDeep(state);
      let isNmst = false  //명부항목 여부 
      
      
      if( questionId){
        const source = paperModel.itemMap[questionId].source
        if(source && source=="nmst"){
          isNmst = true
        }else{
          const parentKeyArray = questionId.split("_")
          if(_.endsWith(parentKeyArray[0], "N") || _.endsWith(parentKeyArray[0], "NL")){
            isNmst = true
          }
        }
          
      }
      if(isNmst===true){
        copyState.nmstUpdtAt = "Y";
      }else{
        if(_.isEmpty(action.svyTableUpdtAt)){
          copyState.svyTableUpdtAt = "Y";
        }else{
          copyState.svyTableUpdtAt = "N";
        }
      }
      return copyState;
    }
    case SET_DEFAULT_SESSION_TIME: {
      return {
        ...state,
        defaultSessionTime:action.time, 
        sessionExpireTime:new dayjs().add(action.time,"m"),
      };
    }
    case UPDATE_SESSION_TIME_STRING: {
      const diff = state.sessionExpireTime.diff(dayjs())
      var duration = require("dayjs/plugin/duration")
      dayjs.extend(duration)
      var timeDuration = dayjs.duration(diff);

      if(diff <= 0){
        return {
          ...state,
          sessionTime:`00:00`,
         
        };
      }else{
        return {
          ...state,
          sessionTime:`${_.padStart(timeDuration.minutes(),2,"0")}:${_.padStart(timeDuration.seconds(),2,"0")}`,
         
        };
      }
    }
    case REFERESH_SESSION_TIME: {
      return {
        ...state,
        sessionExpireTime:new dayjs().add(state.defaultSessionTime,"m"),
       
      };
    }
    case OPEN_SESSION_POPUP: {
      return {
        ...state,
        sessionPopup:action.open, 
       
      };
    }
    case OPEN_SAVE_POPUP: {

      return {
        ...state,
        savePopup:action.open, 
        saveStage:0,
        isSaveComplete : action.isSaveComplete
       
      };
    }
    case SET_SAVE_STAGE: {
      return {
        ...state,
        saveStage:action.stage, 
       
      };
    }
    case EXECUTE_SAVE_PROCESS : {
      return{
        ...state,
        inSaveProcess : action.inSaveProcess
      }
    }
    case SET_NEXT_FOCUS_ID: {
      return{
        ...state,
        nextFocusId : action.nextFocusId
      }
    }case CHANGE_SVY_MEMBER : {
      return{
        ...state,
        changeSvyMember : {
          needChange : !state.changeSvyMember.needChange,
          hhmSn : action.hhmSn
        }
      }
    }case SET_ZOOM : {
      return {
        ...state,
        zoom : action.zoom
      }
    }case TOGGLE_CAUTION : {
      return {
        ...state,
        toggleCaution : !state.toggleCaution
      }
    }
    case SET_IS_INVALID_ANSWER : {
      return {
        ...state,
        isInvalidAnswer : action.isInvalidAnswer,
        invalidItemId : action.itemId
      }
    }
    case SHOW_SAVE_BUTTON : {
      return {
        ...state,
        showSaveButton : action.show
      }
    }
    default: {
      return state;
    }
  }
}