export default function AuthHeader() {
  const authToken = sessionStorage.getItem('authToken');
  const appMode = process.env.REACT_APP_MAIN_APP;
  // if (authToken) {
  // For Spring Boot back-end
  return {
    'X-Auth-Token': authToken,
    'CAPI-Request-Key': '4963FBDB496CE6BE97A329E808926BA0CED38F8215D9FBA3E6306EBA832033CD',
    'Cache-Control': 'max-age=86400',
  };
  // } else {
  //   window.location.href = url;
  // }
}
