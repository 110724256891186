import _ from "lodash";
import dayjs from "dayjs";
import { setActiveQuestion, setCallbackTrigger as behavior_setCallbackTrigger, } from "./Behavior";
import { getCurrentGroup as paper_getCurrentGroup } from "./Paper";
import { isMobile, } from 'react-device-detect';
// const axios = require('axios');
import api from '../../common/utils/api';
// 액션 타입 정의
const SET_INIT_DATA = "main/SET_INIT_DATA";
const INIT = "main/INIT";
const INIT_FALSE = 'main/INIT_FALSE'
const SET_SIDEBAR_WIDTH = "main/SET_SIDEBAR_WIDTH";

const ADD_GROUP_TREE_DATA = "main/ADD_GROUP_TREE_DATA";
const ADD_GROUP_TREE_ITEM = "main/ADD_GROUP_TREE_ITEM";
const DELETE_GROUP_TREE_DATA = "main/DELETE_GROUP_TREE_DATA";
const DELETE_GROUP_TREE_ITEM = "main/DELETE_GROUP_TREE_ITEM";
const DELETE_ALL_GROUP_TREE_DATA = "main/DELETE_ALL_GROUP_TREE_DATA";
const SET_ACTIVE_GROUP_ID = "main/SET_ACTIVE_GROUP_ID"
const CHANGE_GROUP_TREE_DATA_TITLE = "main/CHANGE_GROUP_TREE_DATA_TITLE";
const CHANGE_GROUP_TREE_DATA_VISIBLE = 'main/CHANGE_GROUP_TREE_DATA_VISIBLE'

const SET_INPUT_REVIEW_SCRIPT = "main/SET_INPUT_REVIEW_SCRIPT";
const ADD_INPUT_REVIEW_SCRIPT_RESULT = "main/ADD_INPUT_REVIEW_SCRIPT_RESULT";

const SET_INPUT_REVIEW_SQL = "main/SET_INPUT_REVIEW_SQL";

const SET_HOUSE_HOLD_INFO = "main/SET_HOUSE_HOLD_INFO";

const UPDATE_PROGRESS = "main/UPDATE_PROGRESS";

const SET_EXTERNAL_STATISTICS = "main/SET_EXTERNAL_STATISTICS"
const CALL_EXTERNAL_STATISTICS = "main/CALL_EXTERNAL_STATISTICS"
const SINGLE_PAGE_MODE_NEXT = "main/SINGLE_PAGE_MODE_NEXT"
const SINGLE_PAGE_MODE_PREV = "main/SINGLE_PAGE_MODE_PREV"
const SINGLE_PAGE_MODE = "main/SINGLE_PAGE_MODE"
const UPDATE_TREE = "main/UPDATE_TREE"
const TOGGLE_NEED_SAVEREQUEST = "main/TOGGLE_NEED_SAVEREQUEST"

const SET_AUTOCOMPLETELIST = 'main/SET_AUTOCOMPLETELIST'
const TOGGLE_PYEONGCALC_AUTO_USE = "main/TOGGLE_PYEONGCALC_AUTO_USE"
const SET_SVY_RESULT_LIST = 'main/SET_SVY_RESULT_LIST'
const SET_SVY_RESULT_CHECK = 'main/SET_SVY_RESULT_CHECK'
const SET_NEED_CONFIRM_LIST = "main/"


// 액션 생섬함수 정의
export const setInitData = ({data, }) => ({ type: SET_INIT_DATA, data });
export const init = ({title, version, middleUrl, params, activeGroupId, educationData,rejectList  }) => (dispatch, getState) => {
  dispatch({ type: INIT, propertyModel: getState().Property,paperModel:getState().Paper,paperTreeModel:getState().PaperTree, title, version, middleUrl, params,  activeGroupId, educationData, rejectList});
  const {Main} = getState();
  if(Main.singleQuestionPageArray.length > 0){//단일항목 모드를 사용중이면 ..
    const item = _.find(Main.singleQuestionPageArray,{treeIndex:Main.singleQuestionPageIndex});
    if(item){
      dispatch({ type: "behavior/SET_ACTIVE_QUESTION", activeQuestionId:item.id });
    }
  }
  
};//초기화
export const initFalse = ()=>({type:INIT_FALSE}) //로그아웃시 main모델의 init false로 초기화.
export const setSidebarWidth = ({position, width}) => (dispatch, getState) => {
  dispatch({ type: SET_SIDEBAR_WIDTH, position, width, });  
};
export const addGroupTreeData = ({title, }) => (dispatch, getState) => {
  dispatch({ type: ADD_GROUP_TREE_DATA, title, });
};
export const addGroupTreeItem = ({parentId, id, }) => (dispatch, getState) => {
  dispatch({ type: ADD_GROUP_TREE_ITEM, paperModel:getState().Paper, parentId, id,  });
};
export const deleteGroupTreeData = ({id, }) => (dispatch, getState) => {
  dispatch({ type: DELETE_GROUP_TREE_DATA, id, });
};
export const deleteAllGroupTreeData = () => (dispatch, getState) => {
  dispatch({ type: DELETE_ALL_GROUP_TREE_DATA, });
};
export const deleteGroupTreeItem = ({parentId, id, }) => (dispatch, getState) => {
  dispatch({ type: DELETE_GROUP_TREE_ITEM, parentId, id, });
};
export const setActiveGroupId = ({groupId}) => (dispatch, getState) => {
  window.svy.didWindowUpdated = true
  let result = true;
  //beforeChangeGroup currentGroup : activeGroupId , nextGroup : groupId
  const { Main,Paper, SubItems } = getState();

  if(Main.device == 'ecsa' || Main.device == 'ecpa' || Main.device == 'area'){
    if(Main.readonlyMode != true){
      window.svy.reviewResult = true;
      dispatch(
        behavior_setCallbackTrigger({
          data:{
            eventName:"beforeChangeGroup",
            currentGroup:Main.activeGroupId,
        nextGroup:groupId,
      },callback: (response) => {
        if( response == true){
          //농총용 진도율 계산플래그 갱신
          window.svy.groupList[Main.activeGroupId] =  window.svy.reviewResult;
          dispatch({ type: UPDATE_PROGRESS, paperModel: Paper, subItemsModel : SubItems, device: Main.device, mainModel : Main});
          //실제 이동 
          dispatch({ type: SET_ACTIVE_GROUP_ID, groupId, });
          //afterChangeGroup
          setTimeout(()=>{
            dispatch(
              behavior_setCallbackTrigger({
                data:{
                  eventName:"afterChangeGroup",
                  prevGroup : Main.activeGroupId,
                  currentGroup:groupId,
                },callback: (response) => {
                }})
                )
              },0)
            
          }else{
            result = false
          }
        }
      })  
    )
  }else{
    dispatch({ type: SET_ACTIVE_GROUP_ID, groupId, });
  }
  }else{
    dispatch(behavior_setCallbackTrigger({
      data:{
        eventName:"beforeChangeGroup",
        currentGroup:Main.activeGroupId,
        nextGroup:groupId,
      },
      callback:(response)=>{
        if(response != false){
          dispatch({ type: SET_ACTIVE_GROUP_ID, groupId, });
          setTimeout(()=>{
            dispatch(
              behavior_setCallbackTrigger({
                data:{
                  eventName:"afterChangeGroup",
                  prevGroup : Main.activeGroupId,
                  currentGroup:groupId,
                },callback: (response) => {
                }})
                )
              },0)
        }else{
          result = false
          const {Popup} = getState();
          if(Popup.popupData[0]?.length > 0){
            // css 강조표시될 내검걸린 항목 리스트 생성.
            let confirmList = []
            Popup.popupData[0].forEach(questionId=>{
              confirmList.push(questionId)
            })
            dispatch({type:SET_NEED_CONFIRM_LIST, confirmList});
          }
        }
      }
    }))

  // dispatch({ type: SET_ACTIVE_GROUP_ID, groupId, });
  }
  return result

};
export const changeGroupTreeDataTitle = ({id, title, }) => (dispatch, getState) => {
  dispatch({ type: CHANGE_GROUP_TREE_DATA_TITLE, id, title, });
};
export const changeGroupTreeDataVisible = ({title, value}) =>({type:CHANGE_GROUP_TREE_DATA_VISIBLE , title, value})
export const setInputReviewScript = ({data, }) => ({ type: SET_INPUT_REVIEW_SCRIPT, data });
export const addInputReviewScriptResult = ({resultList, }) => ({ type: ADD_INPUT_REVIEW_SCRIPT_RESULT, resultList, });

export const setInputReviewSQL = ({data, }) => ({ type: SET_INPUT_REVIEW_SQL, data });

export const setHouseHoldInfo = ({data, }) => ({ type: SET_HOUSE_HOLD_INFO, data });


export const updateProgress= () => (dispatch, getState) => {
  const { Paper, SubItems, Main } = getState();
  const device = Main.device
  dispatch({ type: UPDATE_PROGRESS, paperModel: Paper, subItemsModel : SubItems, device, mainModel : Main});

};
export const setExternalStatistics = ({enable, }) => ({ type: SET_EXTERNAL_STATISTICS, enable });

export const callExternalStatistics= ({gbn, iemId, }) => (dispatch, getState) => {
  const { Main:mainModel, } = getState();

  // if(mainModel.externalStatistics.enable != true){
  //   return
  // }
  if(mainModel.params.preView == "Y" || mainModel.readonlyMode == true){
    return 
  }
  

  // const { qsnId, } = mainModel.params
  // gbn : 데이터 구분, iemId : 항목ID
// prdaTrgtId : 명부ID, qsnId : 조사표ID, qsnDesignTyCd : 조사방식코드, qsnRate : 조사표진도율
  // 인총
  // O : 조사표열기 : 2200010411DV0004802
  // C : 조사표닫기 : 2200010412DV0004804
  // I : 통계항목ID : 2200010412DV0004806 , 선택시간 : 2200010412DV0004807
  // S : 조사표진도율 : 2200010412DV0004808, 조사표저장 : 2200010412DV0004809
  
  
  // 농총
  // O : 조사표열기 : 2200020412DV0004820
  // C : 조사표닫기 : 2200020412DV0004822
  // I : 통계항목ID : 2200020412DV0004824 , 선택시간 : 2200020412DV0004825
  // S : 조사표진도율 : 2200020412DV0004826, 조사표저장 : 2200020412DV0004827
  
  
  // 가기초
  // O : 조사표열기 : 2200030412DV0004811
  // C : 조사표닫기 : 2200030412DV0004813
  // I : 통계항목ID : 2200030412DV0004815 , 선택시간 : 2200030412DV0004816
  // S : 조사표진도율 : 2200030412DV0004817, 조사표저장 : 2200030412DV0004818

  const _parameterSerialize = (obj) => {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(p + '=' + obj[p]);
      }
    return str.join('&');
  };
  
  let dumArr = [];
  let data = {};
  let dumObj = {};
  //let systemInfo = {};  //{prdctnId:생산ID, sysId: 시스템 ID, exmnId:조사ID} ==> 필수
  const device = mainModel.device
  const O = device == 'ecsa'|| device == 'ecpa'|| device == 'area' ? '2200020412DV0004820' : (device == 'ecph' || device == 'ecth' || device == 'ihst') ? '2200030412DV0004811' : '2200010411DV0004802'
  const C = device == 'ecsa'|| device == 'ecpa'|| device == 'area' ? '2200020412DV0004822' : (device == 'ecph' || device == 'ecth' || device == 'ihst') ? '2200030412DV0004813' : '2200010412DV0004804'
  const I_itemId = device == 'ecsa'|| device == 'ecpa'|| device == 'area' ? '2200020412DV0004824' : (device == 'ecph' || device == 'ecth' || device == 'ihst') ? '2200030412DV0004815' : '2200010412DV0004806'
  const I_time = device == 'ecsa'|| device == 'ecpa'|| device == 'area' ? '2200020412DV0004825' : (device == 'ecph' || device == 'ecth' || device == 'ihst') ? '2200030412DV0004816' : '2200010412DV0004807'
  const S_progress = device == 'ecsa'|| device == 'ecpa'|| device == 'area' ? '2200020412DV0004826' : (device == 'ecph' || device == 'ecth' || device == 'ihst') ? '2200030412DV0004817' : '2200010412DV0004808'
  const S_save = device == 'ecsa'|| device == 'ecpa'|| device == 'area' ? '2200020412DV0004827' : (device == 'ecph' || device == 'ecth' || device == 'ihst') ? '2200030412DV0004818' : '2200010412DV0004809'

  const qsnId = mainModel.params.qsnId
  const prdaTrgtId = mainModel.device == 'area' ? mainModel.params.adliId : mainModel.params.hshldNmstId; // 명부ID, 변수처리 , 지역조사는 행정리ID로 처리 
  //세션 스토리지에 담겨있지 않은 경우가 있는건지... 로컬이라서 없는건지 확인 필요.
  const qsnDesignTyCd = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')))?.exmnMthdCd; // 조사방식코드, 변수 처리 
  const qsnRate = `${mainModel.progress}%`; // 조사표진도율 변수 처리

  const exmnMthdCd = device.substring(1,3) == "cs" ? "1" : device.substring(1,3) == "ss" ? "2" : device.substring(1,3) == "cp" ? "3" : device.substring(1,3) == "ct" ? "5" : "";


  //시스템정보 셋팅
  data.prdctnId = process.env.REACT_APP_PRDCTN_ID;
  data.exmnId = process.env.REACT_APP_EXMN_ID;
  data.sysId = device.toUpperCase();

  // data.nmstId = nmstId;
  // data.nmstStdrOdr = nmstStdrOdr;
  // data.nmstRecordId = nmstRecordId; 

  if(gbn == "O"){ // 조사표열기
      // console.log("파라데이터!!!!!!!!!!!!!!!! - 조사표열기"); 
      dumObj[O] = dayjs().format("YYYY-MM-DD HH:mm:ss")
      dumArr.push(dumObj);
      data.responseData = dumArr;
  }else if(gbn == "C"){ // 조사표닫기 요약표로넘어갈때 호출
      // console.log("파라데이터!!!!!!!!!!!!!!!! - 조사표닫기 요약표로넘어갈때 호출"); 
      dumObj[C] = dayjs().format("YYYY-MM-DD HH:mm:ss")
      dumArr.push(dumObj);
      data.responseData = dumArr;
  }else if(gbn == "I" && (iemId != null && iemId != "")){ // 통계항목ID 답변이 커밋될때 호출.
    // console.log("파라데이터!!!!!!!!!!!!!!!! - 항목"); 
      if(mainModel.externalStatistics.lastId != iemId){
        dumObj[I_itemId] = iemId; // 통계항목ID
        dumObj[I_time] = dayjs().format("YYYY-MM-DD HH:mm:ss") // 선택시간
        dumArr = JSON.parse(sessionStorage.getItem("responseData")) || [];
        dumArr.push(dumObj);
        sessionStorage.setItem('responseData',JSON.stringify(dumArr));
        dispatch({ type: CALL_EXTERNAL_STATISTICS, lastId:iemId,  });
      }
  }else if(gbn == "S"){ // 조사표진도율
      // console.log("파라데이터!!!!!!!!!!!!!!!! - 조사표진도율"); 
      dumObj[S_progress] = qsnRate // 조사표진도율
      dumObj[S_save] = dayjs().format("YYYY-MM-DD HH:mm:ss") // 조사표 저장시간
      dumArr = JSON.parse(sessionStorage.getItem("responseData")) || [];
      dumArr.push(dumObj);
      data.responseData = dumArr;
  }else{
    return false
  }
  
  if(gbn != "I"){
    data.prdaTrgtId = prdaTrgtId || null;
    data.qsnId = qsnId;
    data.qsnDesignTyCd = qsnDesignTyCd != null ? qsnDesignTyCd : exmnMthdCd;
    try{
      api.post(`/bz200/process-data-save`,data)
    }catch(e){
      console.error("/bz/esvybz200/process-data-save",e)
    }

    sessionStorage.removeItem("responseData");
  }
};
const behaviorSetAtiveQuestion = (dispatch, getState) =>{
  const {Main} = getState();
  const findItem = _.find(Main.singleQuestionPageArray,{treeIndex:Main.singleQuestionPageIndex});
  if(findItem){
    dispatch(setActiveQuestion({activeQuestionId:findItem.id}) ) 
  }
}
export const singePageMode_prev= ({afterMove, nextId}) => (dispatch, getState) => {
  const {Main, Paper} = getState();
  window.svy.didWindowUpdated = true
  const singleQuestionList = _.filter(
    Paper.tree,
    (treeLeaf) => {
      const visible = Paper.itemMap?.[treeLeaf.id].common.visible
      const visibleCnt = _.filter(visible, v=> v != false).length
      if(treeLeaf.isHiddenItem != true && visibleCnt > 0){
        return true
      }else{
        return false
      }
    },
  );
  let nextPage=Main.singleQuestionPageIndex-1
  if(!_.isEmpty(nextId)){
    nextPage = _.find(Main.singleQuestionPageArray, {id : nextId}).treeIndex + 1
  }
  const currentGroupId = singleQuestionList[Main.singleQuestionPageIndex].id
  const nextGroupId = singleQuestionList[nextPage].id
  
  if(Main.singleQuestionPageIndex-1 >= 0){
    // 사용자 콜백에 따라서 이동여부 결정 
    dispatch(
      behavior_setCallbackTrigger({
        data:{
          eventName:"beforeChangePage",
          currentPage:Main.singleQuestionPageIndex,
          nextPage:nextPage,
          currentGroupId,
          nextGroupId
        },callback: (response) => {
          if( response == true){
             //실제 이동 
            dispatch({ type: SINGLE_PAGE_MODE_PREV, });
            behaviorSetAtiveQuestion(dispatch, getState);
            afterMove?.()

            setTimeout(()=>{
              dispatch(
                behavior_setCallbackTrigger({
                  data:{
                    eventName:"afterChangePage",
                    currentPage:nextPage,
                    currentGroupId :nextGroupId
                  },callback: (response) => {
                     //페이지 이동후에 이동한 항목의 전역그룹이 달라진경우 그룹변경.
                     const prevGroup = _.find(Main.groupTree, {children : [{key : singleQuestionList[Main.singleQuestionPageIndex].id }]})
                     const currentGroup = _.find(Main.groupTree, {children : [{key : singleQuestionList[Main.singleQuestionPageIndex-1].id }]})
                     if(prevGroup != currentGroup){
                       dispatch({ type: SET_ACTIVE_GROUP_ID, groupId: currentGroup.key, });
                     }
                  }})
              )
            },0)
          }else{
            const {Popup} = getState();
            if(Popup.popupData[0]?.length > 0){
              // css 강조표시될 내검걸린 항목 리스트 생성.
              let confirmList = []
              Popup.popupData[0].forEach(questionId=>{
                confirmList.push(questionId)
              })
              dispatch({type:SET_NEED_CONFIRM_LIST, confirmList});
            }
          }
          
        }
      })
    )

   
  }else{
    // 사용자 콜백에 따라서 이동여부 결정 
    dispatch(
      behavior_setCallbackTrigger({
        data:{
          eventName:"beforeChangePage",
          currentPage:1,
          nextPage:1,
        },callback: (response) => {
          if( response == true){

          }
          
        }
      })
    )
  }
};
export const singePageMode_next= ({afterMove, nextId}) => (dispatch, getState) => {
  const {Main, Paper} = getState();
  window.svy.didWindowUpdated = true
  const singleQuestionList = _.filter(
    Paper.tree,
    (treeLeaf) => {
      const visible = Paper.itemMap?.[treeLeaf.id].common.visible
      const visibleCnt = _.filter(visible, v=> v != false).length
      if(treeLeaf.isHiddenItem != true && visibleCnt > 0){
        return true
      }else{
        return false
      }
    },
  );
  const max = singleQuestionList.length;
  let nextPage=Main.singleQuestionPageIndex+1 != singleQuestionList.length ? Main.singleQuestionPageIndex+1 : Main.singleQuestionPageIndex
  if(!_.isEmpty(nextId)){
    nextPage = _.find(Main.singleQuestionPageArray, {id : nextId}).treeIndex + 1;
  }
  const currentGroupId = singleQuestionList[Main.singleQuestionPageIndex].id
  const nextGroupId = singleQuestionList[nextPage].id
  
  if(Main.singleQuestionPageIndex+1 < max){
    // 사용자 콜백에 따라서 이동여부 결정 
    dispatch(
      behavior_setCallbackTrigger({
        data:{
          eventName:"beforeChangePage",
          currentPage:Main.singleQuestionPageIndex,
          nextPage: nextPage,
          currentGroupId,
          nextGroupId
        },callback: (response) => {
          if( response == true){
            //실제 이동 
            dispatch({ type: SINGLE_PAGE_MODE_NEXT, });
            behaviorSetAtiveQuestion(dispatch, getState);

            setTimeout(()=>{
              dispatch(
                behavior_setCallbackTrigger({
                  data:{
                    eventName:"afterChangePage",
                    currentPage:nextPage,
                    currentGroupId :nextGroupId
                  },callback: (response) => {
                    //페이지 이동후에 이동한 항목의 전역그룹이 달라진경우 그룹변경.
                    const prevGroup = _.find(Main.groupTree, {children : [{key : singleQuestionList[Main.singleQuestionPageIndex].id }]})
                    const currentGroup = _.find(Main.groupTree, {children : [{key : singleQuestionList[Main.singleQuestionPageIndex+1].id }]})
                    let groupChanged = false
                    if(prevGroup != currentGroup){
                      groupChanged = true
                      dispatch({ type: SET_ACTIVE_GROUP_ID, groupId: currentGroup.key, });
                    }
                    afterMove?.(groupChanged)
                  }})
              )
            },0)
          }else{
            const {Popup} = getState();
            if(Popup.popupData[0]?.length > 0){
              // css 강조표시될 내검걸린 항목 리스트 생성.
              let confirmList = []
              Popup.popupData[0].forEach(questionId=>{
                confirmList.push(questionId)
              })
              dispatch({type:SET_NEED_CONFIRM_LIST, confirmList});
            }
          }
          
        }
      })
    )
    

  }else{
    dispatch(
      behavior_setCallbackTrigger({
        data:{
          eventName:"beforeChangePage",
          currentPage:Main.singleQuestionPageIndex+1,
          nextPage:Main.singleQuestionPageIndex+1,
        },callback: (response) => {
          if( response == true){

          }
          
        }
      })
    )
  }
  
};
export const singePageMode= (pageIndex) => (dispatch, getState) => {
  const { Paper } = getState();
  window.svy.didWindowUpdated = true
  dispatch({ type: SINGLE_PAGE_MODE, paperModel: Paper,pageIndex });
  behaviorSetAtiveQuestion(dispatch, getState);
};
export const updateTree= () => (dispatch, getState) => {
  const { Paper,Main,PaperTree } = getState();
  dispatch({ type: UPDATE_TREE, paperModel: Paper, mainModel:Main,paperTreeModel:PaperTree });
};
export const toggleNeedSaveRequest = ({})=>({type:TOGGLE_NEED_SAVEREQUEST})

export const setAutoCompleteList = ({subjectList})=>({type:SET_AUTOCOMPLETELIST, subjectList})
export const togglePyeongCalcAutoUse = ({ autoUse })=>({type:TOGGLE_PYEONGCALC_AUTO_USE, autoUse})
export const setSvyResultList = ({svyResultList, ciCount})=>(dispatch, getState)=>{
  
  dispatch({type : SET_SVY_RESULT_LIST, svyResultList, ciCount, paperModel:getState().Paper, dispatcher:dispatch});
}
export const setSvyResultCheck = ({resultId, value, ciErrCdValue, hhmSn, ciOkPrcsResn}) =>({type:SET_SVY_RESULT_CHECK , resultId,value, ciErrCdValue, hhmSn, ciOkPrcsResn})
export const setNeedConfirmList = ({confirmList})=>({type:SET_NEED_CONFIRM_LIST, confirmList})

// **** 초기상태 정의
const initialState = { 
  init: false, 
  title:"",
  version:"",
  middleUrl:"",
  params:{},

  nextId:1,
  activeGroupId:undefined,
  groupTree:[],

  fixedQuestion : [],   //고정항목
  designTypeCode:[],
  themeCode:"01",
  inputLayoutCode:"01",

  leftSidebarMinWidth:30,
  leftSidebarMaxWidth:400,
  rightSidebarMinWidth:30,
  rightSidebarMaxWidth:500,

  leftSidebarWidth:300,
  rightSidebarWidth:350,

  showProgress:true,
  progress:0,
  progressTypeRequired:true,
  questionCnt : 0, //전체항목 수
  completedQuestionCnt : 0, //완료된 항목 수

  multiSurveyTableInfo:[],    //복수조사 정보 
  disallowSave: false,        //저장버튼 비활성화 

  inputReviewScript:{
    serverData:undefined,
    reviewResult:{},
  },   //입력내검 스크립트 정보 
  inputReviewSQL:{
    serverData:undefined,
  },
  houseHoldInfo:{       //입력화면설정(가구정보)
    serverData:undefined,
  },
  callback:{},
  externalStatistics:{
    enable:false,
    lastId:undefined,
  }, //파라데이터 사용여부 
  singleQuestionMode:{
    mobile:false,
    desktop:false, 
  },     //단일항목모드
  singleQuestionPageIndex:0,        //단일항목 페이지번호
  singleQuestionPageArray:[],  //단일항목 페이지 아이디 array
  singleQuestionDisplayType: 0 ,  //단일항목 표현 방식 0 - 1레벨기준으로, 1 -  모든항목기준으로
  
  translateLanguage : false,
  needSaveRequest : false,
  memberList : [], //사용자정보
  tabList : [], //농총 상단 탭 정보 
  autoCompleteList : [] ,
  lastInputItem : {}, // 가구원 조사 정보.
  pyeongCalcAutoUse : true,
  device : '',  // ecsa, ecsp등등..
  svyResultList : {}, //응답점검 내검 결과리스트
  completeQuestionList : [], //답변완료된 항목(그룹)리스트 
  educationData : {}, //교육영역 주관식 자동완성 아이템정보
  readonlyMode : false, //제출완료후 보기 모드.
  svyConfirm : false, //요약표에서 수정버튼을 통해 다시 조사표로 넘어온경우 true, 기본값 false
  needConfirmList : [],
  langCd : 'ko',
  qsnSeCd : 'A01',
  rejectList : [], //이전 저장시 불응했던 항목 리스트
  fruitList : [],
  nationList : [],
  houseCountInfo :{},
  apartHouseInfo :{}
};
const getSingleQuestionPageArray = (mainModel, paperModel,paperTreeModel) =>{
  const treeLeafArray = [];
  let treeIndex=0;
  
  if( mainModel.singleQuestionDisplayType ==0 ){
    const _setLeaf = ({target,path,indexUp}) =>{
      const cpath = _.cloneDeep(path);
      cpath.push(target.key)
      const item = paperModel.itemMap[target.key];
      if(item.type!=1){
        treeLeafArray.push({
          treeIndex,
          id:target.key,
          path:cpath
        });
      }
      if(indexUp){
        treeIndex++;
      }
      if(_.isArray(target.children)&&target.children.length>0){
        target.children.forEach(tree=>{  
          _setLeaf({target:tree,path:cpath,indexUp:false});
        });

      }
    }
    paperTreeModel.tree.forEach((data)=>{
      data.children.forEach(tree=>{
        
        if(paperModel.itemMap[tree.key].common.visible!=false){
          _setLeaf({target:tree,path:[],indexUp:false});
          treeIndex++
        }
      });
    });
  }else{
    const _setLeaf = ({target,path,indexUp}) =>{
      const cpath = _.cloneDeep(path);
      cpath.push(target.key)
      const item = paperModel.itemMap[target.key];
      if(item.type!=1){
        treeLeafArray.push({
          treeIndex,
          id:target.key,
          path:cpath
        });
      }
      if(indexUp){
        treeIndex++;
      }
      if(_.isArray(target.children)&&target.children.length>0){
        if(item.type==3){
          treeIndex--;
        }
        target.children.forEach(tree=>{
          _setLeaf({target:tree,path:cpath,indexUp:item.type!=3});
        });
        if(item.type==3){
          treeIndex++;
        }
      }
    }
    paperTreeModel.tree.forEach((data)=>{
      data.children.forEach(tree=>{
        if(paperModel.itemMap[tree.key].common.visible!=false){
          _setLeaf({target:tree,path:[],indexUp:true});
        }
      });
    });
  }

  return treeLeafArray;
}

export const getSingleQuestionList = ()=>(dispatch, getState)=>{
  const {Paper} = getState()
  const singleQuestionList = _.filter(
    Paper.tree,
    (treeLeaf) => {
      if(treeLeaf.isHiddenItem != true){

        const visible = Paper.itemMap[treeLeaf.id]?.common?.visible
        const visibleCnt = _.filter(visible, v=> v != false).length
        if(visibleCnt > 0){
          return true
        }else{
          return false
        }
      }
    },
  );
  return singleQuestionList
}

// **** 리듀서 작성
export default function reducer(state = initialState, action) {
  // console.log("action.type",action.type)
  switch (action.type) {
    case SET_INIT_DATA: {
      return {
        ...state,
        ...action.data,
      }
    }
    case INIT: {
      const {paperModel,paperTreeModel} = action;
      const fruitList = [
        {
            "rnum": 1,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "01",
            "orgCdId": "01",
            "cdNm": "감홍",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|01|감홍",
            "cdSortOrdr": 1,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "01",
            "upCdNm": "사과",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 2,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "02",
            "orgCdId": "02",
            "cdNm": "루비에스",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|02|루비에스",
            "cdSortOrdr": 2,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "01",
            "upCdNm": "사과",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 3,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "03",
            "orgCdId": "03",
            "cdNm": "미시마",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|03|선홍",
            "cdSortOrdr": 3,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "01",
            "upCdNm": "사과",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 4,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "04",
            "orgCdId": "04",
            "cdNm": "미야마(미얀마)",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|04|서머킹",
            "cdSortOrdr": 4,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "01",
            "upCdNm": "사과",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 5,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "05",
            "orgCdId": "05",
            "cdNm": "미야비(미야미)",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|05|쓰가루",
            "cdSortOrdr": 5,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "01",
            "upCdNm": "사과",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 6,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "06",
            "orgCdId": "06",
            "cdNm": "시나노골드",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|06|아리수",
            "cdSortOrdr": 6,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "01",
            "upCdNm": "사과",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 7,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "07",
            "orgCdId": "07",
            "cdNm": "쓰가루",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|07|양광",
            "cdSortOrdr": 7,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "01",
            "upCdNm": "사과",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 8,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "08",
            "orgCdId": "08",
            "cdNm": "아리수",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|08|피크닉",
            "cdSortOrdr": 8,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "01",
            "upCdNm": "사과",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 9,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "09",
            "orgCdId": "09",
            "cdNm": "양광",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|09|홍금",
            "cdSortOrdr": 9,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "01",
            "upCdNm": "사과",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 10,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "10",
            "orgCdId": "10",
            "cdNm": "홍로",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|10|홍로",
            "cdSortOrdr": 10,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "01",
            "upCdNm": "사과",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 11,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "11",
            "orgCdId": "11",
            "cdNm": "홍옥",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|11|홍옥",
            "cdSortOrdr": 11,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "01",
            "upCdNm": "사과",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 12,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "12",
            "orgCdId": "12",
            "cdNm": "홍월",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|12|홀월",
            "cdSortOrdr": 12,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "01",
            "upCdNm": "사과",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 13,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "13",
            "orgCdId": "13",
            "cdNm": "후지(부사)",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|13|후지(부사)",
            "cdSortOrdr": 13,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "01",
            "upCdNm": "사과",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 14,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "14",
            "orgCdId": "14",
            "cdNm": "기타",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|14|기타",
            "cdSortOrdr": 14,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "01",
            "upCdNm": "사과",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 15,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "15",
            "orgCdId": "15",
            "cdNm": "감천배",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|15|감천배",
            "cdSortOrdr": 2,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "03",
            "upCdNm": "배",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 16,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "16",
            "orgCdId": "16",
            "cdNm": "그린시스",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|16|그린시스",
            "cdSortOrdr": 3,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "03",
            "upCdNm": "배",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 17,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "17",
            "orgCdId": "17",
            "cdNm": "금촌추",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|17|금촌조생",
            "cdSortOrdr": 4,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "03",
            "upCdNm": "배",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 18,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "18",
            "orgCdId": "18",
            "cdNm": "만수",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|18|만풍배",
            "cdSortOrdr": 5,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "03",
            "upCdNm": "배",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 19,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "19",
            "orgCdId": "19",
            "cdNm": "만풍배",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|19|슈퍼골드",
            "cdSortOrdr": 6,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "03",
            "upCdNm": "배",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 20,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "20",
            "orgCdId": "20",
            "cdNm": "선황",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|20|신고",
            "cdSortOrdr": 7,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "03",
            "upCdNm": "배",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 21,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "21",
            "orgCdId": "21",
            "cdNm": "신고",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|21|신화",
            "cdSortOrdr": 8,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "03",
            "upCdNm": "배",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 22,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "22",
            "orgCdId": "22",
            "cdNm": "신화",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|22|원황",
            "cdSortOrdr": 9,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "03",
            "upCdNm": "배",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 23,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "23",
            "orgCdId": "23",
            "cdNm": "원황",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|23|장십랑",
            "cdSortOrdr": 10,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "03",
            "upCdNm": "배",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 24,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "24",
            "orgCdId": "24",
            "cdNm": "추황배",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|24|추황배",
            "cdSortOrdr": 11,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "03",
            "upCdNm": "배",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 25,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "25",
            "orgCdId": "25",
            "cdNm": "풍수",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|25|한아름",
            "cdSortOrdr": 12,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "03",
            "upCdNm": "배",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 26,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "26",
            "orgCdId": "26",
            "cdNm": "화산",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|26|화산",
            "cdSortOrdr": 13,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "03",
            "upCdNm": "배",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 27,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "27",
            "orgCdId": "27",
            "cdNm": "황금배",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|27|황금배",
            "cdSortOrdr": 14,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "03",
            "upCdNm": "배",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 28,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "28",
            "orgCdId": "28",
            "cdNm": "기타",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|28|기타",
            "cdSortOrdr": 15,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "03",
            "upCdNm": "배",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 29,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "29",
            "orgCdId": "29",
            "cdNm": "그레이트점보아카쓰키",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|29|그레이트점보아카쓰키",
            "cdSortOrdr": 1,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "05",
            "upCdNm": "복숭아",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 30,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "30",
            "orgCdId": "30",
            "cdNm": "기도백도",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|30|기도백도",
            "cdSortOrdr": 2,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "05",
            "upCdNm": "복숭아",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 31,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "31",
            "orgCdId": "31",
            "cdNm": "대구보",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|31|레드골드",
            "cdSortOrdr": 3,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "05",
            "upCdNm": "복숭아",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 32,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "32",
            "orgCdId": "32",
            "cdNm": "레드골드",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|32|미백",
            "cdSortOrdr": 4,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "05",
            "upCdNm": "복숭아",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 33,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "33",
            "orgCdId": "33",
            "cdNm": "미백",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|33|미백도",
            "cdSortOrdr": 5,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "05",
            "upCdNm": "복숭아",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 34,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "34",
            "orgCdId": "34",
            "cdNm": "미백도",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|34|미홍",
            "cdSortOrdr": 6,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "05",
            "upCdNm": "복숭아",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 35,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "35",
            "orgCdId": "35",
            "cdNm": "미홍",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|35|미황",
            "cdSortOrdr": 7,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "05",
            "upCdNm": "복숭아",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 36,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "36",
            "orgCdId": "36",
            "cdNm": "미황",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|36|백도",
            "cdSortOrdr": 8,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "05",
            "upCdNm": "복숭아",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 37,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "37",
            "orgCdId": "37",
            "cdNm": "백도",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|37|선골드",
            "cdSortOrdr": 9,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "05",
            "upCdNm": "복숭아",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 38,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "38",
            "orgCdId": "38",
            "cdNm": "서미골드",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|38|수미",
            "cdSortOrdr": 10,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "05",
            "upCdNm": "복숭아",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 39,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "39",
            "orgCdId": "39",
            "cdNm": "선골드",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|39|수홍",
            "cdSortOrdr": 11,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "05",
            "upCdNm": "복숭아",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 40,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "40",
            "orgCdId": "40",
            "cdNm": "신비",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|40|신비",
            "cdSortOrdr": 12,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "05",
            "upCdNm": "복숭아",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 41,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "41",
            "orgCdId": "41",
            "cdNm": "아부백도",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|41|아부백도",
            "cdSortOrdr": 13,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "05",
            "upCdNm": "복숭아",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 42,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "42",
            "orgCdId": "42",
            "cdNm": "엘빠다(엘바트)",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|42|오도로키(경봉)",
            "cdSortOrdr": 14,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "05",
            "upCdNm": "복숭아",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 43,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "43",
            "orgCdId": "43",
            "cdNm": "오도로키(경봉)",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|43|월미복숭아",
            "cdSortOrdr": 15,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "05",
            "upCdNm": "복숭아",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 44,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "44",
            "orgCdId": "44",
            "cdNm": "월봉조생",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|44|월봉조생",
            "cdSortOrdr": 16,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "05",
            "upCdNm": "복숭아",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 45,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "45",
            "orgCdId": "45",
            "cdNm": "유명",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|45|유명",
            "cdSortOrdr": 17,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "05",
            "upCdNm": "복숭아",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 46,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "46",
            "orgCdId": "46",
            "cdNm": "장택백봉",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|46|유미",
            "cdSortOrdr": 18,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "05",
            "upCdNm": "복숭아",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 47,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "47",
            "orgCdId": "47",
            "cdNm": "장호원황도",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|47|장호원황도",
            "cdSortOrdr": 19,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "05",
            "upCdNm": "복숭아",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 48,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "48",
            "orgCdId": "48",
            "cdNm": "창방조생",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|48|지요마루",
            "cdSortOrdr": 20,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "05",
            "upCdNm": "복숭아",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 49,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "49",
            "orgCdId": "49",
            "cdNm": "천중도백도",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|49|창방조생",
            "cdSortOrdr": 21,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "05",
            "upCdNm": "복숭아",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 50,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "50",
            "orgCdId": "50",
            "cdNm": "천홍",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|50|천중도백도",
            "cdSortOrdr": 22,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "05",
            "upCdNm": "복숭아",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 51,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "51",
            "orgCdId": "51",
            "cdNm": "판타지아",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|51|천홍",
            "cdSortOrdr": 23,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "05",
            "upCdNm": "복숭아",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 52,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "52",
            "orgCdId": "52",
            "cdNm": "황도1호",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|52|판타지아",
            "cdSortOrdr": 24,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "05",
            "upCdNm": "복숭아",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 53,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "53",
            "orgCdId": "53",
            "cdNm": "기타",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|53|기타",
            "cdSortOrdr": 25,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "05",
            "upCdNm": "복숭아",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 54,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "54",
            "orgCdId": "54",
            "cdNm": "감풍",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|54|감풍",
            "cdSortOrdr": 1,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "07",
            "upCdNm": "단감",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 55,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "55",
            "orgCdId": "55",
            "cdNm": "부유",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|55|부유",
            "cdSortOrdr": 2,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "07",
            "upCdNm": "단감",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 56,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "56",
            "orgCdId": "56",
            "cdNm": "서촌조생",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|56|서촌조생",
            "cdSortOrdr": 3,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "07",
            "upCdNm": "단감",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 57,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "57",
            "orgCdId": "57",
            "cdNm": "차랑",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|57|조완",
            "cdSortOrdr": 4,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "07",
            "upCdNm": "단감",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 58,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "58",
            "orgCdId": "58",
            "cdNm": "태추",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|58|차랑",
            "cdSortOrdr": 5,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "07",
            "upCdNm": "단감",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 59,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "59",
            "orgCdId": "59",
            "cdNm": "기타",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|59|기타",
            "cdSortOrdr": 6,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "07",
            "upCdNm": "단감",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 60,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "60",
            "orgCdId": "60",
            "cdNm": "거봉",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|60|거봉",
            "cdSortOrdr": 1,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "09",
            "upCdNm": "포도",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 61,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "61",
            "orgCdId": "61",
            "cdNm": "델라웨어",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|61|델라웨어",
            "cdSortOrdr": 2,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "09",
            "upCdNm": "포도",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 62,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "62",
            "orgCdId": "62",
            "cdNm": "마이하트",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|62|머스캣베리에이",
            "cdSortOrdr": 3,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "09",
            "upCdNm": "포도",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 63,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "63",
            "orgCdId": "63",
            "cdNm": "머스켓베리에이",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|63|새단",
            "cdSortOrdr": 4,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "09",
            "upCdNm": "포도",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 64,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "64",
            "orgCdId": "64",
            "cdNm": "바이올렛킹",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|64|샤인머스캣",
            "cdSortOrdr": 5,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "09",
            "upCdNm": "포도",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 65,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "65",
            "orgCdId": "65",
            "cdNm": "블랙사파이어",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|65|청수",
            "cdSortOrdr": 6,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "09",
            "upCdNm": "포도",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 66,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "66",
            "orgCdId": "66",
            "cdNm": "비에키시들리스(BK시들리스)",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|66|캠벨얼리",
            "cdSortOrdr": 7,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "09",
            "upCdNm": "포도",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 67,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "67",
            "orgCdId": "67",
            "cdNm": "새단",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|67|홍주시들리스",
            "cdSortOrdr": 8,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "09",
            "upCdNm": "포도",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 68,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "68",
            "orgCdId": "68",
            "cdNm": "샤인머스켓",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|68|흑구슬",
            "cdSortOrdr": 9,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "09",
            "upCdNm": "포도",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 69,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "69",
            "orgCdId": "69",
            "cdNm": "코튼캔디(캔디하트)",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|69|흑보석",
            "cdSortOrdr": 10,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "09",
            "upCdNm": "포도",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 70,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "70",
            "orgCdId": "70",
            "cdNm": "기타",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|70|기타",
            "cdSortOrdr": 11,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "09",
            "upCdNm": "포도",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 71,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "71",
            "orgCdId": "71",
            "cdNm": "감평(레드향)",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|71|감평",
            "cdSortOrdr": 1,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "11",
            "upCdNm": "감귤",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 72,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "72",
            "orgCdId": "72",
            "cdNm": "궁본조생(미야모토)",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|72|궁본조생(미야모토)",
            "cdSortOrdr": 2,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "11",
            "upCdNm": "감귤",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 73,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "73",
            "orgCdId": "73",
            "cdNm": "궁천조생",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|73|궁천조생",
            "cdSortOrdr": 3,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "11",
            "upCdNm": "감귤",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 74,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "74",
            "orgCdId": "74",
            "cdNm": "남진해(카라향)",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|74|남진해",
            "cdSortOrdr": 4,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "11",
            "upCdNm": "감귤",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 75,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "75",
            "orgCdId": "75",
            "cdNm": "다원조생",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|75|다원조생",
            "cdSortOrdr": 5,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "11",
            "upCdNm": "감귤",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 76,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "76",
            "orgCdId": "76",
            "cdNm": "베니마돈나(황금향)",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|76|베니마돈나",
            "cdSortOrdr": 6,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "11",
            "upCdNm": "감귤",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 77,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "77",
            "orgCdId": "77",
            "cdNm": "부지화(한라봉)",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|77|부지화(한라봉)",
            "cdSortOrdr": 7,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "11",
            "upCdNm": "감귤",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 78,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "78",
            "orgCdId": "78",
            "cdNm": "상도조생",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|78|상도조생",
            "cdSortOrdr": 8,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "11",
            "upCdNm": "감귤",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 79,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "79",
            "orgCdId": "79",
            "cdNm": "성전온주(성전귤)",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|79|유라실생(YN-26)",
            "cdSortOrdr": 9,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "11",
            "upCdNm": "감귤",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 80,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "80",
            "orgCdId": "80",
            "cdNm": "오하라베니와세(루비향)",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|80|유라조생",
            "cdSortOrdr": 10,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "11",
            "upCdNm": "감귤",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 81,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "81",
            "orgCdId": "81",
            "cdNm": "유라실생(YN-26)",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|81|일남1호",
            "cdSortOrdr": 11,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "11",
            "upCdNm": "감귤",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 82,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "82",
            "orgCdId": "82",
            "cdNm": "유라조생",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|82|진지향",
            "cdSortOrdr": 12,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "11",
            "upCdNm": "감귤",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 83,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "83",
            "orgCdId": "83",
            "cdNm": "일남1호",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|83|천혜향(세토카)",
            "cdSortOrdr": 13,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "11",
            "upCdNm": "감귤",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 84,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "84",
            "orgCdId": "84",
            "cdNm": "천혜향(세토카)",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|84|청견",
            "cdSortOrdr": 14,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "11",
            "upCdNm": "감귤",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 85,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "85",
            "orgCdId": "85",
            "cdNm": "청견",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|85|탐도1호",
            "cdSortOrdr": 15,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "11",
            "upCdNm": "감귤",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 86,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "86",
            "orgCdId": "86",
            "cdNm": "하례조생",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|86|하례조생",
            "cdSortOrdr": 16,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "11",
            "upCdNm": "감귤",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 87,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "87",
            "orgCdId": "87",
            "cdNm": "홍진조생",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|87|홍진조생",
            "cdSortOrdr": 17,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "11",
            "upCdNm": "감귤",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        },
        {
            "rnum": 88,
            "exmnId": "2003",
            "cdGroupId": "FTR_SPCIES_CD",
            "cdId": "88",
            "orgCdId": "88",
            "cdNm": "기타",
            "cdDc": "FTR_SPCIES_CD|과수품종코드|88|기타",
            "cdSortOrdr": 18,
            "upCdGroupId": "KNDF_CD",
            "upCdGroupNm": "과수품종코드",
            "upCdId": "11",
            "upCdNm": "감귤",
            "adiInfoChrctrCn1": null,
            "adiInfoChrctrCn2": null,
            "adiInfoChrctrCn3": null,
            "adiInfoChrctrCn4": null,
            "adiInfoChrctrCn5": null,
            "adiInfoChrctrCn6": null,
            "adiInfoChrctrCn7": null,
            "adiInfoChrctrCn8": null,
            "adiInfoChrctrCn9": null,
            "adiInfoChrctrCn10": null,
            "adiInfoNumberValue1": null,
            "adiInfoNumberValue2": null,
            "adiInfoNumberValue3": null,
            "adiInfoNumberValue4": null,
            "adiInfoNumberValue5": null,
            "adiInfoNumberValue6": null,
            "adiInfoNumberValue7": null,
            "adiInfoNumberValue8": null,
            "adiInfoNumberValue9": null,
            "adiInfoNumberValue10": null,
            "useYn": "Y"
        }
      ]
      const nationList = [
        {
          "cd": "GH",
          "nm": "가나(Ghana)",
          "odr": 1
        },
        {
          "cd": "GA",
          "nm": "가봉(Gabon)",
          "odr": 2
        },
        {
          "cd": "GY",
          "nm": "가이아나(Guyana)",
          "odr": 3
        },
        {
          "cd": "GM",
          "nm": "감비아(Gambia)",
          "odr": 4
        },
        {
          "cd": "GG",
          "nm": "건지(Bailiwick of Guernsey)",
          "odr": 5
        },
        {
          "cd": "GP",
          "nm": "과들루프(Guadeloupe)",
          "odr": 6
        },
        {
          "cd": "GT",
          "nm": "과테말라(Guatemala)",
          "odr": 7
        },
        {
          "cd": "GU",
          "nm": "괌(Guam)",
          "odr": 8
        },
        {
          "cd": "GD",
          "nm": "그레나다(Grenada)",
          "odr": 9
        },
        {
          "cd": "GR",
          "nm": "그리스(Greece)",
          "odr": 10
        },
        {
          "cd": "GL",
          "nm": "그린란드(Greenland)",
          "odr": 11
        },
        {
          "cd": "GN",
          "nm": "기니(Guinea)",
          "odr": 12
        },
        {
          "cd": "GW",
          "nm": "기니비사우(Guinea-Bissau)",
          "odr": 13
        },
        {
          "cd": "NA",
          "nm": "나미비아(Namibia)",
          "odr": 14
        },
        {
          "cd": "NR",
          "nm": "나우루(Nauru)",
          "odr": 15
        },
        {
          "cd": "NG",
          "nm": "나이지리아(Nigeria)",
          "odr": 16
        },
        {
          "cd": "SS",
          "nm": "남수단(South Sudan)",
          "odr": 17
        },
        {
          "cd": "ZA",
          "nm": "남아프리카공화국(South Africa)",
          "odr": 18
        },
        {
          "cd": "NL",
          "nm": "네덜란드(Netherlands)",
          "odr": 19
        },
        {
          "cd": "AN",
          "nm": "네덜란드령 안틸레스(Netherlands Antilles)",
          "odr": 20
        },
        {
          "cd": "NP",
          "nm": "네팔(Nepal)",
          "odr": 21
        },
        {
          "cd": "NO",
          "nm": "노르웨이(Norway)",
          "odr": 22
        },
        {
          "cd": "NC",
          "nm": "뉴 칼레도니아(New Caledonia)",
          "odr": 23
        },
        {
          "cd": "NZ",
          "nm": "뉴질랜드(New Zealand)",
          "odr": 24
        },
        {
          "cd": "NU",
          "nm": "니우에(Niue)",
          "odr": 25
        },
        {
          "cd": "NE",
          "nm": "니제르(Niger)",
          "odr": 26
        },
        {
          "cd": "NI",
          "nm": "니카라과(Nicaragua)",
          "odr": 27
        },
        {
          "cd": "DK",
          "nm": "덴마크(Denmark)",
          "odr": 28
        },
        {
          "cd": "DO",
          "nm": "도미니카공화국(Dominican Republic)",
          "odr": 29
        },
        {
          "cd": "DM",
          "nm": "도미니카연방(Dominica)",
          "odr": 30
        },
        {
          "cd": "DE",
          "nm": "독일(Germany)",
          "odr": 31
        },
        {
          "cd": "TL",
          "nm": "동티모르(Timor-Leste)",
          "odr": 32
        },
        {
          "cd": "LA",
          "nm": "라오스(Laos)",
          "odr": 33
        },
        {
          "cd": "LR",
          "nm": "라이베리아(Liberia)",
          "odr": 34
        },
        {
          "cd": "LV",
          "nm": "라트비아(Latvia)",
          "odr": 35
        },
        {
          "cd": "RU",
          "nm": "러시아(Russia)",
          "odr": 36
        },
        {
          "cd": "LB",
          "nm": "레바논(Lebanon)",
          "odr": 37
        },
        {
          "cd": "LS",
          "nm": "레소토(Lesotho)",
          "odr": 38
        },
        {
          "cd": "RE",
          "nm": "레위니옹(R.union)",
          "odr": 39
        },
        {
          "cd": "RO",
          "nm": "루마니아(Romania)",
          "odr": 40
        },
        {
          "cd": "LU",
          "nm": "룩셈부르크(Luxembourg)",
          "odr": 41
        },
        {
          "cd": "RW",
          "nm": "르완다(Rwanda)",
          "odr": 42
        },
        {
          "cd": "LY",
          "nm": "리비아(Libya)",
          "odr": 43
        },
        {
          "cd": "LT",
          "nm": "리투아니아(Lithuania)",
          "odr": 44
        },
        {
          "cd": "LI",
          "nm": "리히텐슈타인(Liechtenstein)",
          "odr": 45
        },
        {
          "cd": "MG",
          "nm": "마다가스카르(Madagascar)",
          "odr": 46
        },
        {
          "cd": "MQ",
          "nm": "마르티니크(Martinique)",
          "odr": 47
        },
        {
          "cd": "MH",
          "nm": "마셜제도(Marshall Islands)",
          "odr": 48
        },
        {
          "cd": "YT",
          "nm": "마요트(Mayotte)",
          "odr": 49
        },
        {
          "cd": "FM",
          "nm": "마이크로네시아연방(Federated States of Micronesia)",
          "odr": 50
        },
        {
          "cd": "MW",
          "nm": "말라위(Malawi)",
          "odr": 51
        },
        {
          "cd": "MY",
          "nm": "말레이시아(Malaysia)",
          "odr": 52
        },
        {
          "cd": "ML",
          "nm": "말리(Mali)",
          "odr": 53
        },
        {
          "cd": "IM",
          "nm": "맨 섬(Isle of Man)",
          "odr": 54
        },
        {
          "cd": "MX",
          "nm": "멕시코(Mexico)",
          "odr": 55
        },
        {
          "cd": "MC",
          "nm": "모나코(Monaco)",
          "odr": 56
        },
        {
          "cd": "MA",
          "nm": "모로코(Morocco)",
          "odr": 57
        },
        {
          "cd": "MU",
          "nm": "모리셔스(Mauritius)",
          "odr": 58
        },
        {
          "cd": "MR",
          "nm": "모리타니아(Mauritania)",
          "odr": 59
        },
        {
          "cd": "MZ",
          "nm": "모잠비크(Mazambique)",
          "odr": 60
        },
        {
          "cd": "ME",
          "nm": "몬테네그로(Montenegro)",
          "odr": 61
        },
        {
          "cd": "MS",
          "nm": "몬트세랫(Montserrat)",
          "odr": 62
        },
        {
          "cd": "MD",
          "nm": "몰도바(Moldova)",
          "odr": 63
        },
        {
          "cd": "MV",
          "nm": "몰디브(Maldives)",
          "odr": 64
        },
        {
          "cd": "MT",
          "nm": "몰타(Malta)",
          "odr": 65
        },
        {
          "cd": "MN",
          "nm": "몽골(Mongolia)",
          "odr": 66
        },
        {
          "cd": "US",
          "nm": "미국(United States of America)",
          "odr": 67
        },
        {
          "cd": "MM",
          "nm": "미얀마(Myanmar)",
          "odr": 68
        },
        {
          "cd": "VU",
          "nm": "바누아투(Vanuatu)",
          "odr": 69
        },
        {
          "cd": "BH",
          "nm": "바레인(Bahrain)",
          "odr": 70
        },
        {
          "cd": "BB",
          "nm": "바베이도스(Barbados)",
          "odr": 71
        },
        {
          "cd": "VA",
          "nm": "교황청(Vatican)",
          "odr": 72
        },
        {
          "cd": "BS",
          "nm": "바하마(Bahamas)",
          "odr": 73
        },
        {
          "cd": "BD",
          "nm": "방글라데시(Bangladesh)",
          "odr": 74
        },
        {
          "cd": "BM",
          "nm": "버뮤다(Bermuda)",
          "odr": 75
        },
        {
          "cd": "BJ",
          "nm": "베냉(Benin)",
          "odr": 76
        },
        {
          "cd": "VE",
          "nm": "베네수엘라(Venezuela)",
          "odr": 77
        },
        {
          "cd": "VN",
          "nm": "베트남(Vietnam)",
          "odr": 78
        },
        {
          "cd": "BE",
          "nm": "벨기에(Belgium)",
          "odr": 79
        },
        {
          "cd": "BY",
          "nm": "벨라루스(Belarus)",
          "odr": 80
        },
        {
          "cd": "BZ",
          "nm": "벨리즈(Belize)",
          "odr": 81
        },
        {
          "cd": "BA",
          "nm": "보스니아헤르체고비나(Bosnia-Herzegovina)",
          "odr": 82
        },
        {
          "cd": "BW",
          "nm": "보츠와나(Botswana)",
          "odr": 83
        },
        {
          "cd": "BO",
          "nm": "볼리비아(Bolivia)",
          "odr": 84
        },
        {
          "cd": "BI",
          "nm": "부룬디(Burundi)",
          "odr": 85
        },
        {
          "cd": "BF",
          "nm": "부르키나파소(Burkina Faso)",
          "odr": 86
        },
        {
          "cd": "BT",
          "nm": "부탄(Bhutan)",
          "odr": 87
        },
        {
          "cd": "MP",
          "nm": "북마리아나 군도(Northern Mariana Islands)",
          "odr": 88
        },
        {
          "cd": "MK",
          "nm": "북마케도니아(North Macedonia)",
          "odr": 89
        },
        {
          "cd": "BG",
          "nm": "불가리아(Bulgaria)",
          "odr": 90
        },
        {
          "cd": "BR",
          "nm": "브라질(Brazil)",
          "odr": 91
        },
        {
          "cd": "BN",
          "nm": "브루나이(Brunei)",
          "odr": 92
        },
        {
          "cd": "WS",
          "nm": "사모아(Samoa)",
          "odr": 93
        },
        {
          "cd": "SA",
          "nm": "사우디아라비아(Saudi Arabia)",
          "odr": 94
        },
        {
          "cd": "CY",
          "nm": "사이프러스(Cyprus)",
          "odr": 95
        },
        {
          "cd": "EH",
          "nm": "사하라 아랍민주공화국(서부사하라)(Sahara Arab Democratic Republic)",
          "odr": 96
        },
        {
          "cd": "SM",
          "nm": "산마리노(San Marino)",
          "odr": 97
        },
        {
          "cd": "ST",
          "nm": "상투메프린시페(S.o Tom. & Principe)",
          "odr": 98
        },
        {
          "cd": "PM",
          "nm": "생피에르·미클롱(St. Pierre and Miquelon)",
          "odr": 99
        },
        {
          "cd": "SN",
          "nm": "세네갈(Senegal)",
          "odr": 100
        },
        {
          "cd": "RS",
          "nm": "세르비아(Serbia)",
          "odr": 101
        },
        {
          "cd": "SC",
          "nm": "세이셸(Seychelles)",
          "odr": 102
        },
        {
          "cd": "LC",
          "nm": "세인트 루시아(St. Lucia)",
          "odr": 103
        },
        {
          "cd": "VC",
          "nm": "세인트 빈센트 그레나딘(St. Vincent & the Grenadines)",
          "odr": 104
        },
        {
          "cd": "KN",
          "nm": "세인트 키츠 네비스(St. Kitts-Nevis)",
          "odr": 105
        },
        {
          "cd": "SH",
          "nm": "세인트 헬레나 섬(St Helena)",
          "odr": 106
        },
        {
          "cd": "SO",
          "nm": "소말리아(Somalia)",
          "odr": 107
        },
        {
          "cd": "SB",
          "nm": "솔로몬제도(Solomon Islands)",
          "odr": 108
        },
        {
          "cd": "SD",
          "nm": "수단(Sudan)",
          "odr": 109
        },
        {
          "cd": "SR",
          "nm": "수리남(Suriname)",
          "odr": 110
        },
        {
          "cd": "LK",
          "nm": "스리랑카(Sri Lanka)",
          "odr": 111
        },
        {
          "cd": "SE",
          "nm": "스웨덴(Sweden)",
          "odr": 112
        },
        {
          "cd": "CH",
          "nm": "스위스(Swiss)",
          "odr": 113
        },
        {
          "cd": "ES",
          "nm": "스페인(Spain)",
          "odr": 114
        },
        {
          "cd": "SK",
          "nm": "슬로바키아(Slovakia)",
          "odr": 115
        },
        {
          "cd": "SI",
          "nm": "슬로베니아(Slovenia)",
          "odr": 116
        },
        {
          "cd": "SY",
          "nm": "시리아(Syria)",
          "odr": 117
        },
        {
          "cd": "SL",
          "nm": "시에라리온(Sierra Leone)",
          "odr": 118
        },
        {
          "cd": "SG",
          "nm": "싱가포르(Singapore)",
          "odr": 119
        },
        {
          "cd": "AE",
          "nm": "아랍에미리트(United Arab Emirates : UAE)",
          "odr": 120
        },
        {
          "cd": "AW",
          "nm": "아루바(Aruba)",
          "odr": 121
        },
        {
          "cd": "AM",
          "nm": "아르메니아(Armenia)",
          "odr": 122
        },
        {
          "cd": "AR",
          "nm": "아르헨티나(Argentina)",
          "odr": 123
        },
        {
          "cd": "IS",
          "nm": "아이슬란드(Iceland)",
          "odr": 124
        },
        {
          "cd": "HT",
          "nm": "아이티(Haiti)",
          "odr": 125
        },
        {
          "cd": "IE",
          "nm": "아일랜드(Ireland)",
          "odr": 126
        },
        {
          "cd": "AZ",
          "nm": "아제르바이잔(Azerbaijan)",
          "odr": 127
        },
        {
          "cd": "AF",
          "nm": "아프가니스탄(Afghanistan)",
          "odr": 128
        },
        {
          "cd": "AD",
          "nm": "안도라(Andorra)",
          "odr": 129
        },
        {
          "cd": "AL",
          "nm": "알바니아(Albania)",
          "odr": 130
        },
        {
          "cd": "DZ",
          "nm": "알제리(Algeria)",
          "odr": 131
        },
        {
          "cd": "AO",
          "nm": "앙골라(Angola)",
          "odr": 132
        },
        {
          "cd": "AG",
          "nm": "앤티가바부다(Antigua and Barbuda)",
          "odr": 133
        },
        {
          "cd": "AI",
          "nm": "앵귈라(Anguilla)",
          "odr": 134
        },
        {
          "cd": "ER",
          "nm": "에리트레아(Eritrea)",
          "odr": 135
        },
        {
          "cd": "SZ",
          "nm": "에스와티니(Eswatini)",
          "odr": 136
        },
        {
          "cd": "EE",
          "nm": "에스토니아(Estonia)",
          "odr": 137
        },
        {
          "cd": "EC",
          "nm": "에콰도르(Ecuador)",
          "odr": 138
        },
        {
          "cd": "ET",
          "nm": "에티오피아(Ethiopia)",
          "odr": 139
        },
        {
          "cd": "SV",
          "nm": "엘살바도르(El Salvador)",
          "odr": 140
        },
        {
          "cd": "GB",
          "nm": "영국(United Kingdom)",
          "odr": 141
        },
        {
          "cd": "AQ",
          "nm": "영국령 남극지역(British Antarctic Territory)",
          "odr": 142
        },
        {
          "cd": "VG",
          "nm": "영국령 버진 아일랜드(British Virgin Islands)",
          "odr": 143
        },
        {
          "cd": "IO",
          "nm": "영국령 인도양 제도(British Indian Ocean Territory)",
          "odr": 144
        },
        {
          "cd": "YE",
          "nm": "예멘(Yemen)",
          "odr": 145
        },
        {
          "cd": "OM",
          "nm": "오만(Oman)",
          "odr": 146
        },
        {
          "cd": "AT",
          "nm": "오스트리아(Austria)",
          "odr": 147
        },
        {
          "cd": "HN",
          "nm": "온두라스(Honduras)",
          "odr": 148
        },
        {
          "cd": "WF",
          "nm": "왈리스·퓌튀나(Wallis and Futuna)",
          "odr": 149
        },
        {
          "cd": "JO",
          "nm": "요르단(Jordan)",
          "odr": 150
        },
        {
          "cd": "UG",
          "nm": "우간다(Uganda)",
          "odr": 151
        },
        {
          "cd": "UY",
          "nm": "우루과이(Uruguay)",
          "odr": 152
        },
        {
          "cd": "UZ",
          "nm": "우즈베키스탄(Uzbekistan)",
          "odr": 153
        },
        {
          "cd": "UA",
          "nm": "우크라이나(Ukraine)",
          "odr": 154
        },
        {
          "cd": "IQ",
          "nm": "이라크(Iraq)",
          "odr": 155
        },
        {
          "cd": "IR",
          "nm": "이란(Iran)",
          "odr": 156
        },
        {
          "cd": "IL",
          "nm": "이스라엘(Israel)",
          "odr": 157
        },
        {
          "cd": "EG",
          "nm": "이집트(Egypt)",
          "odr": 158
        },
        {
          "cd": "IT",
          "nm": "이탈리아(Italia)",
          "odr": 159
        },
        {
          "cd": "IN",
          "nm": "인도(India)",
          "odr": 160
        },
        {
          "cd": "ID",
          "nm": "인도네시아(Indonesia)",
          "odr": 161
        },
        {
          "cd": "JP",
          "nm": "일본(Japan)",
          "odr": 162
        },
        {
          "cd": "JM",
          "nm": "자메이카(Jamaica)",
          "odr": 163
        },
        {
          "cd": "ZM",
          "nm": "잠비아(Zambia)",
          "odr": 164
        },
        {
          "cd": "JE",
          "nm": "저지(Bailiwick of Jersey)",
          "odr": 165
        },
        {
          "cd": "GQ",
          "nm": "적도기니(Equatorial Guinea)",
          "odr": 166
        },
        {
          "cd": "GE",
          "nm": "조지아(Georgia)",
          "odr": 167
        },
        {
          "cd": "CN",
          "nm": "중국(China)",
          "odr": 168
        },
        {
          "cd": "CF",
          "nm": "중앙아프리카공화국(Central African Republic)",
          "odr": 169
        },
        {
          "cd": "DJ",
          "nm": "지부티(Djibouti)",
          "odr": 170
        },
        {
          "cd": "GI",
          "nm": "지브롤터(Gibraltar)",
          "odr": 171
        },
        {
          "cd": "ZW",
          "nm": "짐바브웨(Zimbabwe)",
          "odr": 172
        },
        {
          "cd": "TD",
          "nm": "차드(Chad)",
          "odr": 173
        },
        {
          "cd": "CZ",
          "nm": "체코(Czech)",
          "odr": 174
        },
        {
          "cd": "CL",
          "nm": "칠레(Chile)",
          "odr": 175
        },
        {
          "cd": "CM",
          "nm": "카메룬(Cameroon)",
          "odr": 176
        },
        {
          "cd": "CV",
          "nm": "카보베르데(Cape Verde)",
          "odr": 177
        },
        {
          "cd": "KZ",
          "nm": "카자흐스탄(Kazakhstan)",
          "odr": 178
        },
        {
          "cd": "QA",
          "nm": "카타르(Qatar)",
          "odr": 179
        },
        {
          "cd": "KH",
          "nm": "캄보디아(Cambodia)",
          "odr": 180
        },
        {
          "cd": "CA",
          "nm": "캐나다(Canada)",
          "odr": 181
        },
        {
          "cd": "KE",
          "nm": "케냐(Kenya)",
          "odr": 182
        },
        {
          "cd": "KY",
          "nm": "케이맨제도(Cayman Islands)",
          "odr": 183
        },
        {
          "cd": "MO",
          "nm": "마카오(Macao)",
          "odr": 184
        },
        {
          "cd": "KM",
          "nm": "코모로(Comoros)",
          "odr": 185
        },
        {
          "cd": "XK",
          "nm": "코소보(Kosovo)",
          "odr": 186
        },
        {
          "cd": "CR",
          "nm": "코스타리카(Costa Rica)",
          "odr": 187
        },
        {
          "cd": "CI",
          "nm": "코트디부아르(C.te D'Ivoire)",
          "odr": 188
        },
        {
          "cd": "CO",
          "nm": "콜롬비아(Colombia)",
          "odr": 189
        },
        {
          "cd": "CG",
          "nm": "콩고공화국(Congo)",
          "odr": 190
        },
        {
          "cd": "CD",
          "nm": "콩고민주공화국(DR콩고)(Democratic Republic of Congo)",
          "odr": 191
        },
        {
          "cd": "CU",
          "nm": "쿠바(Cuba)",
          "odr": 192
        },
        {
          "cd": "KW",
          "nm": "쿠웨이트(Kuwait)",
          "odr": 193
        },
        {
          "cd": "CK",
          "nm": "쿡제도(Cook Islands)",
          "odr": 194
        },
        {
          "cd": "HR",
          "nm": "크로아티아(Croatia)",
          "odr": 195
        },
        {
          "cd": "KG",
          "nm": "키르기즈(Kyrgyz)",
          "odr": 196
        },
        {
          "cd": "KI",
          "nm": "키리바시(Kiribati)",
          "odr": 197
        },
        {
          "cd": "TW",
          "nm": "타이완(Taiwan)",
          "odr": 198
        },
        {
          "cd": "TJ",
          "nm": "타지키스탄(Tajikistan)",
          "odr": 199
        },
        {
          "cd": "TZ",
          "nm": "탄자니아(Tanzania)",
          "odr": 200
        },
        {
          "cd": "TH",
          "nm": "태국(Thailand)",
          "odr": 201
        },
        {
          "cd": "TC",
          "nm": "터크스·케이커스 제도(Turks and Caicos Islands)",
          "odr": 202
        },
        {
          "cd": "TR",
          "nm": "튀르키예(Turkey)",
          "odr": 203
        },
        {
          "cd": "TG",
          "nm": "토고(Togo)",
          "odr": 204
        },
        {
          "cd": "TO",
          "nm": "통가(Tonga)",
          "odr": 205
        },
        {
          "cd": "TM",
          "nm": "투르크메니스탄(Turkmenistan)",
          "odr": 206
        },
        {
          "cd": "TV",
          "nm": "투발루(Tuvalu)",
          "odr": 207
        },
        {
          "cd": "TN",
          "nm": "튀니지(Tunisia)",
          "odr": 208
        },
        {
          "cd": "TT",
          "nm": "트리니다드토바고(Trinidad & Tobago)",
          "odr": 209
        },
        {
          "cd": "PA",
          "nm": "파나마(Panama)",
          "odr": 210
        },
        {
          "cd": "PY",
          "nm": "파라과이(Paraguay)",
          "odr": 211
        },
        {
          "cd": "PK",
          "nm": "파키스탄(Pakistan)",
          "odr": 212
        },
        {
          "cd": "PG",
          "nm": "파푸아뉴기니(Papua New Guinea :PNG)",
          "odr": 213
        },
        {
          "cd": "PW",
          "nm": "팔라우(Palau)",
          "odr": 214
        },
        {
          "cd": "PS",
          "nm": "팔레스타인(Palestine)",
          "odr": 215
        },
        {
          "cd": "PE",
          "nm": "페루(Peru)",
          "odr": 216
        },
        {
          "cd": "PT",
          "nm": "포르투갈(Portugal)",
          "odr": 217
        },
        {
          "cd": "PL",
          "nm": "폴란드(Poland)",
          "odr": 218
        },
        {
          "cd": "FR",
          "nm": "프랑스(France)",
          "odr": 219
        },
        {
          "cd": "GF",
          "nm": "프랑스령 기아나(French Guiana)",
          "odr": 220
        },
        {
          "cd": "PF",
          "nm": "프랑스령 폴리네시아(French Polynesia)",
          "odr": 221
        },
        {
          "cd": "FJ",
          "nm": "피지(Fiji)",
          "odr": 222
        },
        {
          "cd": "FI",
          "nm": "핀란드(Finland)",
          "odr": 223
        },
        {
          "cd": "PH",
          "nm": "필리핀(Philippines)",
          "odr": 224
        },
        {
          "cd": "PN",
          "nm": "핏케언 섬(Pitcairn Islands)",
          "odr": 225
        },
        {
          "cd": "HU",
          "nm": "헝가리(Hungary)",
          "odr": 226
        },
        {
          "cd": "AU",
          "nm": "호주(Australia)",
          "odr": 227
        },
        {
          "cd": "PR",
          "nm": "푸에르토리코(Puerto Rico)",
          "odr": 228
        },
        {
          "cd": "HK",
          "nm": "홍콩(Hongkong)",
          "odr": 229
        },
        {
          "cd": "KC",
          "nm": "중국(한국계)(Korean-Chinese)",
          "odr": 230
        }
      ]
      return {
        ...state,
        init:true,
        title : action.title,
        version : action.version,
        middleUrl : action.middleUrl,
        params : action.params,
        activeGroupId : action.activeGroupId,
        educationData : action.educationData,
        rejectList : action.rejectList,
        singleQuestionPageArray:getSingleQuestionPageArray(state, paperModel,paperTreeModel),
        fruitList : state.device == 'ecsa'|| state.device == 'ecpa' ? fruitList : [],
        nationList : state.device != 'ecsa' && state.device != 'ecpa' ? nationList : []
      };
    }
    case SET_INPUT_REVIEW_SCRIPT: {
      return {
        ...state,
        inputReviewScript : {
          serverData : action.data,
          reviewResult:{},
        }, 
      };
    }
    case ADD_INPUT_REVIEW_SCRIPT_RESULT: {
      return {
        ...state,
        inputReviewScript:{
          ...state.inputReviewScript,
          reviewResult:{
            ...state.inputReviewScript.reviewResult,
            ...action.resultList,
          },
        }

      };
    }
    case SET_INPUT_REVIEW_SQL: {
      return {
        ...state,
        inputReviewSQL : {
          serverData : action.data,
        }, 
      };
    }
    case SET_HOUSE_HOLD_INFO: {
      return {
        ...state,
        houseHoldInfo : {
          serverData : action.data,
        }, 
      };
    }
    case SET_SIDEBAR_WIDTH: {
      
      let leftSidebarWidth = state.leftSidebarWidth
      let rightSidebarWidth = state.rightSidebarWidth
      if( action.position == "left" ){
        if( action.width >= state.leftSidebarMinWidth && action.width <= state.leftSidebarMaxWidth){
          leftSidebarWidth = action.width
        }
      }else if( action.position == "right" ){
        if( action.width >= state.rightSidebarMinWidth && action.width <= state.rightSidebarMaxWidth){
          rightSidebarWidth = action.width
        }
      }

      return {
        ...state,
        leftSidebarWidth,
        rightSidebarWidth,
      };
    }
    case ADD_GROUP_TREE_DATA:{
      const groupTree = [...state.groupTree]
      const nextId = ("t"+state.nextId)

      groupTree.push({
        key:nextId,
        title:action.title,
        children:[],
      })

      return {
        ...state,
        groupTree:groupTree,
        nextId: state.nextId + 1,
      }
    }
    case ADD_GROUP_TREE_ITEM:{
      const groupTree = [...state.groupTree]
      
      const parent = _.find(groupTree,{key:action.parentId})

    
      // console.log(action.paperModel.itemMap[action.id])
      parent.children.push({
        key:action.id,
        title:`${action.paperModel.itemMap[action.id].common.index}. ${action.paperModel.itemMap[action.id].common.title}`,
        isLeaf:true,
        parentId:action.parentId,
      })

      return {
        ...state,
        groupTree:groupTree,
      }
    }
    case DELETE_GROUP_TREE_DATA:{
      let groupTree = [...state.groupTree]
      
      groupTree.splice(_.findIndex(groupTree, {id:action.id}),1)
      // console.log("groupTree",groupTree)

      return {
        ...state,
        groupTree:groupTree,
      }
    }
    case DELETE_ALL_GROUP_TREE_DATA:{
      
      return {
        ...state,
        groupTree:[],
      }
    }
    case DELETE_GROUP_TREE_ITEM:{
      let groupTree = [...state.groupTree]
      

      const parent = groupTree[_.findIndex(groupTree, {key:action.parentId})]
      parent.children.splice(_.findIndex(parent.children,{key:action.id}))

      // console.log("groupTree",groupTree)

      return {
        ...state,
        groupTree:groupTree,
      }
    }
    case SET_ACTIVE_GROUP_ID:{
      return {
        ...state,
        activeGroupId:action.groupId,
      }
    }
    case CHANGE_GROUP_TREE_DATA_TITLE:{
      let groupTree = [...state.groupTree]
      groupTree[_.findIndex(groupTree, {key:action.id})].title = action.title
      
      return {
        ...state,
        groupTree:groupTree,
      }
    }
    case UPDATE_PROGRESS:{
      console.log("[reducer] main UPDATE_PROGRESS",action.device)
      const memberList = _.cloneDeep(state.memberList);
      const flattenNestedTree = (array, result) => {
        array.forEach(function(el){
          result.push(el);
          if(el.children) {
            
            flattenNestedTree(el.children, result);
          } else {
            
          }
        });
      }
      let completedCnt;
      let questionTotalCnt; 
      let fixedQuestionList = [];
      if(action.device == 'ecsa' || action.device == 'ecpa'){
        state.groupTree[0].children.map(child=>{
          _.find(action.paperModel.tree, {id : child.key}).children.map(treeChild=>{
            if(treeChild.id != '2200020210DV0001170' && treeChild.id != "2200020601DV0005790"){
              fixedQuestionList.push(treeChild.id)
            }
          })
        })
      }
        const getAnswerCompletePercent = ({itemMap, array, progressTypeRequired, }) => {
        let emptyGroupCnt = 0;
        // 1레벨 그룹 하나하나 돌면서 그룹의 자식항목들 리스트로 받아서 해당 자식들의 답변이 완료되면 count +1 해서 완료문항으로.
        const paperTree = _.filter(array, tree=> {
          const group = itemMap[tree.id]
          if(tree.isHiddenItem == true || group.common.visible == false || (group.common.disable == true && !'useRejectBtn' in group.common) || group.common.includeProgress == false){
            return false
          } else{
            return true
          }
        }) 
        let completeGroupList = _.filter(paperTree, group=>{
          let groupChildren = []
          flattenNestedTree(group.children, groupChildren)
          const children = _.filter(groupChildren, groupChild=> { 
            let repeatTableData = !_.isEmpty(_.find(itemMap, {id : groupChild.id})?.repeatTableId) ? itemMap[_.find(itemMap, {id : groupChild.id}).repeatTableId] : {}
            const childItem = itemMap[groupChild.id]
            let educationChoice = !_.isEmpty(state.educationData ) ? state.educationData.common.education.choice : ''
            if( //교육영역 주관식 자동완성 예외처리
                childItem.common.disable == true 
                || childItem.id != educationChoice &&  childItem.common.visible == false 
                || childItem.type == 1
                || childItem.type == 3
                || !_.isEmpty(repeatTableData) && repeatTableData.common.tableType == '2' 
                || (childItem.questionType == '1' && _.filter(childItem.saveKeys, saveKey=> itemMap[saveKey]?.common?.disable == true).length ==  childItem.saveKeys.length)
                || (childItem.questionType == '1' && _.filter(childItem.saveKeys, saveKey=> itemMap[saveKey]?.common?.visible == false).length ==  childItem.saveKeys.length)
                // 주관식항목의 저장키가 여러개이면서 전부 체크박스형태이거나 한개이면서 체크박스형태일때 예외.
                // || _.filter(childItem.saveKeys, saveKey=> itemMap[saveKey]?.common?.displayType == 13).length ==  childItem.saveKeys.length
                // || _.filter(childItem.saveKeys, saveKey=> itemMap[saveKey]?.common?.displayType == 15).length ==  childItem.saveKeys.length
                || _.filter(childItem.saveKeys, saveKey=> itemMap[saveKey]?.common?.displayType == 18).length ==  childItem.saveKeys.length
              ){
              return false
            }else{
              return true
            }
          })
          // const children = []
          // getFlattenChildren(group.children, children)
          if(children.length == 0) emptyGroupCnt++;
          let completeCnt = 0;
          if(children.length > 0){
            children.map(child=>{
              if(_.indexOf(fixedQuestionList, child.id) == -1){
                //문항중심항목이 아닌 일반항목
                let saveKeyCnt = 0; 
                const itemMapData = itemMap[child.id]
                const itemMapSaveKeys = itemMapData.questionType == 1 || itemMapData.questionType == 5? _.filter(itemMapData.saveKeys, key=>{ //주관식
                  const item = itemMapData.questionType == 5 ? _.find(itemMap, {saveKeys : [key], parentId : itemMapData.id }) : itemMap[key]
                  if(item.common.disable == true || item.common.visible == false ){
                    return false
                  }else{
                    return true
                  }
                }) 
                :itemMapData.saveKeys
                itemMapSaveKeys.map(saveKey=>{
                  if(itemMapData.answerComplete[saveKey] == true){
                    saveKeyCnt++;
                  }
                })
                let checkedCnt = itemMapData.questionType == 2 && itemMapData.common.minimumAnswer == 0 ? 1 // 객관식의 최소응답개수가 0개일때. 
                : itemMapData.questionType == 2 && itemMapData.common.minimumAnswer > 0 ? itemMapData.common.minimumAnswer //객관식의 최소응답개수가 0개이상일때 
                // : itemMapData.questionType == 1 && _.filter(itemMapSaveKeys, saveKey=> itemMap[saveKey]?.common?.displayType == 13 || itemMap[saveKey]?.common?.displayType == 15).length > 0 //주관식이면서 응답형식이 13 또는 15 체크박스 원형 또는 체크박스 네모박스 인 아이템이 0개 이상일떄
                // ? _.filter(itemMapSaveKeys, saveKey=> itemMap[saveKey]?.common?.displayType != 13 || itemMap[saveKey]?.common?.displayType == 15).length //주관식이면 문항 응답형식이 위와같고 모든 문항이 체크박스형식일때.
                : itemMapData.questionType == 1 && _.filter(itemMapSaveKeys, saveKey=> itemMap[saveKey]?.common?.displayType == 18).length > 0 ? _.filter(itemMapSaveKeys, saveKey=> itemMap[saveKey]?.common?.displayType != 18).length //주관식의 코드유형인 문항의 개수가 0개이상일때 
                : itemMapSaveKeys.length
                if(checkedCnt == 0){
                  emptyGroupCnt++;
                  return false;
                }
                if(saveKeyCnt >= checkedCnt){
                  completeCnt++;
                }
              }else{
                //항목중심 항목
                let saveKeyCnt = 0;
                const itemMapData = itemMap[child.id]
                const itemMapSaveKeys = []
                itemMapData.saveKeys.map(saveKey=>{
                  memberList.map(member=>{
                    itemMapSaveKeys.push(`${member.HHM_SN}_${saveKey}`)
                  })
                })
                itemMapSaveKeys.map(saveKey=>{
                  if(itemMapData.answerComplete[saveKey] == true){
                    saveKeyCnt++;
                  }
                })
                let checkedCnt = itemMapSaveKeys.length
                
                if(saveKeyCnt >= checkedCnt){
                  completeCnt++; 
                }
              }
  
            })
          }
          if(children.length > 0 && completeCnt == children.length){
            return true
          }else{
            return false
          }
        })
        // console.log("🚀 ~ file: Main.js:825 ~ completeGroupList ~ completeGroupList:", completeGroupList, paperTree)
        
        
        const lv1GroupCount = paperTree.length - emptyGroupCnt;
        questionTotalCnt = lv1GroupCount;
        completedCnt = completeGroupList.length
        const result = {
          "answerCompletePercent" : lv1GroupCount==0?0:Math.ceil(completeGroupList.length/ lv1GroupCount * 100) > 100 ? 100 : lv1GroupCount==0?0:Math.ceil(completeGroupList.length/ lv1GroupCount * 100),
          "completeQuestionList" : completeGroupList
        }
        return result
      
      }

      const getAnswerCompletePercentByQuestion = ({itemMap, array})=>{
        let emptyGroupCnt = 0;
        const memberList = state.memberList 
        // 1레벨 그룹 하나하나 돌면서 그룹의 자식항목들 리스트로 받아서 해당 자식들의 답변이 완료되면 count +1 해서 완료문항으로.
        const paperTree = _.filter(array, tree=> {
          const group = itemMap[tree.id]
          let groupVisible = _.filter(group.common.visible, visible => visible == false).length == memberList.length ? false :true
          let disableCount = 0
          Object.keys(group.common.disable).forEach(key=>{
            if(typeof group.common.isAnswerHold != 'object'){
              group.common.isAnswerHold = {}
            }
            if(group.common.disable[key] && group.common.isAnswerHold?.[key] != true){
              disableCount++
            }
          })
          let groupDisable = disableCount == memberList.length ?true :false
          
          if(tree.isHiddenItem == true || groupVisible == false || groupDisable == true || group.common.includeProgress == false ){
            return false
          } else{
            return true
          }
        }) 
        let completeGroupList = _.filter(paperTree, group=>{
          const groupItem = itemMap[group.id]
          let groupChildren = []
          flattenNestedTree(group.children, groupChildren)
          const children = _.filter(groupChildren, groupChild=> { 
            let repeatTableData = !_.isEmpty(_.find(itemMap, {id : groupChild.id})?.repeatTableId) ? itemMap[_.find(itemMap, {id : groupChild.id}).repeatTableId] : {}
            const childItem = itemMap[groupChild.id]
            let disableCount = 0
            Object.keys(childItem.common.disable).forEach(key=>{
              if(typeof groupItem.common.isAnswerHold != 'object'){
                groupItem.common.isAnswerHold = {}
              }
              if(childItem.common.disable[key] && groupItem.common.isAnswerHold?.[key] != true){
                disableCount++
              }
            })
            let childItemDisable = disableCount == memberList.length ? true :false
            let childItemVisible =  _.filter(childItem.common.visible, visible => visible == false).length == memberList.length ? false :true
            let educationChoice = !_.isEmpty(state.educationData ) ? state.educationData.common.education.choice : ''
            
            if( //교육영역 주관식 자동완성 예외처리
              childItemDisable == true 
                || childItem.id != educationChoice &&  childItemVisible == false 
                || childItem.type == 1
                || childItem.type == 3
                || !_.isEmpty(repeatTableData) && repeatTableData.common.tableType == '2' 
                || (childItem.questionType == '1' && _.filter(childItem.saveKeys, saveKey=> {
                  if(_.filter(itemMap[saveKey]?.common?.disable, disable=> disable == true).length == memberList.length){
                    return true
                  }else{
                    return false
                  }
                }).length ==  childItem.saveKeys.length)
                ||(childItem.questionType == '1' && _.filter(childItem.saveKeys, saveKey=> {
                  if(_.filter(itemMap[saveKey]?.common?.visible, visible=> visible == false).length == memberList.length){
                    return true
                  }else{
                    return false
                  }
                }).length ==  childItem.saveKeys.length)
                // 주관식항목의 저장키가 여러개이면서 전부 체크박스형태이거나 한개이면서 체크박스형태일때 예외.
                // || _.filter(childItem.saveKeys, saveKey=> itemMap[saveKey]?.common?.displayType == 13).length ==  childItem.saveKeys.length
                // || _.filter(childItem.saveKeys, saveKey=> itemMap[saveKey]?.common?.displayType == 15).length ==  childItem.saveKeys.length
                || _.filter(childItem.saveKeys, saveKey=> itemMap[saveKey]?.common?.displayType == 18).length ==  childItem.saveKeys.length
              ){
              return false
            }else{
              return true
            }
          })
          // const children = []
          // getFlattenChildren(group.children, children)
          if(children.length == 0) emptyGroupCnt++;
          let completeCnt = 0;
          if(children.length > 0){
            children.map(child=>{
              //문항중심항목이 아닌 일반항목
              let saveKeyCnt = 0; 
              const itemMapData = itemMap[child.id]
              const itemMapSaveKeys = itemMapData.questionType == 1 || itemMapData.questionType == 5? _.filter(itemMapData.saveKeys, key=>{ //주관식
                const item = itemMapData.questionType == 5 ? _.find(itemMap, {saveKeys : [key], parentId : itemMapData.id }) : itemMap[key]
                let disableCount = 0
                Object.keys(item.common.disable).forEach(key=>{
                  if(typeof itemMapData.common.isAnswerHold != 'object'){
                    itemMapData.common.isAnswerHold = {}
                  }
                  if(item.common.disable[key] && itemMapData.common.isAnswerHold?.[key] != true){
                    disableCount = disableCount +1
                  }
                })
                if(disableCount == _.filter(itemMap[group.id].common.visible, visible=>visible != false).length || _.filter(item.common.visible, visible=>visible == false).length == memberList.length  ){
                  return false
                }else{
                  return true
                }
              }) 
              :itemMapData.saveKeys
              itemMapSaveKeys.map(saveKey=>{
                memberList.forEach(member=>{
                  const snSaveKey = `${member.HHM_SN}_${saveKey}`
                  if(itemMapData.answerComplete?.[snSaveKey] == true){
                    saveKeyCnt++;
                  }
                })
              })
              let checkedCnt = itemMapData.questionType == 2 && itemMapData.common.minimumAnswer == 0 ? 1 // 객관식의 최소응답개수가 0개일때. 
              : itemMapData.questionType == 2 && itemMapData.common.minimumAnswer > 0 ? itemMapData.common.minimumAnswer //객관식의 최소응답개수가 0개이상일때 
              // : itemMapData.questionType == 1 && _.filter(itemMapSaveKeys, saveKey=> itemMap[saveKey]?.common?.displayType == 13 || itemMap[saveKey]?.common?.displayType == 15).length > 0 //주관식이면서 응답형식이 13 또는 15 체크박스 원형 또는 체크박스 네모박스 인 아이템이 0개 이상일떄
              // ? _.filter(itemMapSaveKeys, saveKey=> itemMap[saveKey]?.common?.displayType != 13 || itemMap[saveKey]?.common?.displayType == 15).length //주관식이면 문항 응답형식이 위와같고 모든 문항이 체크박스형식일때.
              : itemMapData.questionType == 1 && _.filter(itemMapSaveKeys, saveKey=> itemMap[saveKey]?.common?.displayType == 18).length > 0 ? _.filter(itemMapSaveKeys, saveKey=> itemMap[saveKey]?.common?.displayType != 18).length //주관식의 코드유형인 문항의 개수가 0개이상일때 
              : itemMapSaveKeys.length
              if(checkedCnt == 0){
                emptyGroupCnt++;
                return false;
              }
              let test = _.filter(memberList, member=>{
                if((itemMapData.common.disable[member.HHM_SN] == true && itemMapData.common.isAnswerHold?.[member.HHM_SN] != true) || itemMapData.common.visible[member.HHM_SN] == false){
                  return false
                }else{
                  return true
                }
              })
              if(saveKeyCnt >= (checkedCnt * _.filter(memberList, member=>{
                if((itemMapData.common.disable[member.HHM_SN] == true && itemMapData.common.isAnswerHold?.[member.HHM_SN] != true) || itemMapData.common.visible[member.HHM_SN] == false){
                  return false
                }else{
                  return true
                }
              }).length)){
                completeCnt++;
              }
              
  
            })
          }
          if(children.length > 0 && completeCnt == children.length){
            return true
          }else{
            return false
          }
        })
        // console.log("🚀 ~ file: Main.js:825 ~ completeGroupList ~ completeGroupList:", completeGroupList, paperTree)
        
        
        const lv1GroupCount = paperTree.length - emptyGroupCnt;
        questionTotalCnt = lv1GroupCount;
        completedCnt = completeGroupList.length
        // console.log("🚀 ~ getAnswerCompletePercentByQuestion ~ completeGroupList:", paperTree, completeGroupList)
        const result = {
          "answerCompletePercent" : lv1GroupCount==0?0:Math.ceil(completeGroupList.length/ lv1GroupCount * 100) > 100 ? 100 : lv1GroupCount==0?0:Math.ceil(completeGroupList.length/ lv1GroupCount * 100),
          "completeQuestionList" : completeGroupList
        }
        return result
      }

      //농총용 임시 진도율 계산식.
      const getAnswerCompletePercentForEcsaEcpa = ({itemMap, currentGroupId})=>{
        const groupTree = _.filter(action.mainModel.groupTree, group=>group.visible != false);
        questionTotalCnt = 0;
        let needCalcQuestionCount = 0;
        groupTree.map(group=>{
          let groupChildren = _.filter(group.children, child=>{
            const childItem = itemMap[child.key]
            if(_.isEmpty(childItem.common.index) || childItem.common.index.split('-')?.length > 1){
              return false
            }else{
              if(window.svy.groupList?.[group.key] == true){
                needCalcQuestionCount++;
              }
              return true
            }
          })
          questionTotalCnt = questionTotalCnt + groupChildren.length
        })
        //농총 전체 항목수.
        completedCnt = needCalcQuestionCount;
        // const currentGroup = groupTree[currentGroupId]
        const result = {
          "answerCompletePercent" : Math.ceil(completedCnt/ questionTotalCnt * 100) > 100 ? 100 : Math.ceil(needCalcQuestionCount/ questionTotalCnt * 100),
          "completeQuestionList" : []
        }
        return result
      
      }

      // saveKeys총합으로 계산 
      const {answerCompletePercent, completeQuestionList} = action.device =='ecsa' || action.device =='ecpa' || action.device =='area' ? getAnswerCompletePercentForEcsaEcpa({itemMap: action.paperModel.itemMap, currentGroupId : action.mainModel.activeGroupId}) 
                                                                                                             : (state.inputLayoutCode == "02" ? getAnswerCompletePercentByQuestion({itemMap: action.paperModel.itemMap, array: action.paperModel.tree}) 
                                                                                                             : getAnswerCompletePercent({itemMap: action.paperModel.itemMap, array: action.paperModel.tree, progressTypeRequired:state.progressTypeRequired, }))
                                                                                                             
                                                                                                             
      return {
        ...state,
        progress:answerCompletePercent,
        completedQuestionCnt : completedCnt,
        questionCnt : questionTotalCnt,
        completeQuestionList : completeQuestionList
      }
    }
    case SET_EXTERNAL_STATISTICS:{
      console.log("[reducer] main SET_EXTERNAL_STATISTICS",)
      return {
        ...state,
        externalStatistics:{
          enable:action.enable,
          lastId:undefined,
        }
      }
    }
    case CALL_EXTERNAL_STATISTICS:{
        
      return {
        ...state,
        externalStatistics:{
          ...state.externalStatistics,
          lastId:action.lastId,
        }
      }
    }
    case SINGLE_PAGE_MODE_NEXT:{
      return {
        ...state,
        singleQuestionPageIndex:state.singleQuestionPageIndex+1,
      }
    }
    case SINGLE_PAGE_MODE_PREV:{
      return {
        ...state,
        singleQuestionPageIndex: state.singleQuestionPageIndex-1,
      }
    }
    case SINGLE_PAGE_MODE:{
      let pageIndex = action.pageIndex;
      let treeLength = state.singleQuestionPageArray.length-1;
      if(pageIndex<0){
        pageIndex = 0;
      }else if(pageIndex>treeLength){
        pageIndex=treeLength;
      }
      return {
        ...state,
        singleQuestionPageIndex:pageIndex,
      }
    }
    case UPDATE_TREE:{
      const copyState = _.cloneDeep(state);
      copyState.singleQuestionPageArray = getSingleQuestionPageArray(copyState, action.paperModel,action.paperTreeModel);
      return copyState;
    }
    case TOGGLE_NEED_SAVEREQUEST : {
      return{
        ...state,
        needSaveRequest : !state.needSaveRequest
      }
    }
    case CHANGE_GROUP_TREE_DATA_VISIBLE : {
      const groupTree = _.cloneDeep(state.groupTree)
      let group = _.find(groupTree, {title : action.title})
      group.visible = action.value 

      let activeGroupId = state.activeGroupId
      if(activeGroupId == group.key  && action.value == false){//현재 보여지는 그룹을 숨기면 첫번째 그룹으로 이동처리 
        activeGroupId = _.filter(groupTree, group=> group.visible != false)[0].key
      }
      return{
        ...state,
        groupTree, 
        activeGroupId
      }
    }
    case SET_AUTOCOMPLETELIST : {
      return{
        ...state,
        autoCompleteList : action.subjectList
      }
    }
    case TOGGLE_PYEONGCALC_AUTO_USE : {
      return {
        ...state,
        pyeongCalcAutoUse : action.autoUse
      }
    }
    case SET_SVY_RESULT_LIST : {
      let svyResultList = _.cloneDeep(action.svyResultList)
      let highlightQuestionList = []
      svyResultList.map(result=>{
        result.ciList.map(ci=>{
          //ci Ok체크박스 체크여부확인위해 속성추가.
          ci.checked = state.device == 'ecsa' || state.device == 'ecpa' || state.device == 'ecph' || state.device == 'ecth' || state.device == 'ihst' || state.device == 'area' ? false : true
        })
      })
      return {
        ...state,
        svyResultList : {
          "resultList":svyResultList,
          "ciCount" : action.ciCount,
        },
      }
    }
    case SET_SVY_RESULT_CHECK : {
      const { resultId, value, ciErrCdValue,hhmSn, ciOkPrcsResn } = action
      const svyResultList = _.cloneDeep(state.svyResultList)
      svyResultList.resultList.map(result=>{
        let ciData = hhmSn == 0 ?_.find(result.ciList, {statsIemId : resultId, ciErrCdValue : ciErrCdValue, }) : _.find(result.ciList, {statsIemId : resultId, ciErrCdValue : ciErrCdValue, hhmSn})
        if(!_.isEmpty(ciData)){
          let index = _.findIndex(result.ciList, ciData)
          if(index != -1){
            result.ciList[index].checked = value
            if(state.device == 'ecpa' || state.device == 'area'){
              result.ciList[index].ciOkPrcsResn = ciOkPrcsResn
            }
            return false
          }
        }
      })
      return {
        ...state,
        svyResultList
      }
    }
    case INIT_FALSE : {
      return {
        ...state,
        init : false
      }
    }
    case SET_NEED_CONFIRM_LIST : {
      return{
        ...state,
        needConfirmList : action.confirmList
      }
    }
    default: {
      return state;
    }
  }
}