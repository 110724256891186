import { LocalDB as db } from './LocalDB';
import { getReqList, P_HHM_IEM_LIST, A06 } from './LocalQsnInfo';
import _ from 'lodash';
import { isObj } from 'common/utils/CommonFunction';
import OfflineService from 'stores/services/OfflineService';
import api from 'common/utils/api';

const appMode = process.env.REACT_APP_MODE;
/**
 * 오프라인 저장 명부 목록
 */
export const getReqNmstList = async () => {
  let hshldNmstList = await db.hshldNmstList.where({ isSave: 'Y' }).toArray();
  return hshldNmstList;
};

/**
 * 오프라인 데이터 전송
 */
export const reqOffData = async (hshldNmstList) => {
  console.log(' * reqOffData * ');
  const reqQsnList = _.cloneDeep(getReqList());
  try {
    let oflneTrsmDmndNocs = 0;
    let reqSvyList = new Array();
    for (const hshldNmst of hshldNmstList) {
      try {
        let svyList = hshldNmst.svyList;
        if (isObj(svyList)) {
          let svySeq = 1;
          for (const svyKey of reqQsnList) {
            // 조사표 상세 정보
            let svy = svyList[svyKey];
            // 저장 유무 판단
            if (isObj(svy) && svy.isSave === 'Y') {
              // 조사표 저장 요청 건수
              oflneTrsmDmndNocs++;
              console.log(svyKey + ' => 전송시작 URL : ' + svy.requestUrl);
              // 전송 파라미터
              let user = svy.user;

              let reqParams = {
                url: svy.requestUrl,
                method: svy.method,
                data: { ...user, ...svy, isOflneTrsm: 'Y' },
              };
              // 전송
              await api(reqParams)
                .then(() => {
                  svy.isSave = 'N'; // 전송완료후
                  // 이력전송 파라미터
                  svy.oflneTrsmSn = svySeq++;
                  svy.hshldNmstId = hshldNmst.hshldNmstId;
                  svy.qsnId = svyKey;
                  reqSvyList.push(svy);
                  console.log(svyKey + ' => 전송완료 URL : ' + svy.requestUrl);
                })
                .catch((e) => {
                  svy.isSave = 'Y'; // 전송완료후
                  reqSvyList.push(svy);
                  console.log('전송중 에러발생 : ' + e);
                });
              console.log(svyKey + ' => 전송종료 step 3');
            }

            // TEST용
            // delete svyList['exmnStts'];
          }
          hshldNmst.isSave = 'N';
          console.log('오프라인 명부 UPDATE');
          await db.hshldNmstList.put(hshldNmst);
        }
      } catch (e) {
        hshldNmst.isSave = 'Y';
        await db.hshldNmstList.put(hshldNmst);
      }
    }
    if (oflneTrsmDmndNocs > 0) {
      console.log('전송 이력 저장 S');
      let reqObj = {
        oflneTrsmNmstNocs: hshldNmstList.length,
        oflneTrsmDmndNocs: oflneTrsmDmndNocs,
        data: { reqSvyList: reqSvyList },
      };
      await OfflineService.insertOflneHis(reqObj);
      console.log('android.surveyComplete("1");');
      new Function('android.surveyComplete("1");')();
    } else {
      console.log('android.surveyComplete("1");');
      new Function('android.surveyComplete("1");')();
    }
  } catch (e) {
    console.log('reqOffData 에러 발생');
    // TODO 에러 로그 전송
  }
};

/**
 * 오프라인 기본 데이터 셋팅
 */
export const setOffBaseData = async (param) => {
  console.log('setOfflineData');

  // 삭제시
  // let hshldNmst = await db.hshldNmstList.get({ hshldNmstId: '9000822700' });
  // delete hshldNmst.svyList['2200020116SD0000259'];
  // await db.hshldNmstList.put(hshldNmst);

  const startOffline = async () => {
    // 데이터 베이스 구성  dexie는 자동으로 DB업그레이드
    // Dexie.exists('A_CENSUS_DB').then(async (exists) => {
    // if (!exists) {
    await db.open();

    let exmnInfo = await db.exmnInfo.get(process.env.REACT_APP_EXMN_ID);
    // 오프라인 취약점
    await OfflineService.selectOflneData(exmnInfo).then(async (data) => {
      if (isObj(data.exmnInfo)) {
        if (!_.isEmpty(data.exmnInfo?.exmnDcElseRm)) {
          // 조사 명령어 추가
          let isDBDelete = false;
          let exmnDcElseRm = data.exmnInfo.exmnDcElseRm;

          // 삭제명령
          if (exmnDcElseRm.indexOf('allDelete') > -1) {
            isDBDelete = true;
          } else if (exmnDcElseRm.indexOf('delete') > -1) {
            if (exmnDcElseRm.indexOf(param.userId) > -1) {
              isDBDelete = true;
            }
          }
          if (isDBDelete) {
            console.log('********local database********delete********');
            await db.delete();
            await db.open();
          }
        } else {
          await db.exmnInfo.put(data.exmnInfo);
          console.log('오프라인 기준일자 적재 완료!');
        }
      }
      if (process.env.REACT_APP_PRDCTN_ID !== '220003') {
        // 성별
        if (data.hhmSexdstnCdList != null && data.hhmSexdstnCdList.length > 0) {
          await db.hhmSexdstnCdList.bulkPut(data.hhmSexdstnCdList);
          console.log('오프라인 성별 적재 완료!');
        }
        // 가구주와의 관계
        if (data.hshldrRelCdList != null && data.hshldrRelCdList.length > 0) {
          await db.hshldrRelCdList.bulkPut(data.hshldrRelCdList);
          console.log('오프라인 가구주와의 관계 적재 완료!');
        }
        // 공통 코드 적재
        // for (const cdNm of _.keys(data.commonCdList)) {
        //   data.commonCdList[cdNm]
        // }
        if (process.env.REACT_APP_PRDCTN_ID === '220001') {
          // 종이조사 사유
          if (data.cdList != null && data.cdList.length > 0) {
            await db.cdList.bulkPut(data.cdList);
            console.log('오프라인 종이조사 사유 적재 완료!');
          }
        } else if (process.env.REACT_APP_PRDCTN_ID === '220002') {
          // 가구원 등록시 국적
          if (data.nltyFrntnNtnCdList != null && data.nltyFrntnNtnCdList.length > 0) {
            await db.nltyFrntnNtnCdList.bulkPut(data.nltyFrntnNtnCdList);
            console.log('오프라인 국적 적재 완료!');
          }
          // 시군구 작물
          if (data.cropList != null && data.cropList.length > 0) {
            await db.cropList.bulkPut(data.cropList);
            console.log('오프라인 시군구 작물 적재 완료!' + data.cropList);
          }
          // 공통 코드
          if (data.commonCdList != null && data.commonCdList.length > 0) {
            console.log(data.commonCdList);
            await db.commonCdList.bulkPut(data.commonCdList);
            console.log('오프라인 농총 공통코드 적재 완료!');
          }
        }
      }
      if (data.ciRuleList != null && data.ciRuleList.length > 0) {
        console.log('오프라인 내검 적재 시작!');
        await db.ciRuleList.where({ exmnId: process.env.REACT_APP_EXMN_ID }).delete();
        await db.ciRuleList.bulkPut(data.ciRuleList);
        console.log('오프라인 내검 적재 완료!');
      }
      if (data.qsnDsgnstdrList != null && data.qsnDsgnstdrList.length > 0) {
        console.log('오프라인 조사표 설계정보 적재 시작!');
        console.log(data.qsnDsgnstdrList);
        await db.qsnDsgnstdrList.where({ exmnId: process.env.REACT_APP_EXMN_ID }).delete();
        await db.qsnDsgnstdrList.bulkPut(data.qsnDsgnstdrList);
        console.log('오프라인 내검 적재 완료!');
      }
      // let hshldrRelCdList = db.hshldrRelCdList.orderBy('cdId').keys();
    });
    // } else {}
    // });
  };
  await startOffline();
};

export const infoData = async (config) => {
  console.log('인총 응답자 조회');
  let keys = {};
  if (!_.isEmpty(config.params.hshldNmstId)) {
    keys = { hshldNmstId: config.params.hshldNmstId, isSave: 'Y' };
  } else if (!_.isEmpty(config.params.partcptnNo)) {
    keys = { partcptnNo: config.params.partcptnNo, isSave: 'Y' };
  }

  let hshldNmst = await db.hshldNmstList.get(keys);

  // let hshldNmst = await db.hshldNmstList.get({
  //   hshldNmstId: config.params.hshldNmstId,
  //   isSave: 'Y',
  // });

  let nmstInfo = {};
  if (!_.isEmpty(hshldNmst) && !_.isEmpty(hshldNmst.svyList.rspnInfo)) {
    nmstInfo = hshldNmst.svyList.rspnInfo;
    // 전화번호or휴대폰 번호
    hshldNmst.svyList.rspnInfo.cpnYn = _.isEmpty(hshldNmst.svyList.rspnInfo.rspdntTelno)
      ? 'Y'
      : 'N';
    // 진행중
    nmstInfo.exmnSttsCd = '1';
  } else {
    nmstInfo = config.params;
    // 초기값
    nmstInfo.exmnSttsCd = '0';
    nmstInfo.exmnMthdCd = '3';
  }
  return { ...nmstInfo, result: 'SUCCESS' };
};

/**
 * 인총 응답자 저장
 * @param {*} config
 * @returns
 */
export const infoUpdate = async (config) => {
  console.log('인총 응답자 저장');
  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));

  let hshldNmst = await db.hshldNmstList.get(user.hshldNmstId);
  if (_.isEmpty(hshldNmst)) {
    hshldNmst = {
      hshldNmstId: user.hshldNmstId,
      partcptnNo: user.partcptnNo,
      svyList: [],
    };
  }
  let data = JSON.parse(config.data);
  let telNo;
  if (!_.isEmpty(data.rspdntCpn)) {
    telNo = data.rspdntCpn;
  } else {
    telNo = data.rspdntTelno;
  }

  _.assign(data, user);

  let rspnInfo = {
    ...data,
    hshldNmstId: user.hshldNmstId,
    telNo: telNo,
    method: config.method,
    requestUrl: config.url,
    isSave: 'Y',
    user: user,
  };

  let svyList = hshldNmst.svyList;
  svyList.rspnInfo = rspnInfo;
  _.assign(hshldNmst.svyList, svyList);

  user.rspdntNm = rspnInfo.rspdntNm;
  // user.telNo = telNo;
  user.rspdntTelno = rspnInfo.rspdntTelno;

  sessionStorage.setItem('user', encodeURIComponent(JSON.stringify(user)));

  hshldNmst = {
    ...hshldNmst,
    svyList: svyList,
    isSave: 'Y',
  };
  await db.hshldNmstList.put(hshldNmst);
  return {
    ...user,
    result: 'SUCCESS',
    msg: '응답자 등록에 성공하였습니다.',
  };
};

/**
 * 농총 표지 조회
 * @returns
 */
export const affscoverData = async () => {
  console.log('농총 표지 조회');

  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));
  let hshldNmst = await db.hshldNmstList.get(user.hshldNmstId);
  if (_.isEmpty(hshldNmst)) {
    hshldNmst = {
      hshldNmstId: user.hshldNmstId,
      svyList: [],
    };
  }
  return { ...hshldNmst.svyList.affsCover, result: 'SUCCESS' };
};

/**
 * 농총 표지 저장
 * @param {*} config
 * @returns
 */
export const affscoverUpdate = async (config) => {
  console.log('농총 표지 저장');
  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));
  let hshldNmst = await db.hshldNmstList.get(user.hshldNmstId);
  if (_.isEmpty(hshldNmst)) {
    hshldNmst = {
      hshldNmstId: user.hshldNmstId,
      partcptnNo: user.partcptnNo,
      svyList: [],
    };
  }

  let data = JSON.parse(config.data);
  let affsCover = {
    ...data,
    method: config.method,
    requestUrl: config.url,
    isSave: 'Y',
    user: user,
  };

  let svyList = hshldNmst.svyList;
  svyList.affsCover = affsCover;
  _.assign(hshldNmst.svyList, svyList);

  hshldNmst = {
    ...hshldNmst,
    svyList: svyList,
    isSave: 'Y',
  };
  await db.hshldNmstList.put(hshldNmst);
  return {
    result: 'SUCCESS',
  };
};

/**
 * 가구원 코드 조회
 * @returns
 */
export const selectHhmCoverColData = async () => {
  /* useLiveQuery 사용 ex) */
  // const hshldrRelCdList = useLiveQuery(async () => {});
  console.log('가구원 등록 코드 조회');
  let hshldrRelCdList = await db.hshldrRelCdList.orderBy('qesitmNo').toArray();
  let hhmSexdstnCdList = await db.hhmSexdstnCdList.toArray();
  let nltyFrntnNtnCdList = await db.nltyFrntnNtnCdList.toArray();
  return {
    result: 'SUCCESS',
    hshldrRelCdList: hshldrRelCdList,
    hhmSexdstnCdList: hhmSexdstnCdList,
    nltyFrntnNtnCdList: nltyFrntnNtnCdList,
  };
};

/**
 * 가구원 조회
 * @returns
 */
export const selectHhmCoverData = async () => {
  console.log('가구원 조회');
  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));

  let hshldNmst = await db.hshldNmstList.get({ hshldNmstId: user.hshldNmstId, isSave: 'Y' });
  let hhmCover = hshldNmst?.svyList?.hhmCover;
  // 첫 조회시
  if (_.isEmpty(hhmCover)) {
    hhmCover = { hhmList: [] };
  } else {
    if (hhmCover.isSave === 'Y' || appMode === 'LOCAL') {
      hhmCover.hhmList = _.filter(hhmCover.data.answer, (hhmInfo) => hhmInfo.hhmState !== 'D');
      if (user.emtdChartrCd === '4') {
        hhmCover.fclty = hhmCover.data.fclty;
      }
    } else {
      hhmCover = { hhmList: [] };
    }
  }
  return { ...hhmCover, result: 'SUCCESS' };
};

/**
 * 가구원 저장
 * @param {*} config
 * @returns
 */
export const saveHhmCoverData = async (config) => {
  console.log('가구원 저장');
  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));
  let hshldNmst = await db.hshldNmstList.get(user.hshldNmstId);
  if (_.isEmpty(hshldNmst)) {
    hshldNmst = {
      hshldNmstId: user.hshldNmstId,
      svyList: [],
    };
  }

  // step1 카멜케이스를 스네이크 케이스로 변환하여 qsnInfodataJson의 data.hmember에 가구원 정보 셋팅
  let answer = [];
  let data = JSON.parse(config.data);
  let dataAnswerList = _.cloneDeep(data.data.answer);
  let nmstAnswerList = _.cloneDeep(hshldNmst?.svyList?.hhmCover?.data?.answer);
  let svyAnswer = [];
  let svyAttribute = [];

  // 조사표 설계 가구원 저장정보 확인
  if (!_.isEmpty(hshldNmst?.svyList[A06]?.data?.answer)) {
    svyAnswer = hshldNmst.svyList[A06].data.answer;
  }
  if (!_.isEmpty(hshldNmst?.svyList[A06]?.data?.attribute)) {
    svyAttribute = hshldNmst.svyList[A06].data.attribute;
  }

  let isDelete = false;
  // 가구원 삭제 시 D로 변경
  _.each(dataAnswerList, async (hhm) => {
    if (hhm.hhmState === 'D') {
      isDelete = true;
      _.remove(nmstAnswerList, { hhmSn: hhm.hhmSn }); // 가구원 등록
      _.remove(svyAnswer, { HHM_SN: String(hhm.hhmSn) }); // 조사표 설계 가구원
      _.remove(svyAttribute, { HHM_SN: String(hhm.hhmSn) }); // 조사표 설계 가구원

      // 남남 가구원이 있는지 체크한다.
      if (!_.isEmpty(hshldNmst?.svyList['sendOtherHhmrSms'])) {
        let namHhmList = hshldNmst.svyList['sendOtherHhmrSms'].namHhmList;
        _.remove(namHhmList, { hhmSn: Number(hhm.hhmSn) }); // 조사표 설계 가구원
        if (namHhmList.length <= 0) {
          hshldNmst.svyList['sendOtherHhmrSms'].isSave = 'N';
        }
      }
    } else {
      hhm.chk = false;
      hhm.hhmState = 'U';
    }
  });
  if (isDelete) {
    _.each(nmstAnswerList, async (hhm, i) => {
      hhm.hhmSn = i + 1;
    });
    answer = nmstAnswerList;
  } else {
    answer = dataAnswerList;
  }

  // 농총 시군구 작물
  let crops = {};
  if (process.env.REACT_APP_PRDCTN_ID === '220002') {
    let cropList = await db.cropList
      .where({
        ctpvCd: user.ctpvCd,
        sggCd: user.sggCd,
      })
      .toArray();

    let cropIdx = 1;
    for (const crop of cropList) {
      crops['SGG_CROPS_NM' + cropIdx] = crop.sggCropsNm;
      cropIdx++;
    }
  }
  let snakeCaseAnswer = _.cloneDeep(answer);
  _.each(snakeCaseAnswer, async (hhm) => {
    let keys = _.keys(hhm);
    _.each(keys, async (camelKey) => {
      let snakeKey = _.snakeCase(camelKey).toUpperCase();
      hhm[snakeKey] = hhm[camelKey];
      if ('hshldrRelCd' === camelKey) {
        let hshldrRelCdTxt = '';
        if (process.env.REACT_APP_PRDCTN_ID === '220001') {
          hshldrRelCdTxt = `CENSUS_${snakeKey}`;
        } else {
          hshldrRelCdTxt = `ACENS_${snakeKey}`;
        }
        hhm[hshldrRelCdTxt] = hhm[camelKey];
      }
      delete hhm[camelKey];
    });
    hhm['HSHLD_NMST_ID'] = user.hshldNmstId;

    // 사회시설 일경우
    if (user.emtdChartrCd === '4') {
      hhm['PARTCPTN_NO_DIV'] = 'Y';
      hhm['EMTD_CHARTR_CD'] = '4';
    }

    if (process.env.REACT_APP_PRDCTN_ID === '220002' && !_.isEmpty(crops)) {
      _.assign(hhm, crops);
    }
  });

  let saveData = {
    answer: answer,
    fclty: data.data.fclty,
    snakeCaseAnswer: snakeCaseAnswer,
  };

  let svyList = hshldNmst.svyList;
  svyList.hhmCover = {
    data: saveData,
    isSave: 'Y',
    method: config.method,
    requestUrl: config.url,
    user: user,
  };

  // 조사표 설계 가구원 저장정보 확인
  if (svyAnswer.length > 0) {
    // 설계정보 가구원 변경
    _.each(svyAnswer, async (createAnswer) => {
      let hhmCover = _.cloneDeep(_.find(answer, { hhmSn: Number(createAnswer.HHM_SN) }));
      createAnswer[P_HHM_IEM_LIST[1]] = hhmCover.hhmNm;
      createAnswer[P_HHM_IEM_LIST[2]] = hhmCover.hhmSexdstnCd;
      createAnswer[P_HHM_IEM_LIST[3]] = hhmCover.hhmBryy;
      createAnswer[P_HHM_IEM_LIST[4]] = hhmCover.hhmBrthMm;
      createAnswer[P_HHM_IEM_LIST[5]] = hhmCover.hhmBrthDay;
      createAnswer[P_HHM_IEM_LIST[6]] = hhmCover.hhmAge;
      createAnswer[P_HHM_IEM_LIST[7]] = hhmCover.hshldrRelCd;
      // _.remove(answer, { chk: true });
    });
  }

  _.assign(hshldNmst.svyList, svyList);

  hshldNmst = {
    ...hshldNmst,
    svyList: svyList,
    isSave: 'Y',
  };
  await db.hshldNmstList.put(hshldNmst);
  return {
    result: 'SUCCESS',
  };
};

/**
 * 남남 가구원 저장
 * @param {*} config
 * @returns
 */
export const sendOtherHhmrSmsData = async (config) => {
  console.log('남남 가구원 저장');
  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));
  let hshldNmst = await db.hshldNmstList.get(user.hshldNmstId);
  if (_.isEmpty(hshldNmst)) {
    hshldNmst = {
      hshldNmstId: user.hshldNmstId,
      svyList: [],
    };
  }
  let data = JSON.parse(config.data);
  let sendOtherHhmrSms = {
    ...data,
    method: config.method,
    requestUrl: config.url,
    isSave: 'Y',
    user: user,
  };

  let svyList = hshldNmst.svyList;
  svyList.sendOtherHhmrSms = sendOtherHhmrSms;
  _.assign(hshldNmst.svyList, svyList);

  hshldNmst = {
    ...hshldNmst,
    svyList: svyList,
    isSave: 'Y',
  };
  await db.hshldNmstList.put(hshldNmst);
  return {
    result: 'SUCCESS',
  };
};

/**
 * 농총 응답자 가구원 목록 조회
 * @param {*} config
 * @returns
 */
export const acensHhmList = async (config) => {
  console.log('응답자 가구원 목록 조회');
  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));
  let hshldNmst = await db.hshldNmstList.get(user.hshldNmstId);

  let hhmList = [];
  _.each(hshldNmst.svyList.hhmCover.data.answer, async (hhm) => {
    hhmList.push(hhm.hhmNm);
  });

  return { hhmList: hhmList, result: 'SUCCESS' };
};

/**
 * 농총 응답자 정보 조회
 * @param {*} config
 * @returns
 */
export const acensHhmInfo = async (config) => {
  console.log('응답자 조회');
  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));
  let hshldNmst = await db.hshldNmstList.get(user.hshldNmstId);
  if (_.isEmpty(hshldNmst?.svyList?.acensusCapiInfo)) {
    hshldNmst.svyList.acensusCapiInfo = {
      rspdntNm: null,
      rspdntCpn: null,
      atmnentRegCd: null,
      atmnentFarmngYn: null,
      atmnentFshrYn: null,
      intnetExmnIntenCd: null,
      exmnMthdCd: null,
      hshldNmstId: null,
      exmnId: null,
      appMode: null,
    };
  }

  return { ...hshldNmst.svyList.acensusCapiInfo, result: 'SUCCESS' };
};

/**
 * 농총 응답자 저장
 * @param {*} config
 * @returns
 */
export const acensCapiInfoUpdate = async (config) => {
  console.log('응답자 저장');
  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));
  let hshldNmst = await db.hshldNmstList.get(user.hshldNmstId);
  if (_.isEmpty(hshldNmst)) {
    hshldNmst = {
      hshldNmstId: user.hshldNmstId,
      svyList: [],
    };
  }
  let data = JSON.parse(config.data);
  data.cpnYn = data.selectedPhoneType === 'cell' ? 'Y' : 'N';
  let acensusCapiInfo = {
    ...data,
    method: config.method,
    requestUrl: config.url,
    isSave: 'Y',
    user: user,
  };

  let svyList = hshldNmst.svyList;
  svyList.acensusCapiInfo = acensusCapiInfo;
  _.assign(hshldNmst.svyList, svyList);

  hshldNmst = {
    ...hshldNmst,
    svyList: svyList,
    isSave: 'Y',
  };
  await db.hshldNmstList.put(hshldNmst);
  return {
    result: 'SUCCESS',
  };
};

/**'
 * 조사상태 저장
 * @param {*} config
 * @returns
 */
export const updateExmnStts = async (config) => {
  console.log('조사상태 저장');
  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));
  let hshldNmst = await db.hshldNmstList.get(user.hshldNmstId);
  if (_.isEmpty(hshldNmst)) {
    hshldNmst = {
      hshldNmstId: user.hshldNmstId,
      svyList: [],
    };
  }
  let exmnSttsCd = JSON.parse(config.data).exmnSttsCd;
  let exmnStts = {
    method: config.method,
    user: user,
    requestUrl: config.url,
    isSave: 'Y',
    exmnSttsCd: exmnSttsCd,
  };

  let svyList = hshldNmst.svyList;
  if (process.env.REACT_APP_PRDCTN_ID === '220003') {
    svyList[`exmnStts${user.qsnSeCd}`] = exmnStts;
  } else {
    svyList.exmnStts = exmnStts;
  }
  _.assign(hshldNmst.svyList, svyList);

  hshldNmst = {
    ...hshldNmst,
    svyList: svyList,
    isSave: 'Y',
  };
  await db.hshldNmstList.put(hshldNmst);
  return {
    result: 'SUCCESS',
  };
};

/**
 * 공통 코드 조회
 * @returns
 */
export const cdList = async () => {
  console.log('공통 등록 코드 조회');
  let cdList = await db.cdList.orderBy('cdSortOrdr').toArray();
  return {
    result: 'SUCCESS',
    cdList: cdList,
  };
};
