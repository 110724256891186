import {
  ADD_HHM_COVER,
  INPUT_HHM_COVER,
  DELETE_HHM_COVER,
  ADD_FCLTY_COVER,
} from '../actions/types';

const initialState = {
  values: { answer: [], fclty: {}, orgAnswer: [] },
};

/**
 * 가구원 등록 상태관리
 * @param {*} state
 * @param {*} action
 * @returns
 * @author gnsoft-lee.kh
 */
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_HHM_COVER:
      return {
        ...state,
        values: { ...state.values, answer: payload.answer },
      };
    case INPUT_HHM_COVER:
      return {
        ...state,
        values: { ...state.values, answer: payload.answer },
      };
    case DELETE_HHM_COVER:
      return {
        ...state,
        values: { ...state.values, answer: payload.answer },
      };
    case ADD_FCLTY_COVER:
      return {
        ...state,
        values: { ...state.values, answer: payload.answer, fclty: payload.fclty },
      };
    default:
      return state;
  }
}
