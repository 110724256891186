import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from 'store';
import './index.css';
import UseScrollTop from 'common/hook/useScrollTop';
// import * as serviceWorker from './serviceWorker';

import { BrowserRouter, HashRouter } from 'react-router-dom';
import './locale/i18n';

// IE9의 경우
import 'react-app-polyfill/ie9';
// IE11의 경우
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

const container = document.getElementById('root');
const root = createRoot(container);
window.svy = {
  getParams: () => {
    // var params = { partcptnNo: 'GAJ223UAA' }; // TODO CATI의 임시 참여번호 = {인총 : ABZ392HGG, 농총 : GAJ223UAA}
    var params = {};
    window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (str, key, value) {
      params[key] = value;
    });
    return params;
  },
};
console.log(`
██████╗  ██████╗ ██████╗ ███████╗
╚════██╗██╔═████╗╚════██╗██╔════╝
 █████╔╝██║██╔██║ █████╔╝███████╗
██╔═══╝ ████╔╝██║██╔═══╝ ╚════██║
███████╗╚██████╔╝███████╗███████║
╚══════╝ ╚═════╝ ╚══════╝╚══════╝
 
 ██████╗███████╗███╗   ██╗███████╗██╗   ██╗███████╗
██╔════╝██╔════╝████╗  ██║██╔════╝██║   ██║██╔════╝
██║     █████╗  ██╔██╗ ██║███████╗██║   ██║███████╗
██║     ██╔══╝  ██║╚██╗██║╚════██║██║   ██║╚════██║
╚██████╗███████╗██║ ╚████║███████║╚██████╔╝███████║
 ╚═════╝╚══════╝╚═╝  ╚═══╝╚══════╝ ╚═════╝ ╚══════╝
            WELCOME ${process.env.REACT_APP_MAIN_APP}! - Version ${process.env.REACT_APP_VERSION}(${process.env.REACT_APP_MODE})
`);
const EcspApp = lazy(() => import('view/ecsp/EcspApp')); // 인총 CASI
const EsspApp = lazy(() => import('view/essp/EsspApp')); // 인총 SASI
const EcppApp = lazy(() => import('view/ecpp/EcppApp')); // 인총 CAPI
const EctpApp = lazy(() => import('view/ectp/EctpApp')); // 인총 CATI
const EcsaApp = lazy(() => import('view/ecsa/EcsaApp')); // 농총 CASI
const EcpaApp = lazy(() => import('view/ecpa/EcpaApp')); // 농총 CAPI
const PilotApp = lazy(() => import('view/ecsp/EcspApp')); // 인총 CASI
const AreaApp = lazy(() => import('view/area/AreaApp')); // 농총 지역조사
const EcphApp = lazy(() => import('view/ecph/EcphApp')); // 인총 가기초

// const EcpaAppOffline = lazy(() => import('view/ecpa/EcpaAppOffline')); // 농총 CAPI 오프라인

const baseUrl = process.env.REACT_APP_ROUTE_BASE_URL ? process.env.REACT_APP_ROUTE_BASE_URL : '';
root.render(
  <Provider store={store}>
    {process.env.REACT_APP_MAIN_APP === 'EcpaAppOffline' && (
      <HashRouter>
        <UseScrollTop />
        <EcpaApp
          options={{
            selector: 'body',
            url: window.location.pathname,
            params: window.svy.getParams(),
          }}
        />
      </HashRouter>
    )}
    {process.env.REACT_APP_MAIN_APP === 'EcppAppOffline' && (
      <HashRouter>
        <UseScrollTop />
        <EcppApp
          options={{
            selector: 'body',
            url: window.location.pathname,
            params: window.svy.getParams(),
          }}
        />
      </HashRouter>
    )}
    {process.env.REACT_APP_MAIN_APP === 'EcphAppOffline' && (
      <HashRouter>
        <UseScrollTop />
        <EcphApp
          options={{
            selector: 'body',
            url: window.location.pathname,
            params: window.svy.getParams(),
          }}
        />
      </HashRouter>
    )}
    {(process.env.REACT_APP_MAIN_APP === 'EcspApp' ||
      process.env.REACT_APP_MAIN_APP === 'EsspApp' ||
      process.env.REACT_APP_MAIN_APP === 'EcppApp' ||
      process.env.REACT_APP_MAIN_APP === 'EctpApp' ||
      process.env.REACT_APP_MAIN_APP === 'EcsaApp' ||
      process.env.REACT_APP_MAIN_APP === 'EcpaApp' ||
      process.env.REACT_APP_MAIN_APP === 'AreaApp' ||
      process.env.REACT_APP_MAIN_APP === 'EcthApp' ||
      process.env.REACT_APP_MAIN_APP === 'EcphApp' ||
      process.env.REACT_APP_MAIN_APP === 'IhstApp') && (
      <BrowserRouter basename={baseUrl}>
        <UseScrollTop />
        {process.env.REACT_APP_MAIN_APP === 'EcspApp' && <EcspApp />}
        {process.env.REACT_APP_MAIN_APP === 'EsspApp' && <EsspApp />}
        {process.env.REACT_APP_MAIN_APP === 'EcppApp' && (
          <EcppApp
            options={{
              selector: 'body',
              url: window.location.pathname,
              params: window.svy.getParams(),
            }}
          />
        )}
        {process.env.REACT_APP_MAIN_APP === 'EctpApp' && (
          <EctpApp
            options={{
              selector: 'body',
              url: window.location.pathname,
              params: window.svy.getParams(),
            }}
          />
        )}
        {process.env.REACT_APP_MAIN_APP === 'EcsaApp' && <EcsaApp />}
        {process.env.REACT_APP_MAIN_APP === 'EcpaApp' && (
          <EcpaApp
            options={{
              selector: 'body',
              url: window.location.pathname,
              params: window.svy.getParams(),
            }}
          />
        )}
        {process.env.REACT_APP_MAIN_APP === 'PilotApp' && <PilotApp />}
        {process.env.REACT_APP_MAIN_APP === 'AreaApp' && (
          <AreaApp
            options={{
              selector: 'body',
              url: window.location.pathname,
              params: window.svy.getParams(),
            }}
          />
        )}
        {(process.env.REACT_APP_MAIN_APP === 'EcphApp' ||
          process.env.REACT_APP_MAIN_APP === 'EcthApp' ||
          process.env.REACT_APP_MAIN_APP === 'IhstApp') && (
          <EcphApp
            options={{
              selector: 'body',
              url: window.location.pathname,
              params: window.svy.getParams(),
            }}
          />
        )}
      </BrowserRouter>
    )}
  </Provider>,
);

// If you want your app to work offline and load faster, you can chađinge
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//
// serviceWorker.unregister();
