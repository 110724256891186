// 액션 타입 정의
const SET_INIT_DATA = "datasource/SET_INIT_DATA";
const SET_QUESTION_LIST = "datasource/SET_QUESTION_LIST";
// 액션 생섬함수 정의
export const setInitData = ({data, }) => ({ type: SET_INIT_DATA, data });
export const setQuestionList = ({ questionList }) => ({ type: SET_QUESTION_LIST, questionList, });

// **** 초기상태 정의
const initialState = { //통계항목에서 복제해온 데이터 관리 
  questionList:[],    //통계항목에서 복제된 원본 

};

// **** 리듀서 작성
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_INIT_DATA: {
      return action.data;
    }
    case SET_QUESTION_LIST: {
      return {
        ...state,
        questionList:action.questionList,
      };
    }
    default: {
      return state;
    }
  }
}