import _ from "lodash";
import { getInfo as styles_getInfo } from "./Styles";
//액션타입 정의
const SET_INIT_DATA = "translate/SET_INIT_DATA";
const CHANGE_DEFAULT_LANGUAGE = "translate/CHANGE_DEFAULT_LANGUAGE";


//액션 생성함수 정의
export const setInitData = ({data,currentLanguage }) => ({ type: SET_INIT_DATA, data, currentLanguage });
export const changeDefaultLanguage = ({selectedLanguage}) => ({ type: CHANGE_DEFAULT_LANGUAGE, selectedLanguage});

export const getString = ({title} ) =>(dispatch, getState) =>{
  const {Translate} = getState();
  let result = "";
  if(Translate.defaultLanguage == "ko"){
    result = title;
  }else{
    result = Translate.translateData[Translate.defaultLanguage][title]
    if(_.isEmpty(result)){
        result = title;
    }
  }
  return result;
};

export const getHtmlString = ({itemMapId, target}) => (dispatch, getState) =>{
  const {Translate} = getState();
  let result = "";
  let htmlTag = dispatch(styles_getInfo({id: itemMapId, target: target}) || {})

  if(Translate.defaultLanguage == "ko"){
    result = htmlTag.html;
  }else{
    if(htmlTag.html_translate != undefined){
      result = htmlTag.html_translate[Translate.defaultLanguage]
    }else{
      result = htmlTag.html;
    }
  }
  
  return result;
}

// ****초기상태 정의
const initialState = {
  translateData : {
  },
  // translateListForCurrentLanuage : { //번역 언어별 정렬을 위한 객체
  // },
  // korTranslateList : [""], //한글 문항목록
  currentLanguage : "", //현재 선택된 번역 언어
  defaultLanguage : "ko", //화면에 보여지는 기본 언어
  supportLanguageList : [],
  // staticDataList : [""] //고정번역대상 배열
}

// ****리듀서 작성
export default function reducer(state = initialState, action){
  switch(action.type){
    case SET_INIT_DATA: {
      const supportLanguageList= []
      const translateData = {}
      action.data.supportLanguageList.forEach(lang=>{
          let langData = _.cloneDeep(lang)
          if(_.includes(langData.code, 'trs')){
              langData.code = langData.title == '영어' ? 'en'
                              : langData.title == '영어' ? 'en'
                              : langData.title == '일본어' ? 'ja'
                              : langData.title == '중국어' ? 'zh'
                              : langData.title == '태국어' ? 'th'
                              : langData.title == '러시아어' ? 'ru'
                              : langData.title == '베트남어' ? 'vi'
                              : langData.title == '몽골어' ? 'mn'
                              : langData.title == '우즈베크어' ? 'uz'
                              : langData.title == '카자흐어' ? 'kz'
                              : langData.title == '크메르어' ? 'kh'
                              : langData.code
              delete langData[lang.code]
          }
          supportLanguageList.push(langData)
            translateData[langData.code] = {
              ...action.data.translateData[lang.code]
            }
        })

      return {
        ...state,
        ...action.data,
        supportLanguageList,
        translateData,
        defaultLanguage : action.currentLanguage,
        currentLanguage : ''
      }
    }
    case CHANGE_DEFAULT_LANGUAGE : {
      return {
        ...state,
        defaultLanguage : action.selectedLanguage
      }
    }
    default: {
      return state;
    }
  }
}