import { combineReducers } from 'redux';
import Auth from './Auth';
import Message from './Message';
import IsLoading from './IsLoading';
import Language from './Language';
import HhmCover from './HhmCover';
import Affs from './Affs';
import AcensNmstSe from './AcensNmstSe';

import Main from './Main';
import Paper from './Paper';
import PaperTree from './PaperTree';
import DataSource from './DataSource';
import Behavior from './Behavior';
import Answer from './Answer';
import Styles from './Styles';
import SubItems from './SubItems';
import Popup from './Popup';
import Translate from './Translate';
export default combineReducers({
  HhmCover,
  Affs,
  AcensNmstSe,
  Auth,
  Message,
  IsLoading,
  Language,
  Main,
  Paper,
  PaperTree,
  DataSource,
  Behavior,
  Answer,
  Styles,
  SubItems,
  Popup,
  Translate,
});
