import { useEffect, useRef } from 'react';
import _ from 'lodash';

/**
 * 문자열 분리기
 * @param str 문자열
 * @param interval 간격
 * @param spacer 사이문자
 * @returns {string} xxx-xxx-xxx
 */
export function strSplitter(str, interval, spacer) {
  const length = str.length;
  const cnt = length / interval;
  let start = 0;
  let end = interval;
  let strArr = [];
  for (let i = 0; i < cnt; i++) {
    strArr.push(str.substring(start, end));
    start = end;
    end = end + interval;
  }
  return strArr.join(spacer);
}

/**
 * 주소셋팅
 * @param user
 * @returns 주소 문자열
 */
export function addressReturner(user) {
  if (!user) return '';
  const aptArr = [
    user.aptcpxBldgNm !== null ? user.aptcpxBldgNm : '',
    user.aptcpxBddgNm !== null ? user.aptcpxBddgNm + '동' : '',
    user.aptcpxFlrNm !== null ? user.aptcpxFlrNm + '층' : '',
    user.aptcpxBdhNm !== null ? user.aptcpxBdhNm + '호' : '',
  ];
  let apt = '';
  if (aptArr.join('').length > 0) {
    apt = '(' + aptArr.join(' ') + ')';
  }

  const buildingNo = (user) => {
    let bsno = '';
    if (user.bsno) {
      bsno = user.bsno;
    }
    if (user.bsno === '0') {
      bsno = '';
    }
    if (bsno.length !== 0) {
      bsno = `-${bsno}`;
    }
    let arr = [user.bmno, bsno];
    return arr.join('');
  };

  const addressArray = [
    user.ctpvNm,
    user.sggNm,
    user.roadNm,
    buildingNo(user),
    apt !== '' ? apt : '',
  ];
  return addressArray.join(' ');
}

/**
 * React setInterval by Dan abramov
 * @param callback 인터벌 함수
 * @param delay    인터벌 간격
 * @returns        인터벌 ID (clearInterval 하기 위함)
 */
export function useInterval(callback, delay) {
  const savedCallback = useRef();
  const savedInterval = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      savedInterval.current = setInterval(tick, delay);
      return () => clearInterval(savedInterval.current);
    }
  }, [delay]);
  return savedInterval.current;
}

export function manAgeCalc(bd) {
  const birthday = new Date(bd);
  // 조사 기준일
  const exmnTrgtCrtrYmd = sessionStorage.getItem('exmnTrgtCrtrYmd');
  const today = new Date(exmnTrgtCrtrYmd);
  today.setDate(today.getDate() - 1);
  // const today = new Date(
  //   now.getFullYear() +
  //     '-' +
  //     String(now.getMonth() + 1).padStart(2, '0') +
  //     '-' +
  //     String(now.getDate()).padStart(2, '0') +
  //     'T00:00:00.000Z',
  // );
  let years = today.getFullYear() - birthday.getFullYear();
  birthday.setFullYear(today.getFullYear());
  if (today < birthday) {
    years--;
  }
  return years;
}

export function getByteString(str) {
  let byte = 0;
  for (let i = 0; i < str.length; i++) {
    str.charCodeAt(i) > 127 ? (byte += 3) : byte++;
  }
  return byte;
}

/**
 * 인총 참여번호코드 생성
 * 시설가구 타인가구 구분을 위함.
 * @param partcptnNo
 * @param prdctnId
 * @returns {string|string}
 */
export function partcptnNoCd(partcptnNo, prdctnId = process.env.REACT_APP_PRDCTN_ID) {
  return prdctnId !== '220001' ? '0' : partcptnNo[0].toUpperCase();
}

/**
 * 로그인 이후 이동페이지 설정
 * @param res 로그인결과의 마지막 응답항목 ID
 * @param user 사용자정보의 조사상태코드
 * @param appMode AppMode
 * @returns {string|string} 주소
 */
export function loginNavigate(
  res = { lastInptStatsIemId: null },
  user = { exmnSttsCd: null },
  appMode = process.env.REACT_APP_MAIN_APP,
) {
  const prdctnId = process.env.REACT_APP_PRDCTN_ID;

  const sttsCdCondition = (exmnSttsCd) => {
    if (exmnSttsCd) {
      return exmnSttsCd === '1' || exmnSttsCd === '2' || exmnSttsCd === '3';
    } else {
      return false;
    }
  };

  if (prdctnId === '220001') {
    if (res.qsnSeCd === 'A02' || res.qsnSeCd === 'A03') {
      // 빈집, 시설관리자
      return '/svyView';
    } else {
      if (res.exmnSttsCd === '2') {
        // 완료처리된 조사표 요약표로 이동
        return `/${appMode.slice(0, 4).toLowerCase()}bd120m01`;
      }
      // 인총
      if (sttsCdCondition(user.exmnSttsCd)) {
        return res.lastInptStatsIemId
          ? '/svyView'
          : `/${appMode.slice(0, 4).toLowerCase()}bd110m01`;
      } else {
        return `/${appMode.slice(0, 4).toLowerCase()}ba110m01`;
      }
    }
  } else if (prdctnId === '220002') {
    let movePage = _.isEmpty(res.svyConfirm) ? '/svyView' : `/svyView?svyConfirm=${res.svyConfirm}`;
    if (appMode === 'AreaApp') {
      return movePage;
    } else {
      // 농총
      if (
        appMode === 'EcpaAppOffline' ||
        appMode === 'EcpaApp' ||
        sttsCdCondition(user.exmnSttsCd)
      ) {
        if (user.isMoveRspdnt === 'Y') {
          return `/${appMode.slice(0, 4).toLowerCase()}ba110m01`;
        } else {
          return res.lastInptStatsIemId
            ? movePage
            : `/${appMode.slice(0, 4).toLowerCase()}bd130m01`;
        }
      } else {
        return `/${appMode.slice(0, 4).toLowerCase()}ba110m01`;
      }
    }
  } else {
    return '/svyView';
  }
}

/**
 * @param prefix 접두사
 * @param suffix 접미사
 * @param separator 구분자
 * @param array 합성할 문자열 배열
 * @returns {string} 완성된 문자열
 */
export function valueFilter(prefix = '', suffix = '', separator = '', array = []) {
  if (array.length !== 0) {
    let word = prefix;
    for (let i = 0; i < array.length; i++) {
      if (_.isNil(array[i])) {
        continue;
      }
      if (i !== 0 && !_.isNil(array[i])) {
        word += separator;
      }
      word += array[i];
    }
    word += suffix;
    return word;
  } else {
    return '';
  }
}

/**
 * CAPI x버튼
 */
export function capiExit() {
  alert('조사를 종료 합니다.');
  // window.android.close();
  console.log('andorid.close() - 호출 Start');
  new Function('android.close()')();
  console.log('andorid.close() - 호출 End');
}

/**
 * 세션 종료
 */
export function sessionOut() {
  if (
    ['EcppApp', 'EcpaApp', 'EcppAppOffline', 'EcpaAppOffline'].indexOf(
      process.env.REACT_APP_MAIN_APP,
    ) !== -1
  ) {
    // alert('잘못된 세션 정보 입니다.');
    new Function('android.close()')();
  } else if (
    ['EctpApp', 'EcphApp', 'EcphAppOffline', 'IhstApp', 'EcthApp'].indexOf(
      process.env.REACT_APP_MAIN_APP,
    ) !== -1
  ) {
    // alert('잘못된 세션 정보 입니다.');
    window.close();
  } else {
    alert('사용자의 세션이 종료되었습니다. 로그인 화면으로 이동합니다.');
    const url =
      process.env.REACT_APP_MODE === 'LOCAL'
        ? `http://localhost:${process.env.PORT}`
        : process.env.REACT_APP_PROXY_HOST;
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('authToken');
    window.location.href = url;
  }
}

/**
 * 읽기모드 조건
 * @param {*} user
 * @returns
 */
export function isReadOnly(user) {
  // _.includes([user.sbmsnYn, user.readonlyYn], 'Y');
  return (
    (!_.isEmpty(user.sbmsnYn) && user.sbmsnYn === 'Y') ||
    (!_.isEmpty(user.readonlyYn) && user.readonlyYn === 'Y')
  );
}

/**
 * 오브젝트 체크
 * @param obj 유저
 * @returns
 */
export function isObj(obj) {
  return !_.isUndefined(obj) && !_.isNull(obj);
}

export function paramDecoder(paramsObj = {}) {
  const keyArr = Object.keys(paramsObj);
  for (const key in keyArr) {
    paramsObj[keyArr[key]] = decodeURIComponent(paramsObj[keyArr[key]]);
  }
  return paramsObj;
}

/**
 * appMode로 조사방법코드를 반환
 * @returns
 */
export function getExmnMthdCode() {
  let appMode = process.env.REACT_APP_MAIN_APP;
  let exmnMthdCd = '';
  if ('EcspApp' === appMode || 'EcsaApp' === appMode) {
    exmnMthdCd = '1';
  } else if ('EsspApp' === appMode) {
    exmnMthdCd = '2';
  } else if (appMode.indexOf('EcppApp') > -1 || appMode.indexOf('EcpaApp')) {
    exmnMthdCd = '3';
  } else if ('EctpApp' === appMode) {
    exmnMthdCd = '5';
  }
  return exmnMthdCd;
}

/**
 * 조사방법코드로 조사방법명을 반환
 * @returns
 */
export function getExmnMthdNm(exmnMthdCd) {
  let exmnMthdNm = '';
  if (exmnMthdCd === '1') {
    exmnMthdNm = 'CASI(인터넷PC)';
  } else if (exmnMthdCd === '2') {
    exmnMthdNm = 'SASI(모바일)';
  } else if (exmnMthdCd === '3') {
    exmnMthdNm = 'CAPI(태블릿)';
  } else if (exmnMthdCd === '5') {
    exmnMthdNm = 'CATI(전화조사)';
  }
  return exmnMthdNm;
}

/**
 * AES-CBC 암호화
 * @param p 평문
 * @returns {Promise<string>}
 */
export async function aesEncrypt(p) {
  const arrayBufferFromHexString = (hexString) => {
    const byte = Uint8Array.from(hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)));
    return byte.buffer;
  };
  const key = process.env.REACT_APP_AES_KEY;
  const keyBuffer = arrayBufferFromHexString(key);
  const ivBuffer = arrayBufferFromHexString(key.substring(0, 32));
  const secretKey = await window.crypto.subtle.importKey(
    'raw',
    keyBuffer,
    { name: 'AES-CBC', length: 256 },
    true,
    ['encrypt'],
  );
  let enc = new TextEncoder();
  const encrypted = await window.crypto.subtle.encrypt(
    { name: 'AES-CBC', iv: ivBuffer },
    secretKey,
    enc.encode(p),
  );
  return window.btoa(String.fromCharCode.apply(null, new Uint8Array(encrypted)));
}

export function isMobile() {
  var isMobile = false;
  var mobileKeyWords = new Array(
    'SAMSUNG',
    'LG',
    'iPhone',
    'iPad',
    'iPod',
    'BlachkBerry',
    'PlayBook',
    'BB',
    'Android',
    'Windows CE',
    'MOT',
    'SonyEricsson',
    'Symbian',
    'Windows Phone',
    'webOS',
    'opera Min',
    'opera Mobi',
    'POLARIS',
    'EMobile',
    'gTelecom',
    'Nokia',
  );
  for (var word in mobileKeyWords) {
    if (navigator.userAgent.match(mobileKeyWords[word]) != null) {
      isMobile = true;
      break;
    }
  }

  return isMobile;
}
