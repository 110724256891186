import { batch } from 'react-redux'
import { toast } from 'react-toastify';
import _ from "lodash";
import { setCallbackTrigger as behavior_setCallbackTrigger, setStatus as behavior_setStatus, executeSaveProcess as behavior_executeSaveProcess } from "./Behavior";
import { addInputReviewScriptResult as main_addInputReviewScriptResult, callExternalStatistics as main_callExternalStatistics, setActiveGroupId as main_setActiveGroupId, setNeedConfirmList as main_setNeedConfirmList, setSvyResultList as main_setSvyResultList } from "./Main";
import { checkAnswerComplete as paper_checkAnswerComplete, setAnswerCompleteArray as paper_setAnswerCompleteArray, getCurrentGroup as paper_getCurrentGroup } from "./Paper";
// 액션 타입 정의
const INIT = "answer/INIT";
const SET_ANSWERDATA = "answer/SET_ANSWERDATA";
const ADD_ANSWER = "answer/ADD_ANSWER";
const ADD_ANSWER_ARRAY = "answer/ADD_ANSWER_ARRAY";
const COMMIT_ANSWER = "answer/COMMIT_ANSWER"
const SET_AFFECTED_DATA = "answer/SET_AFFECTED_DATA";
const ADD_AFFECTED_DATA = "answer/ADD_AFFECTED_DATA";
const ADD_AFFECTED_DATA_ARRAY = "answer/ADD_AFFECTED_DATA_ARRAY";
const ADD_ANSWER_LIST = "answer/ADD_ANSWER_LIST";
const REMOVE_ANSWER = "answer/REMOVE_ANSWER";
const CHANGE_SVY_MEMBER = "answer/CHANGE_SVY_MEMBER";
const SET_LAST_CHANGED_GROUP = 'answer/SET_LAST_CHANGED_GROUP'

// 액션 생섬함수 정의
export const init = () => ({ type: INIT });//초기화
export const setAnswerData = ({answerData, recordIdMap,}) => (dispatch, getState) =>{
  const { Main } = getState();
  dispatch({ type: SET_ANSWERDATA, answerData, recordIdMap, mainModel : Main})
};

export const addAnswer = ({questionId, saveKey, value, commit, isApiCall=false, }) => async (dispatch, getState) => {
  const { Main:mainModel, Paper:paperModel } = getState();
  const beforeAnswer = _.cloneDeep(getState().Answer);
  batch(async ()=>{
    let key = saveKey
    let hhmsn;
    if(mainModel.inputLayoutCode == '02'){
      key = saveKey.split('_')[1]
      hhmsn = saveKey.split('_')[0]
    }
    let group = dispatch(paper_getCurrentGroup({questionId : questionId}))
    const mainGroup = _.find(mainModel.groupTree, {children : [{key : group?.id}]})

    await dispatch({ type: ADD_ANSWER, questionId, saveKey, value, commit, }); //await 가 changeAnswer안에서 자기자신의 getAnswer를 햇을때 이전값을 가져오는걸 방지해준다.
    
    if( commit){
      if(!_.isEmpty(mainModel.svyResultList) && mainModel.svyResultList.ciCount > 0 ){
        //답변완료될때 해당 답변이 응답점검목록에 존재하는지 체크후 존재한다면 isupdated값을 업데이트
        let svyResultList = _.cloneDeep(mainModel.svyResultList.resultList)
        for (const result of svyResultList) {
          let needBreak = false
          if (result.ciList.length > 0) {
            for (const ciData of result.ciList) {
              if (ciData.isUpdated.updated == false && ciData.isUpdated.key == questionId) {
                ciData.isUpdated.updated = true
                needBreak = true
                break;
              }
            }
          }
          if(needBreak){
            break;
          }
        }
        dispatch(main_setSvyResultList({svyResultList : svyResultList, ciCount : mainModel.svyResultList.ciCount}))
      }

      //답변완료여부확인하면서 updateProgress가 changeAnswer보다 먼저타게되면 changeAnswer로 visible이나 disable속성이 변경되는 항목들이 updateProgress에 반영안되는 문제때문에 
      //checkanswercomplete와 위치변경함. 
      //0. 답변완료여부 확인 
      dispatch( paper_checkAnswerComplete({questionId,saveKey}))
      //1. changeAnswer 호출 
      if(!_.isEmpty(getState().Answer)&&!_.isEqual(beforeAnswer,getState().Answer)){
        if(commit == true && isApiCall == false){
          dispatch(
            behavior_setCallbackTrigger({
              data:{
                eventName:"changeAnswer",
                questionId,
                saveKeys:[saveKey],
                hhmsn
              },callback:(response)=>{
                
                
              }
            })
          )
        }
        
      } 

      
      dispatch( behavior_setStatus({questionId, }))//조사항목,명부항목별 수정여부 업데이트 
      
      //전역그룹의 마지막그룹의 마지막항목 답변시 임시저장.
      // if(mainModel.device == 'ecsp' || mainModel.device == 'ecpp' || mainModel.device == 'essp' || mainModel.device == 'ectp'){
      //   if(!_.isEmpty(group)){
      //     group = _.find(getState().Paper.tree, {id : group.id})
      //     const mainChildren = _.filter(_.filter(paperModel.tree, tree=> tree.mainGroupId == mainGroup.key), child=>{
      //       const item = getState().Paper.itemMap[child.id]
      //       if(item.common.visible == false || item.common.disable == true){
      //         return false
      //       }else{
      //         return true
      //       }
      //     })
      //     const groupChildren = _.filter(group.children, child=>{
      //       const item = getState().Paper.itemMap[child.id]
      //       if(item.common.visible == false || item.common.disable == true){
      //         return false
      //       }else{
      //         return true
      //       }
      //     })
      //     if(_.indexOf(mainChildren, _.find(mainChildren, {id : group.id})) == mainChildren.length -1){
      //       // console.log("마지막그룹", mainGroup,mainChildren, group)
      //       if(_.indexOf(groupChildren, _.find(groupChildren, {id : questionId})) == groupChildren.length -1){
      //         // console.log("마지막그룹의 마지막항목", groupChildren)
      //         dispatch(behavior_executeSaveProcess({inSaveProcess : true}))
      //       }
      //     }
      //   }
  
      // }
      dispatch(main_callExternalStatistics({gbn:"I",iemId:questionId}))
      //2. 스크립트 내검
      // if(mainModel.inputReviewScript.serverData){
      //   const scriptList = _.filter(mainModel.inputReviewScript.serverData.rsList,(v)=>{
      //     return (v.ciScriptExecutCode == 10 || v.ciScriptExecutCode == 30) && v.scriptApplcStatsIemId == saveKey
      //   })
      //   if(scriptList.length > 0){
      //     //스크립트 내검 실행 
      //     dispatch(
      //       behavior_setCallbackTrigger({
      //         data:{
      //           eventName:"inputReviewScript",
      //           scriptList:scriptList,
      //         },callback: (response)=>{
      //           dispatch( main_addInputReviewScriptResult({resultList:response,  }))//스크립트 내검 결과 저장
      //           //main_addInputReviewScriptResult 실행 후 바로 반영된 결과를 엑세스 하지 못하므로 반영될 결과 값을 조합해서 검사한다
      //           const expected_reivewResult = {
      //             ...mainModel.inputReviewScript.reviewResult,
      //             ...response,
      //           }
      //           //입력내검 결과 통과 못하면 알럿 표시 
      //           const passInputReview = expected_reivewResult[saveKey]
      //           if(passInputReview== true){ //알럿 표시 
      //             const {ciErorMssage, } = (_.find(mainModel.inputReviewScript.serverData.rsList,(v)=>v.scriptApplcStatsIemId == saveKey)||{})
      //             toast.error(ciErorMssage)
      //           }
      //         }
      //       })
      //     )
      //   }
        
      // }
    }
  })
};

export const commitAnswer = ({saveKey, save, executeChangeAnswerCallback = false, isFocusItem}) => async (dispatch, getState) => {
  const { Main:mainModel, Paper:paperModel, } = getState();
  const beforeAnswer = _.cloneDeep(getState().Answer);
  batch(async ()=>{
    dispatch({ type: COMMIT_ANSWER, saveKey, save, });
    let key = saveKey
    let hhmsn;
    if(mainModel.inputLayoutCode == '02'){
      key = saveKey.split('_')[1]
      hhmsn = saveKey.split('_')[0]
    }

    //save키를 가지고 questionid 찾기
    let question;
    if(isFocusItem != true){
      question = _.find(paperModel.itemMap, v=>{return (v.saveKeys||[]).indexOf(key)!=-1})
    }else{
      question = _.find(paperModel.itemMap, v=>{return (v.saveKeys||[]).indexOf(key.split('_')[1])!=-1})
    }
    if( question == undefined){
      question = paperModel.itemMap[key].parentId && paperModel.itemMap[paperModel.itemMap[key].parentId]//선택적 주관식은 ParentId로 가져옴 
    }
    if(!_.isEmpty(mainModel.svyResultList) && mainModel.svyResultList.ciCount > 0 ){
      //답변완료될때 해당 답변이 응답점검목록에 존재하는지 체크후 존재한다면 isupdated값을 업데이트
      let svyResultList = _.cloneDeep(mainModel.svyResultList.resultList)
      for (const result of svyResultList) {
        let needBreak = false
        if (result.ciList.length > 0) {
          for (const ciData of result.ciList) {
            if (ciData.isUpdated.updated == false && ciData.isUpdated.key == question.id) {
              ciData.isUpdated.updated = true
              needBreak = true
              break;
            }
          }
          
        }
        if(needBreak){
          break;
        }
      }
      dispatch(main_setSvyResultList({svyResultList : svyResultList, ciCount : mainModel.svyResultList.ciCount}))
      
    }

    // 0. 답변완료여부 확인
    dispatch( paper_checkAnswerComplete({questionId:question.id, saveKey}))
    //1. changeAnswer 호출 
    if(!_.isEmpty(getState().Answer)&&!_.isEqual(beforeAnswer,getState().Answer)){
      if(executeChangeAnswerCallback==true){ //키보드로 쓰고 마우스로 포커스 나갈때(onblur)에서 사용 중
        if(window.svy.keyEventQuestion != true){
          dispatch(
            behavior_setCallbackTrigger({
              data:{
                eventName:"changeAnswer",
                questionId: question.id,
                saveKeys: [saveKey],
                hhmsn
              },callback:(response)=>{
              }
            })
            )
          }
        }
        dispatch( behavior_setStatus({questionId:question.id, }))//조사항목,명부항목별 수정여부 업데이트 
        window.svy.keyEventQuestion = false; //키보드이벤트여부 초기화
      }
      let mainGroupKey= '';
      //전역그룹의 마지막그룹의 마지막항목 답변시 임시저장.
    //   if(mainModel.device == 'ecsp' || mainModel.device == 'ecpp' || mainModel.device == 'essp' || mainModel.device == 'ectp'){
    //   let group = dispatch(paper_getCurrentGroup({questionId : question.id}))
    //   if(!_.isEmpty(group)){
    //     group = _.find(getState().Paper.tree, {id : group.id})
    //     const mainGroup = _.find(mainModel.groupTree, {children : [{key : group.id}]})
    //     mainGroupKey = mainGroup.key
    //     const mainChildren = _.filter(_.filter(paperModel.tree, tree=> tree.mainGroupId == mainGroup.key), child=>{
    //       const item = getState().Paper.itemMap[child.id]
    //       if(item.common.visible == false || item.common.disable == true){
    //         return false
    //       }else{
    //         return true
    //       }
    //     })
    //     const groupChildren = _.filter(group.children, child=>{
    //       const item = getState().Paper.itemMap[child.id]
    //       if(item.common.visible == false || item.common.disable == true){
    //         return false
    //       }else{
    //         return true
    //       }
    //     })
    //     if(_.indexOf(mainChildren, _.find(mainChildren, {id : group.id})) == mainChildren.length -1){
    //       // console.log("마지막그룹", mainGroup,mainChildren, group)
    //       if(_.indexOf(groupChildren, _.find(groupChildren, {id : question.id})) == groupChildren.length -1 ){
    //         // console.log("마지막그룹의 마지막항목", groupChildren)
    //         dispatch(behavior_executeSaveProcess({inSaveProcess : true}))
    //         if(mainModel.device == 'essp'){
    //           // dispatch(main_setActiveGroupId({groupId : mainGroup.key}));
    //         }
    //       }
    //     }

    //   }

    // }
    dispatch(main_callExternalStatistics({gbn:"I",iemId:question.id}))
  
    //2. 스크립트 내검
    // if(mainModel.inputReviewScript.serverData){
    //   const scriptList = _.filter(mainModel.inputReviewScript.serverData.rsList,(v)=>{
    //     return (v.ciScriptExecutCode == 10 || v.ciScriptExecutCode == 30 ) && v.scriptApplcStatsIemId == saveKey
    //   })
    //   // console.log("scriptList",scriptList)
    //   if(scriptList.length > 0){
    //     //스크립트 내검 실행 
    //     dispatch(
    //       behavior_setCallbackTrigger({
    //         data:{
    //           eventName:"inputReviewScript",
    //           scriptList:scriptList,
    //         },callback: (response)=>{
    //           // console.log("inputReviewScript result,",response)
    //           dispatch( main_addInputReviewScriptResult({resultList:response,  }))//스크립트 내검 결과 저장
    //           //main_addInputReviewScriptResult 실행 후 바로 반영된 결과를 엑세스 하지 못하므로 반영될 결과 값을 조합해서 검사한다
    //           const expected_reivewResult = {
    //             ...mainModel.inputReviewScript.reviewResult,
    //             ...response,
    //           }
    //           // console.log("expected_reivewResult,",saveKey,expected_reivewResult)
    //           //입력내검 결과 통과 못하면 알럿 표시 
    //           const passInputReview = expected_reivewResult[saveKey]
    //           if(passInputReview== true){ //알럿 표시 
    //             const {ciErorMssage, } = (_.find(mainModel.inputReviewScript.serverData.rsList,(v)=>v.scriptApplcStatsIemId == saveKey)||{})
    //             toast.error(ciErorMssage)
    //           }
    //         }
    //       })
    //     )
    //   }
    // }
  })
};
export const setAffectedData = ({affectedData, }) => ({ type: SET_AFFECTED_DATA, affectedData, });
export const addAffectedData = ({questionId, path, value, }) => ({ type: ADD_AFFECTED_DATA, questionId, path, value, });
export const addAffectedDataArray = (affectedDataArray) => ({ type: ADD_AFFECTED_DATA_ARRAY, affectedDataArray, });

export const addAnswerList = ({questionId, answerList,  commit,  }) => (dispatch, getState) => {
  const { Main:mainModel, } = getState();
  const beforeAnswer = _.cloneDeep(getState().Answer);
  batch(()=>{

    dispatch({ type: ADD_ANSWER_LIST, questionId, answerList, commit, });
  
    if( commit){
  
      // 0. 답변완료여부 확인
      answerList.map(v=>{
        dispatch( paper_checkAnswerComplete({questionId, saveKey:v.key}))
      })
      
  
      //1. changeAnswer 호출 
      if(!_.isEmpty(getState().Answer)&&!_.isEqual(beforeAnswer,getState().Answer)){
        dispatch(
          behavior_setCallbackTrigger({
            data:{
              eventName:"changeAnswer",
              saveKeys:answerList.map(v=>v.key),
            },callback:(response)=>{
              //TODO : 리턴값을 통해서 이후 동작 제어 처리해야함 
              const { Answer } = getState();
              // console.log("response",response)
            }
          })
        )
        dispatch( behavior_setStatus({questionId, }))//조사항목,명부항목별 수정여부 업데이트 
      } 
  
      //2. 스크립트 내검
      if(mainModel.inputReviewScript.serverData){
        const scriptList = _.filter(mainModel.inputReviewScript.serverData.rsList,(v)=>{
          return (v.ciScriptExecutCode == 10 || v.ciScriptExecutCode == 30 ) && answerList.map(v=>v.key).indexOf(v.scriptApplcStatsIemId) != -1
        })
        
        if(scriptList.length > 0){
          //스크립트 내검 실행 
          dispatch(
            behavior_setCallbackTrigger({
              data:{
                eventName:"inputReviewScript",
                scriptList:scriptList,
              },callback: (response)=>{
                // console.log("request main_addInputReviewScriptResult",response)
                dispatch( main_addInputReviewScriptResult({resultList:response,  }))//스크립트 내검 결과 저장
                //main_addInputReviewScriptResult 실행 후 바로 반영된 결과를 엑세스 하지 못하므로 반영될 결과 값을 조합해서 검사한다
                const expected_reivewResult = {
                  ...mainModel.inputReviewScript.reviewResult,
                  ...response,
                }
                //입력내검 결과 통과 못하면 알럿 표시 
                const passInputReview = true
                answerList.map(v=>{
                  // console.log("v",v.key)
                  if( expected_reivewResult[v.key] == true){
                    passInputReview = false
                  }
                })
                
                if(passInputReview== false){ //알럿 표시 
                  const {ciErorMssage, } = (_.find(mainModel.inputReviewScript.serverData.rsList,(v)=>v.scriptApplcStatsIemId == _.first(answerList).key)||{})
                  alert(ciErorMssage)
  
                }
              }
            })
          )
        }
      }
  
  
    }
  })
}
export const addAnswerArray = (answerArray   ) => (dispatch, getState) => {
  //array 구조 [{questionId, saveKey, value}]
  const { Main:mainModel } = getState();
  const beforeAnswer = _.cloneDeep(getState().Answer);

  batch(()=>{
    let rowIndex;
    let calcCallbackList = []
    dispatch({ type: ADD_ANSWER_ARRAY, answerArray});
  
      const checkAnswerList = []
      // 0. 답변완료여부 확인
      answerArray.map(answer=>{
        // dispatch( paper_checkAnswerComplete({questionId:answer.questionId, saveKey:answer.saveKey}))
        // saveKeys.push(answer.saveKey)
        // questionIdList.push(answer.questionId)
        checkAnswerList.push({
          questionId : answer.questionId,
          saveKey : answer.saveKey,
        })
        //.해당항목이 계산식함수에 파라미터로 포함되어있는지 체크후 콜백실행
      let parentId = answer.questionId
      rowIndex = _.split(parentId, "_")[1]
      if(_.includes(parentId, "_")){
        parentId = _.split(parentId, "_")[0]
      }
      Object.keys(mainModel.callback).forEach(key=> {
        if(_.includes(mainModel.callback[key].param, parentId)){
          calcCallbackList.push(mainModel.callback[key])
        }
      })
      })
      dispatch( paper_setAnswerCompleteArray(checkAnswerList))

      if(calcCallbackList.length>0){
        _.uniq(calcCallbackList).forEach(callback=>{
          let userFunction = new Function("rowIndex", callback.code)
          userFunction.apply(window.svy, [rowIndex])
        })
      }
      
      

      //1. changeAnswer 호출 ( 사용자 콜백안에서 호출할때는 콜백 호출 다시 안함, addAnswerArray는 콜백을 통해서만 사용됨  )
      
      //2. 스크립트 내검
      if(mainModel.inputReviewScript.serverData){
        const scriptList = _.filter(mainModel.inputReviewScript.serverData.rsList,(v)=>{
          return (v.ciScriptExecutCode == 10 || v.ciScriptExecutCode == 30 ) && answerArray.map(v=>v.questionId).indexOf(v.scriptApplcStatsIemId) != -1
        })
        
        if(scriptList.length > 0){
          //스크립트 내검 실행 
          dispatch(
            behavior_setCallbackTrigger({
              data:{
                eventName:"inputReviewScript",
                scriptList:scriptList,
              },callback: (response)=>{ 
                // console.log("request main_addInputReviewScriptResult",response)
                dispatch( main_addInputReviewScriptResult({resultList:response,  }))//스크립트 내검 결과 저장
                //main_addInputReviewScriptResult 실행 후 바로 반영된 결과를 엑세스 하지 못하므로 반영될 결과 값을 조합해서 검사한다
                const expected_reivewResult = {
                  ...mainModel.inputReviewScript.reviewResult,
                  ...response,
                }
                //입력내검 결과 통과 못하면 알럿 표시 
                const passInputReview = true
                answerArray.map(v=>{
                  // console.log("v",v.key)
                  if( expected_reivewResult[v.key] == true){
                    passInputReview = false
                  }
                })
                
                if(passInputReview== false){ //알럿 표시 
                  const {ciErorMssage, } = (_.find(mainModel.inputReviewScript.serverData.rsList,(v)=>v.scriptApplcStatsIemId == _.first(answerArray).key)||{})
                  alert(ciErorMssage)
  
                }
              }
            })
          )
        }
      }
  
  
  })
}
export const removeAnswer = ({ questionId, answerIds, }) => (dispatch, getState)=> { 
const {Paper, } = getState();
  dispatch({type: REMOVE_ANSWER, questionId, answerIds, paperModel : Paper})
 }
export const removeAnswerArray = (removeAnswerArray) =>(dispatch, getState) =>{
  const {Paper, } = getState();
  batch(()=>{
    removeAnswerArray.map(answer=>{
      dispatch({type: REMOVE_ANSWER, questionId : answer.questionId, answerIds : answer.answerIds, paperModel : Paper})
    })
  })
}
export const changeSvyMember = ({}) => ({type: CHANGE_SVY_MEMBER})

export const setLastChangedGroup = ({groupKey})=>({type :SET_LAST_CHANGED_GROUP, groupKey})
// **** 초기상태 정의
const initialState = { 
  init:false,
  questionId:undefined, //답변선택이 일어난 항목번호 
  answerData:{},  //현재 조사표에서 답변한 모든 데이터
  changedData:{},   //마지막 입력으로 변경된 데이터  (랭킹 컴포넌트 때문에 여러개가 들어올수 도 있음 )
  affectedData:{},   //답변선택과정에서 생성된 데이터 
  recordIdMap:{},    //반복표의 명부항목에 대한 서버에서 내려온 키 정보
  lastChangedGroup : '',
  commit:{
    needCommit: false,  //false-편집중이지 않은 상태, true-편집중이면서 커밋되지 않은 상태
    saveKey:undefined,
    answerData_startValue:undefined,   //편집 시작할때의 값 저장 
    changedData_startValue:undefined,
  },
};

// **** 리듀서 작성
export default function reducer(state = initialState, action) {
  switch (action.type) {

    case INIT: {
      return {
        ...state,
        init:true,
      };
    }
    case SET_ANSWERDATA: {
      console.log("[reducer] answer SET_ANSWERDATA",action.answerData)
      const { mainModel, } = action
      if(mainModel.inputLayoutCode == '02'){
        let answerData = _.cloneDeep(action.answerData)
        let answerTempData = {}
        answerData.map(answer=>{
          let hhmSn = answer['HHM_SN']
          Object.keys(answer).forEach(key=>{
            if(key != 'HHM_SN'){
              let answerKey = `${hhmSn}_${key}`
              answerTempData = {
                ...answerTempData,
                ...answerTempData[answerKey]
              }
              answerTempData[answerKey] = answer[key]
            }
          })
        })
        console.log("[reducer] answer SET_ANSWERDATA",answerTempData,)
        return {
          ...state,
          answerData : answerTempData,
          recordIdMap : action.recordIdMap,
          lastChangedGroup : ''
        };
      }else{
        return {
          ...state,
          answerData : mainModel.device != 'ecsa' && mainModel.device != 'ecpa' && mainModel.device != 'area' && mainModel.device != 'ecph' && mainModel.device != 'ecth' && mainModel.device != 'ihst' ? _.find(action.answerData, {HHM_SN : window.svy.params.hhmSn + ''}) || {} : action.answerData ,
          recordIdMap : action.recordIdMap,
          lastChangedGroup : ''
        };
      }
    }
    case ADD_ANSWER: {
      // console.log("ADD_ANSWER",action.questionId, action.key, action.value,)
      let commit={}
      const value = _.isNumber(action.value) ? action.value + '' : action.value //숫자로 들어올경우 문자열로 변환하기위함.
      // console.log(state.commit.saveKey , action.key)
      if(state.commit.saveKey == undefined){ //새로운 입력값에 대해서 편집을 시작하면, 
        if( action.commit == true){ //바로 변경을 확정하는 상황이면 ,
          commit={
            needCommit: false,
            saveKey:undefined,
            answerData_startValue:undefined,
          }
        }else{
          commit={
            needCommit: true,  
            startValue:state.answerData[action.saveKey],
            changedData_startValue:state.changedData[action.saveKey],
            saveKey:action.saveKey,
          }
        }
        
      }
      // let questionId = _.isArray(state.questionId) ?[...state.questionId] : [state.questionId]
      // if(questionId.length >= 10){
      //   questionId.shift()
      // }
      // questionId.push(action.questionId)
      return {
        ...state,
        questionId:action.questionId ,
        answerData:{
          ...state.answerData,
          [action.saveKey]:value, 
        },
        changedData:{[action.saveKey]:value, },
        commit:{
          ...state.commit,
          ...commit,
        },
      };
      
    }
    case ADD_ANSWER_ARRAY: {
      // console.log("ADD_ANSWER",action.questionId, action.key, action.value,)
      let commit={
        needCommit: false,
        saveKey:undefined,
        answerData_startValue:undefined,
      }
      let answerData = {...state.answerData}
      // let questionId = _.isArray(state.questionId) ?[...state.questionId] : [state.questionId]
      let changedData = {...state.changedData}
      
      action.answerArray.map(answer=>{
        // console.log("🚀 ~ reducer ~ answer.questionId:", answer.questionId)
        answerData[answer.saveKey] = answer.value
        // if(questionId.length >= 10){
        //   questionId.shift()
        // }
        // questionId.push(answer.questionId)
        changedData = {[answer.saveKey]:answer.value, }
      })

      return {
        ...state,
        // questionId,
        answerData,
        changedData,
        commit:{
          ...state.commit,
          ...commit,
        },
      };
    }
    case COMMIT_ANSWER: {
      // console.log("COMMIT_ANSWER",action.key, action.save,)
      if( action.save){
        return {
          ...state,
          commit:{
            needCommit: false,
            saveKey:undefined,
            answerData_startValue:undefined,
          },
        };
      }else{ //캔슬 해야할 경우 
        return {
          ...state,
          commit:{
            needCommit: false,  
            saveKey:undefined,
            answerData_startValue:undefined,
          },
          answerData:{
            ...state.answerData,
            [action.saveKey]:state.commit.answerData_startValue||"", 
          },
          changedData:{[action.saveKey]:state.commit.changedData_startValue||"", },
        };
      }
      
    }
    case SET_AFFECTED_DATA: {
      console.log("[reducer] answer SET_AFFECTED_DATA", action.affectedData)
      return {
        ...state,
        affectedData:action.affectedData,
      };
    }
    case ADD_AFFECTED_DATA: {
      let affectedData = {...state.affectedData}
      // console.log("ADD_AFFECTED_DATA",action.questionId, action.path, action.value,)

      const pathSplit = action.path.split(".")
      // console.log("pathSplit",pathSplit)
      const _process = (questionId) =>{
        if(pathSplit.length == 1){
          affectedData[questionId] = {
            ...affectedData[questionId],
            [pathSplit[0]]:action.value
          }
          
        }else if(pathSplit.length == 2){
          affectedData[questionId] = {
            ...affectedData[questionId],
          }
          affectedData[questionId] = {
            ...affectedData[questionId],
            [pathSplit[0]]:{
              ...affectedData[questionId][pathSplit[0]],
              [pathSplit[1]]:action.value,
            }
          }
  
        }else if(pathSplit.length == 3){
          affectedData[questionId]={
            ...affectedData[questionId], 
            [pathSplit[0]]:{
              ...affectedData[questionId][pathSplit[0]],
              [pathSplit[1]]:{
                ...affectedData[questionId][pathSplit[0]][pathSplit[1]],
                [pathSplit[2]]:action.value,
              }
            }
            
          }
        }
      }
      if(_.isString(action.questionId)){
        _process(action.questionId);
      }else if(_.isArray(action.questionId)){
        action.questionId.forEach(questionId=>{
          _process(questionId);
        })
      }

      return {
        ...state,
        affectedData
      };
    }
    case ADD_AFFECTED_DATA_ARRAY: {
      let affectedData = {...state.affectedData}
      // console.log("ADD_AFFECTED_DATA",action.affectedDataArray)

      
      // console.log("pathSplit",pathSplit)
      const _process = (id, path, value) =>{
        const pathSplit = path.split(".")
        if(pathSplit.length == 1){
          affectedData[id] = {
            ...affectedData[id],
            [pathSplit[0]]:value
          }
          
        }else if(pathSplit.length == 2){
          affectedData[id] = {
            ...affectedData[id],
          }
          affectedData[id] = {
            ...affectedData[id],
            [pathSplit[0]]:{
              ...affectedData[id][pathSplit[0]],
              [pathSplit[1]]:value,
            }
          }
  
        }
      }
      action.affectedDataArray.forEach(affectedData => {
        _process(affectedData.questionId, affectedData.path, affectedData.value,);
      	
      })

      return {
        ...state,
        affectedData
      };
    }
    case ADD_ANSWER_LIST: {
      let answerData = {
        ...state.answerData
      }
      let changedData = []
      for(const answer of action.answerList){
        answerData={
          ...answerData,
          [answer.key]:answer.value,
        }
        changedData={
          ...changedData,
          [answer.key]:answer.value,
        }
      }
      // console.log(answerData)
      return {
        ...state,
        questionId:action.questionId,
        answerData,
        changedData,
      };
      
    }
    case REMOVE_ANSWER:{
      let answerData = _.cloneDeep(state.answerData);
      let affectedData = _.cloneDeep(state.affectedData);
      let changedData = _.cloneDeep(state.changedData);
      if(_.isArray(action.answerIds)){
        action.answerIds.forEach(id=>{
          const questionId = _.find(action.paperModel.itemMap, {saveKeys : [id]}).id
          delete affectedData[questionId];
          delete affectedData[id];
          delete answerData[id];
          delete changedData[id];
        });
      }
      return {
        ...state,
        affectedData,
        answerData,
        changedData,
      };
    }
    case CHANGE_SVY_MEMBER : {
      return{
        ...state,
        questionId : '',
        changedData : {}
      }
    }
    case SET_LAST_CHANGED_GROUP : {
      const lastChangedGroup = state.lastChangedGroup == action.groupKey ? state.lastChangedGroup : action.groupKey
      return {
        ...state,
        lastChangedGroup
      }
    }
    default: {
      return state;
    }
  }
}